import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react'
import { Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { AC_HANDLE_INPUT_CHANGE } from '../../actions/accountAction';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { AC_EMPTY_TICKET_WORK_FLOW, AC_HANDLE_TICKET_WORK_FLOW_CHANGE, AC_VIEW_TICKET_WORK_FLOW } from '../../actions/ticketworkflow';
import { AC_LIST_USER } from '../../actions/userAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import { AC_HANDLE_TICKET_PRIORITY_CHANGE, AC_EMPTY_TICKET_PRIORITY, AC_VIEW_TICKET_PRIORITY, AC_DEFAULT_TICKET_PRIORITY } from '../../actions/ticketpriorityAction';


class TicketPriority extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highLimitError: false,
            lowLimitError: false,
            mediumLimitError: false,
            highError: false,
            mediumError: false,
            lowError: false,

            highDayLimitError: false,
            lowDayLimitError: false,
            mediumDayLimitError: false,
            highDayError: false,
            mediumDayError: false,
            lowDayError: false,
        }
    }
    componentDidMount() {
        this.props.EmptyPriority()
        this.props.ViewTicketPriority()
        // this.props.DefaultPriority()
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const { HandleChange } = this.props;
        console.log("Input Name:", name);
        console.log("Input Value:", value);
        if (name == 'high') {
            const regex = /^0|[^0-9\s]/g; // This regex matches anything that is not a digit or whitespace
            const filtered = value.replace(regex, '');
            if (filtered) {
                HandleChange('high', filtered);
                this.setState({ highError: false })
            } else {
                HandleChange('high', filtered);
                this.setState({ highError: true, highZeroError: false })
            }
        }
        if (name == 'medium') {
            const regex = /^0|[^0-9\s]/g; // This regex matches anything that is not a digit or whitespace
            const filtered = value.replace(regex, '');
            if (filtered) {
                HandleChange('medium', filtered);
                this.setState({ mediumError: false })
            } else {
                HandleChange('medium', filtered);
                this.setState({ mediumError: true })
            }
        }
        if (name == 'low') {
            const regex = /^0|[^0-9\s]/g; // This regex matches anything that is not a digit or whitespace
            const filtered = value.replace(regex, '');
            if (filtered) {
                HandleChange('low', filtered);
                this.setState({ lowError: false })
            } else {
                HandleChange('low', filtered);
                this.setState({ lowError: true })
            }
        }
        if (name == 'daysforhigh') {
            console.log("daysforhigh");
            const regex = /^0|[^0-9\s]/g;
            const filtered = value.replace(regex, '');
            if (filtered) {
                HandleChange('daysforhigh', filtered);
                this.setState({ highDayError: false })
            } else {
                HandleChange('daysforhigh', filtered);
                this.setState({ highDayError: true, highZeroError: false })
            }
        }
        if (name == 'daysformedium') {
            const regex = /^0|[^0-9\s]/g;
            const filtered = value.replace(regex, '');
            if (filtered) {
                HandleChange('daysformedium', filtered);
                this.setState({ mediumDayError: false })
            } else {
                HandleChange('daysformedium', filtered);
                this.setState({ mediumDayError: true })
            }
        }
        if (name == 'daysforlow') {
            const regex = /^0|[^0-9\s]/g;
            const filtered = value.replace(regex, '');
            if (filtered) {
                HandleChange('daysforlow', filtered);
                this.setState({ lowDayError: false })
            } else {
                HandleChange('daysforlow', filtered);
                this.setState({ lowDayError: true })
            }
        }
    }

    submitPriority = (e) => {
        this.setState({ spinner: false })
        const { TicketPriorityState, AccountState } = this.props;
        const data = TicketPriorityState.ticketpriority;
        const languageData = AccountState.account.SelectedlanguageData
        let valid = 1;
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }
        if (!data.high) {
            this.setState({ highError: true });
            valid = 0;
        }
        if (!data.medium) {
            this.setState({ mediumError: true });
            valid = 0;
        }
        if (!data.low) {
            this.setState({ lowError: true });
            valid = 0;
        }
        if (!data.daysforhigh) {
            this.setState({ highDayError: true });
            valid = 0;
        }
        if (!data.daysformedium) {
            this.setState({ mediumDayError: true });
            valid = 0;
        }
        if (!data.daysforlow) {
            this.setState({ lowDayError: true });
            valid = 0;
        }

        if (valid) {
            this.setState({ saving: true, spinner: true })
            axios.post(ImportedURL.API.updateTicketPriority, data)
                .then((res) => {
                    this.props.EmptyPriority()
                    this.props.ViewTicketPriority();
                    this.setState({ saving: false, spinner: false })
                    Success(("Ticket Priority") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
                }).catch(({ response }) => {
                    this.setState({ saving: false, spinner: false })
                    if (response != undefined) {
                        if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else if (response.status == 400) {
                            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    }
                });
        }
    }
    render() {
        const { TicketPriorityState } = this.props;
        const data = TicketPriorityState.ticketpriority
        const spinner = TicketPriorityState.spinner
        const { lowError, mediumError, highError, highDayError, mediumDayError, lowDayError, highLimitError, lowLimitError, mediumLimitError,
            highDayLimitError, lowDayLimitError, mediumDayLimitError } = this.state;
        return (
            <>
                <div className='card-body  ticketpriority'>
                    {/* <h5>Ticket priority</h5> */}
                    <Row>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2'>
                            <Row>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2'>
                                    <div className='title'><h5>Ticket priority</h5> </div>

                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2'>
                                    <div className='title'> <h5>Notification Minutes</h5> </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2'>
                                    <div className='title'> <h5>Due Date Range</h5> </div>
                                </div>

                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2 '>
                                    <label class="form-label">High</label>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2'>
                                    <div class="form-group">
                                        <input type="text" class="form-control placevalue" onChange={this.handleChange} name="high" placeholder="High" value={data.high} />
                                        <div class="invalid-feedback" style={{ display: highError ? 'block' : 'none' }}>High priority minutes is required</div>
                                        <div class="invalid-feedback" style={{ display: highLimitError ? 'block' : 'none' }}>Maximum limit reached</div>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2'>
                                    <div class="form-group">
                                        <input type="text" class="form-control placevalue" onChange={this.handleChange} name="daysforhigh" placeholder="Highday" value={data.daysforhigh} />
                                        <div class="invalid-feedback" style={{ display: highDayError ? 'block' : 'none' }}>High priority days is required</div>
                                        <div class="invalid-feedback" style={{ display: highDayLimitError ? 'block' : 'none' }}>Maximum limit reached</div>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2 '>
                                    <label class="form-label">Medium</label>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2'>
                                    <div class="form-group">
                                        <input type="text" class="form-control placevalue" onChange={this.handleChange} name="medium" placeholder="Medium" value={data.medium} />
                                        <div class="invalid-feedback" style={{ display: mediumError ? 'block' : 'none' }}>Medium priority minutes is required</div>
                                        <div class="invalid-feedback" style={{ display: mediumLimitError ? 'block' : 'none' }}>Maximum limit reached</div>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2'>
                                    <div class="form-group">
                                        <input type="text" class="form-control placevalue" onChange={this.handleChange} name="daysformedium" placeholder="Mediumday" value={data.daysformedium} />
                                        <div class="invalid-feedback" style={{ display: mediumDayError ? 'block' : 'none' }}>Medium priority days is required</div>
                                        <div class="invalid-feedback" style={{ display: mediumDayLimitError ? 'block' : 'none' }}>Maximum limit reached</div>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2' >
                                    <label class="form-label">Low</label>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2'>
                                    <div class="form-group">
                                        <input type="text" class="form-control placevalue" onChange={this.handleChange} name="low" placeholder="Low" value={data.low} />
                                        <div class="invalid-feedback" style={{ display: lowError ? 'block' : 'none' }} >Low priority minutes is required</div>
                                        <div class="invalid-feedback" style={{ display: lowLimitError ? 'block' : 'none' }} >Maximum limit reached</div>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2'>
                                    <div class="form-group">
                                        <input type="text" class="form-control placevalue" onChange={this.handleChange} name="daysforlow" placeholder="Lowday" value={data.daysforlow} />
                                        <div class="invalid-feedback" style={{ display: lowDayError ? 'block' : 'none' }} >Low priority days is required</div>
                                        <div class="invalid-feedback" style={{ display: lowDayLimitError ? 'block' : 'none' }} >Maximum limit reached</div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Row >
                    <div className="card-footer text-right">
                        <button type="submit" style={{ marginBottom: '20px' }} className="btn commor_save" onClick={this.submitPriority}><i className={this.state.saving ? "fa fa-spinner fa-spin mr-2" : "fe fe-save mr-2"}></i>{this.state.saving ? "Saving" : "Save"}</button>
                    </div>
                </div >
                <div id='overlay' style={{ display: spinner ? 'block' : 'none' }}></div>
                {spinner ?
                    <div className='model_loader_poss'>
                        <img className='loader_img_style_model' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_model' animation="border" variant="info" >
                        </Spinner>
                    </div>
                    : ''}
            </>
        )
    }
}


const mapStateToProps = state => ({
    AccountState: state.account,
    TicketPriorityState: state.ticketpriority,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleChange: AC_HANDLE_TICKET_PRIORITY_CHANGE,
        ViewTicketPriority: AC_VIEW_TICKET_PRIORITY,
        EmptyPriority: AC_EMPTY_TICKET_PRIORITY,
        DefaultPriority: AC_DEFAULT_TICKET_PRIORITY
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(TicketPriority);
