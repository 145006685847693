import axios from 'axios';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ImportedUrl from '../../common/api';
import { AC_LIST_USER } from '../../actions/userAction';
import { Error, Success } from '../../common/swal';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { translateContent } from '../../common/translate';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import { onErrorImage } from '../../common/validate';
import ImportedURL from '../../common/api';

class ViewTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticket: {},
            listusers: [],
            listComments: [],
            selectAssignee: true,
            comment: '',
            history: {},
            spinner: true,
            nxpvspinner: false,
            indexOfImagesWsa: 0,
            showModalWsa: false,

            indexOfImagesWca: 0,
            showModalWca: false,

            indexOfVideosWca: 0,
            showVideosModalWca: false,
            originaltitle: ''
        }
    }

    setAssignee = e => {
        const { name, value, label } = e;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (value) {
            const ticketid = this.props.match.params.ticketid;
            const formData = {
                status: 'assigned',
                ticketId: ticketid,
                assignee: value,
            }
            this.setState({ spinner: true })
            axios.post(ImportedUrl.API.assignTaskerToTicket, formData)
                .then((res) => {
                    this.setState({ comment: '' });
                    axios.get(ImportedUrl.API.ticketData + "/" + ticketid)
                        .then((res) => {
                            this.setState({
                                ticket: res.data.ticket ? res.data.ticket : {},
                                listComments: res.data.comments ? res.data.comments : [],
                                history: res.data.tickethistory ? res.data.tickethistory : {},
                                selectAssignee: false
                            });
                            this.setState({ spinner: false })
                        }).catch(({ response }) => {
                            if (response.status == 500) {
                                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                            } else if (response.status == 502) {
                                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                            } else {
                                Error(response.statusMessage)
                            }
                        });
                }).catch(({ response }) => {
                    this.setState({ spinner: false })
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else if (response.status == 429) {
                        Error(languageData && languageData.TICKET_STATUS_CHANGED ? languageData.TICKET_STATUS_CHANGED : "Ticket status has been changed")
                    } else {
                        Error(response.statusMessage)
                    }
                });
        } else {
            Error((languageData && languageData.SELECT_AN_USER ? languageData.SELECT_AN_USER : "Select an user"));
        }
    }

    submitComment = () => {
        const { AccountState } = this.props;
        const ticketid = this.props.match.params.ticketid;
        const formData = {
            comment: this.state.comment,
            ticket: this.state.ticket ? this.state.ticket._id : '',
        }
        const languageData = this.props.AccountState.account.SelectedlanguageData
        var comment = this.state.comment.trim()
        let valid = true
        if (!comment) {
            valid = false
            this.setState({ comment: comment })
        }
        if (valid) {
            axios.post(ImportedUrl.API.addComment, formData)
                .then((res) => {
                    this.setState({ comment: '' });
                    Success((languageData && languageData.COMMENT_ADDED_SUCCESSFULLY ? languageData.COMMENT_ADDED_SUCCESSFULLY : "Comment added successfully"))
                    axios.get(ImportedUrl.API.listComments + "/" + ticketid)
                        .then((res) => {
                            this.setState({ listComments: res.data })
                        }).catch(({ response }) => { Error(response.statusText) });
                }).catch(({ response }) => {
                    if (response.status == 400) {
                        Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                    } else if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else {
                        Error(response.statusMessage)
                    }
                });
        }
    }

    onPreviousTicket = (id) => {
        const searchParams = new URLSearchParams(window.location.search.substring(1));
        let params = this.searchUrlParams()
        const ticketid = id;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        this.setState({
            nxpvspinner: true, originaldescription: false, originaltitle: false
        })
        this.props.history.push('/view-ticket-analytics/' + ticketid + "?" + searchParams);
        axios.get(ImportedUrl.API.ticketData + "/" + ticketid, { params: params })
            .then((res) => {
                this.setState({

                    ticket: res.data.ticket ? res.data.ticket : {},
                    listComments: res.data.comments ? res.data.comments : [],
                    history: res.data.tickethistory ? res.data.tickethistory : {},
                    nxpvspinner: false
                })

            }).catch(({ response }) => {
                if (response.status == 500) {
                    Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                } else if (response.status == 502) {
                    Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                } else {
                    Error(response.statusMessage)
                }
            });
        //this.setState({ modalType: "View" })

    }
    onNextTicket = (id) => {
        const searchParams = new URLSearchParams(window.location.search.substring(1));
        let params = this.searchUrlParams()
        const ticketid = id;
        this.setState({
            nxpvspinner: true, originaldescription: false, originaltitle: false
        })
        this.props.history.push('/view-ticket-analytics/' + ticketid + "?" + searchParams);
        axios.get(ImportedUrl.API.ticketData + "/" + ticketid, { params: params })
            .then((res) => {
                this.setState({
                    ticket: res.data.ticket ? res.data.ticket : {},
                    listComments: res.data.comments ? res.data.comments : [],
                    history: res.data.tickethistory ? res.data.tickethistory : {},
                    nxpvspinner: false
                })
            }).catch(({ response }) => { Error(response.statusText) });
    }
    getStatus(status) {
        if (!status) return '';
        if (status == 'open') {
            return `<span class="tag ticket_open" style="cursor: default;">Open</span>`;
        } else if (status === 'inprogress') {
            return '<span class="tag ticket_inprogress" style="width: 85px; cursor: default;">Inprogress</span>';
        } else if (status === 'reopened') {
            return '<span class="tag ticket_reopened" style="width: 85px; cursor: default;">Reopened</span>';
        } else if (status === 'assigned') {
            return '<span class="tag ticket_assigned" style="cursor: default;">Assigned</span>';
        } else if (status === 'inreview') {
            return '<span class="tag ticket_inreview" style="cursor: default;">Inreview</span>';
        } else if (status === 'closed') {
            return '<span class="tag ticket_closed" style="cursor: default;">Closed</span>';
        } else if (status === 'completed') {
            return '<span class="tag ticket_completed" style="width: 85px; cursor: default;">Completed</span>';
        } else if (status === 'isDeleted') {
            return '<span class="tag ticket_deleted" style="cursor: default;">Deleted</span>';
        } else
            return `<span class="tag default" style="width: 85px; cursor: default;">${status}</span>`;
    }
    openModalAndSetIndexWsa = (index) => {
        this.setState({ indexOfImagesWsa: index, showModalWsa: true })
    }
    openModalAndSetIndexWca = (index) => {
        this.setState({ indexOfImagesWca: index, showModalWca: true })
    }
    openVedioModelWsa = (index) => {
        this.setState({ indexOfVideosWca: index, showVideosModalWca: true })
    }
    searchUrlParams = () => {
        const searchParams = new URLSearchParams(window.location.search.substring(1));
        const fromdate = searchParams.get('startDate') ? decodeURIComponent(searchParams.get('startDate')) : '';
        const todate = searchParams.get('endDate') ? decodeURIComponent(searchParams.get('endDate')) : '';
        const hotel = searchParams.get('hotel') ? decodeURIComponent(searchParams.get('hotel')) : '';
        const status = searchParams.get('status') ? decodeURIComponent(searchParams.get('status')) : '';
        const group = searchParams.get('group') ? decodeURIComponent(searchParams.get('group')) : '';
        const category = searchParams.get('category') ? decodeURIComponent(searchParams.get('category')) : '';
        const user = searchParams.get('user') ? decodeURIComponent(searchParams.get('user')) : '';
        const priority = searchParams.get('priority') ? decodeURIComponent(searchParams.get('priority')) : '';
        const roomno = searchParams.get('roomno') ? decodeURIComponent(searchParams.get('roomno')) : '';
        const clickedBarIndex = searchParams.get('clickedBarIndex') ? decodeURIComponent(searchParams.get('clickedBarIndex')) : '';
        let params = {}
        if (fromdate) params["fromdate"] = fromdate
        if (hotel) params["hotel"] = [hotel]
        if (todate) params["todate"] = todate
        if (status) params["status"] = status
        if (group) params["group"] = group
        if (category) params["category"] = category
        if (user) params["user"] = user
        if (priority) params["priority"] = priority
        if (roomno) params["roomno"] = roomno
        if (clickedBarIndex) params["clickedBarIndex"] = clickedBarIndex
        return params;
    }
    componentDidMount() {
        let params = this.searchUrlParams()
        const ticketid = this.props.match.params.ticketid;
        const account = this.props.AccountState.account;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (!ImportedURL.SUPERADMIN.includes(account.role)) {
            this.props.ListUser({ 'assignTicket': true })
        }
        if (ticketid) {
            axios.get(ImportedUrl.API.ticketData + "/" + ticketid, { params: params })
                .then((res) => {
                    this.setState({
                        ticket: res.data.ticket ? res.data.ticket : {},
                        listComments: res.data.comments ? res.data.comments : [],
                        history: res.data.tickethistory ? res.data.tickethistory : {}
                    });
                    this.setState({ spinner: false })
                }).catch(({ response }) => {
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else {
                        Error(response.statusMessage)
                    }
                });
        }
        //default scroll top the page
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    translateLang = async (field, text, lang) => {
        this.setState({ spinner: true })
        let val = await translateContent(text, lang);
        if (val) {
            this.setState({ spinner: false })
        }
        this.setState({
            ticket: { ...this.state.ticket, [field]: val },
            ['original' + field]: text
        });
    }

    originalLang = async (field) => {
        this.setState({
            ticket: { ...this.state.ticket, [field]: this.state['original' + field] },
            ['original' + field]: ''
        });
    }
    handleImageClick = (video) => {
        this.setState({ show: true, video: video })
    }
    handleClose = () => {
        this.setState({ show: false, video: '' })
    }
    render() {

        //light box -------- >
        const { indexOfImagesWsa, showModalWsa, indexOfImagesWca, showModalWca } = this.state;

        const { AccountState, UserState } = this.props;
        const ticketworkflowreceive = UserState.ticketworkflow;
        const role = AccountState.account ? AccountState.account.role : '';
        const { ticket, listComments, history } = this.state;
        const listUser = UserState.listusers;
        var historyData = history.history
        const logo = AccountState.account.logo;
        const data = typeof logo == 'string' ? logo : '';
        let nextTicket = ticket.next;
        let previousTicket = ticket.previous;

        const photos = ticket.images ? ticket.images.map((image, index) =>
            <div className="col-4" key={index}>
                <div style={{ height: '200px', cursor: 'pointer' }} onClick={() => this.openModalAndSetIndexWsa(index)}>
                    <img className="d-block img-fluid rounded w-100" src={ImportedUrl.FILEURL + image} alt="image" width='100%' style={{ objectFit: 'cover', height: '100%' }} onError={onErrorImage} />
                    <span className="link-overlay-bg rounded"><i className="fa fa-search" /></span>
                </div>
                {showModalWsa && (
                    <div className='react-image-video-lightbox'>
                        <ReactImageVideoLightbox
                            data={ticket.images.map((item) => {
                                return ({ url: ImportedUrl.FILEURL + item, type: "photo", altTag: "No image to display" })
                            })}
                            startIndex={indexOfImagesWsa}
                            showResourceCount={true}
                            onCloseCallback={() => this.setState({ showModalWsa: false })}
                        />
                    </div>
                )}
            </div>
        ) : [];

        const audios = ticket.audios ? ticket.audios.map((audio, item) => <audio
            id="audio"
            src={ImportedUrl.FILEURL + audio}
            type="video/mp4"
            controls
            key={item}
        ></audio>) : [];
        const vedioWsa = [];
        const videos = ticket.videos ? ticket.videos.map((video, index) => {
            vedioWsa.push({
                index: index,
                url: ImportedUrl.FILEURL + video,
                type: "video",
                title: "some video",
            })
            return (
                <>
                    <div className="col-4 mb-1">
                        <div className='vedio_bg' style={{ height: '200px', width: '100%', position: 'relative' }} onClick={() => { this.handleImageClick(video) }}>
                            <video
                                muted
                                id="video"
                                src={ImportedUrl.FILEURL + video}
                                type="video/quicktime"
                                width='100%'
                                key={index}
                                height='100%'
                            ></video>
                            <div className='vedio_overlay' onClick={() => this.openVedioModelWsa(index)}></div>
                            <div className='play_is'>
                                <span className='vedio_play_icon'><i className="fa fa-play" data-toggle="tooltip" title="fa fa-play-circle"></i></span>
                            </div>
                        </div>
                    </div>
                </>
            )
        }) : [];

        const workCompletedPhotos = ticket.reviewimages ? ticket.reviewimages.map((image, index) => <div className="col-4" key={index}>
            <div style={{ height: '200px', width: '100%', cursor: 'pointer' }} onClick={() => this.openModalAndSetIndexWca(index)}>
                <img className="d-block img-fluid rounded w-100" src={ImportedUrl.FILEURL + image} alt="image" width='100%' style={{ objectFit: 'cover', height: '100%' }} onError={onErrorImage} />
                <span className="link-overlay-bg rounded"><i className="fa fa-search" /></span>
            </div>
            {showModalWca && (
                <ReactImageVideoLightbox
                    data={ticket.reviewimages.map((item) => {
                        return ({ url: ImportedUrl.FILEURL + item, type: "photo", altTag: "No image to display" })
                    })}
                    startIndex={indexOfImagesWca}
                    showResourceCount={true}
                    onCloseCallback={() => this.setState({ showModalWca: false })}
                />
            )}
        </div>) : [];

        const workCompletedAudios = ticket.reviewaudios ? ticket.reviewaudios.map((audio, item) => <audio
            id="audio"
            src={ImportedUrl.FILEURL + audio}
            type="video/mp4"
            controls
            key={item}
        ></audio>) : [];

        const workCompletedVideos = ticket.reviewvideos ? ticket.reviewvideos.map((video, item) =>
            <video
                id="video"
                src={ImportedUrl.FILEURL + video}
                type="video/mp4"
                controls
                width='300px'
                key={item}
                height='200px'
            ></video>
        ) : [];

        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        const deflang = account.language ? account.language : 'en';
        var dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }


        const userArrayOption = [];
        listUser.sort((a, b) => Number(a.categorysort) - Number(b.categorysort)).filter(e => e.status == true).map(item => {
            if (ticketworkflowreceive.multiplecategory && ticketworkflowreceive.multiplecategory.includes(item.categoryid) || ticketworkflowreceive.ticketuser && ticketworkflowreceive.ticketuser.includes(item._id)) {
                if (account._id != item._id) {
                    userArrayOption.push({ label: item.name + ' (' + item.category + ')', value: item._id, name: 'assignee' });
                }
            }
        })
        const searchParams = new URLSearchParams(window.location.search.substring(1));
        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/ticket-analytics"><h6> Ticket Analytics</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>View Ticket</h6></p>
                    </div>
                    <div className="section-body pt-3 view_ticket">
                        <div id="overlay" style={{ display: this.state.nxpvspinner ? 'block' : 'none' }}></div>
                        <div className="container-fluid">
                            <div className="card">
                                <div className='card_body_border_bottom'>
                                    <div className="card-header res_tit_tick" style={{ padding: '20px', background: 'white' }}>
                                        <div className='d-flex'>
                                            <div className='rounded_icon'><i className="fa fa-ticket mr-2 "></i></div>
                                            <div className='d-flex align-items-center'>
                                                <h3 className="card-title " style={{ marginTop: '5px' }}>View Ticket</h3>
                                            </div>
                                        </div>
                                        <div className="header-action view_btn_tic_res" >
                                            <Link to={searchParams ? '/ticket-analytics' + "?" + searchParams : '/ticket-analytics'}><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button></Link>
                                        </div>
                                    </div>


                                </div>
                                <div className='card-body'>
                                    <div className="row clearfix">

                                        <div className="col-xl-8 col-lg-4 col-md-12">
                                            <div className="card" style={{ display: ticket.next == 0 && ticket.previous == 0 ? 'none' : 'block' }}>
                                                <div className=' next_previous_btn '>
                                                    {nextTicket != 0 ? <div onClick={(e) => this.onPreviousTicket(nextTicket)} className='left_ticket' ><i class="fa fa-arrow-circle-left mr-2" aria-hidden="true"></i>Previous</div> : <div></div>}
                                                    {previousTicket != 0 ? <div onClick={(e) => this.onNextTicket(previousTicket)} className='right_ticket'><i class="fa fa-arrow-circle-right mr-2" aria-hidden="true"></i>Next </div> : <div></div>}
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="h6" style={{ marginTop: '15px', marginBottom: "15px" }}>{ticket.ticketid} - {ticket.title}
                                                        {this.state.originaltitle ?
                                                            <span className='tag tag-blue_tickets' onClick={() => this.originalLang('title')}>See Original
                                                            </span> :
                                                            <>
                                                                {ticket.isLanguage ?
                                                                    <span className='tag tag-blue_tickets' onClick={() => this.translateLang('title', ticket.title, deflang)}>See Translation
                                                                    </span> : ''
                                                                }
                                                            </>
                                                        }
                                                    </h3>
                                                </div>
                                            </div>
                                            {ticket.description !== '' ?
                                                <div className="card">
                                                    <div className="card-body view_ticket_dec">
                                                        <span className="h6">Description</span>
                                                        <p>{ticket.description}
                                                            {this.state.originaldescription ?
                                                                <span className='tag tag-blue_tickets' onClick={() => this.originalLang('description')}>See Original
                                                                </span> :
                                                                <>
                                                                    {ticket.isLanguageDescription &&
                                                                        <span className='tag tag-blue_tickets' onClick={() => this.translateLang('description', ticket.description, deflang)}>See Translation
                                                                        </span>
                                                                    }
                                                                </>
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                : ''}
                                            {photos.length ?
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Image Attachments</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div>
                                                            <div className="row img-gallery">
                                                                {photos}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : ''}
                                            {videos.length ? <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">Video</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div>
                                                        <div className="row">
                                                            {videos}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''}
                                            {audios.length ? <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">Audio</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div>
                                                        <div className="row img-gallery">
                                                            <div className="col-8">
                                                                {audios}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''}

                                            {workCompletedPhotos.length ? <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">Work completed Image Attachments</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div>
                                                        <div className="row img-gallery">
                                                            {workCompletedPhotos}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                : []}
                                            {workCompletedAudios.length ?
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Work completed Audio Attachments</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div>
                                                            <div className="row img-gallery">
                                                                <div className="col-8">
                                                                    {workCompletedAudios}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : []}

                                            {workCompletedVideos.length > 0 ?
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Work completed Video Attachments</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    {workCompletedVideos}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : []}
                                            {ticket.notes &&
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Task feedback from guest</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div>
                                                            <div className="row img-gallery">
                                                                <div className="col-8">
                                                                    {ticket.notes}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-lg-4 col-md-12">
                                            <div className="Over_all_table_style card">
                                                <div className="card-header">
                                                    <h3 className="card-title">Details</h3>
                                                </div>
                                                <table className="table card-table">
                                                    <tbody>
                                                        {/* {ticket.assignstatus && !ticket.assigneename && !ticket.assignername && ticket.status != 'isDeleted' &&
                                                            <tr>
                                                                <td> <p className='detail_table'>Assign</p></td>
                                                                <td className="">
                                                                    <div className="row gutters-sm">
                                                                        <div className="col-12">
                                                                            <Select
                                                                                onChange={this.setAssignee}
                                                                                options={userArrayOption ? userArrayOption.map(item => {
                                                                                    return { label: item.label, value: item.value, name: 'assignee' }
                                                                                }) : ''}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        } */}
                                                        {ticket.assigneename &&
                                                            <tr>
                                                                <td><p className='detail_table'>Assignee</p></td>
                                                                <td className="text-right"><span className="text-muted ">{ticket.assigneename}</span></td>
                                                            </tr>
                                                        }
                                                        {ticket.assignername &&
                                                            <tr>
                                                                <td><p className='detail_table'>Assigner</p></td>
                                                                <td className="text-right"><span className="text-muted ">{ticket.assignername}</span></td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td><p className='detail_table'>Reporter</p></td>
                                                            <td className="text-right"><span className="text-muted ">{ticket.reporter}</span></td>
                                                        </tr>
                                                        {ticket.roomno ? <tr>
                                                            <td><p className='detail_table'>Room</p></td>
                                                            <td className="text-right"><span className="text-muted ">{ticket.roomno}</span></td>
                                                        </tr> :
                                                            <tr>
                                                                <td><p className='detail_table'>Place</p></td>
                                                                <td className="text-right"><span className="text-muted">{ticket.place}</span></td>
                                                            </tr>
                                                        }
                                                        {ImportedURL.SUPERADMIN.includes(role) &&
                                                            <>
                                                                <tr>
                                                                    <td><p className='detail_table'>Property</p></td>
                                                                    <td className="text-right"><span className="text-muted">{ticket.hotel}</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><p className='detail_table'>Location</p></td>
                                                                    <td className="text-right"><span className="text-muted">{ticket.hotelLocation}</span></td>
                                                                </tr>
                                                            </>
                                                        }
                                                        <tr>
                                                            <td><p className='detail_table'>Status</p></td>
                                                            <td className="text-right"><span className="text-center" dangerouslySetInnerHTML={{ __html: this.getStatus(ticket.status) }}></span></td>
                                                        </tr>
                                                        {(ticket.dueDate && ticket.assignername) &&
                                                            <tr>
                                                                <td><p className='detail_table'>Due Date & Time</p></td>
                                                                <td className="text-right"><span className="text-muted ">{moment(ticket.dueDate).format(dtFormat)}</span></td>
                                                            </tr>
                                                        }
                                                        {
                                                            ticket.issuecategoryname
                                                                ?
                                                                <tr>
                                                                    <td><p className='detail_table'>Issue Category</p></td>
                                                                    <td className="text-right"><span className="text-muted ">{ticket.issuecategoryname}</span></td>
                                                                </tr>
                                                                : ""
                                                        }
                                                        {(ticket.completedAt) &&
                                                            <tr>
                                                                <td><p className='detail_table'>Completed Date & Time</p></td>
                                                                <td className="text-right"><span className="text-muted ">{moment(ticket.completedAt).format(dtFormat)}</span></td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td><p className='detail_table'>Priority</p></td>
                                                            <td className="text-right"><span className={ticket.priority == "High" ? 'text-muted-priority-red' : (ticket.priority == "Medium" ? 'text-muted-priority-orange' : 'text-muted-priority-grey')} >{ticket.priority}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {!ImportedURL.SUPERADMIN.includes(role) ?
                                            <div className="col-lg-12 col-md-12">
                                                <div className={listComments.length > 0 ? "card" : ''}>
                                                    {listComments && listComments.length > 0 || ticket.status == "inprogress" ?
                                                        <div className="card-header">
                                                            <h3 className="card-title">Comments</h3>
                                                        </div> : ''}
                                                    <div className={listComments && listComments.length > 0 || ticket != undefined && ticket && ticket.status == "inprogress" ? "card-body" : ''}>
                                                        {ticket != undefined && ticket && ticket.status == "inprogress" &&
                                                            <div className='comment_box' style={ImportedURL.SUPERADMIN.includes(role) ? { display: 'none' } : {}}>
                                                                <div className="form-group">
                                                                    <textarea rows={4} className="form-control no-resize" placeholder="Type here..." value={this.state.comment} onChange={(e) => {
                                                                        this.setState({ comment: e.target.value })
                                                                    }} />
                                                                    <button className="btn btn-primary mt-2" disabled={this.state.comment === ''} onClick={this.submitComment}>Post</button>
                                                                </div>
                                                            </div>
                                                        }
                                                        {listComments && listComments.length > 0 &&
                                                            <ul className={listComments.length > 3 ? "right_chat scroll_ticket_comment" : "right_chat"} style={{ display: 'block', marginTop: '2px solid #ccc' }}>
                                                                {listComments.map((data, i) => {
                                                                    return <li className="offline" key={i}>
                                                                        <div className="media">
                                                                            <img className="media-object" src="../assets/images/xs/avatar2.jpg" alt="fake_url" />
                                                                            <div className="media-body">
                                                                                <span className="name">{data.userName} - {data.userCategory}<small className="float-right">{moment(data.createdAt).format(dtFormat)}</small></span>
                                                                                <span className="message">{data.comment}</span>
                                                                                {/* <span className="badge badge-outline status" /> */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                }
                                                                )}
                                                            </ul>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            : ''}
                                        <div className={history && history.history && history.history.length > 5 ? "col-xl-12 col-lg-12 col-md-12 ticket_history ticket_history_data" : "col-xl-12 col-lg-12 col-md-12 ticket_history"}>
                                            {historyData != undefined ?
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Ticket History</h3>
                                                        <div className="card-options">
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        {
                                                            history.history ? history.history.map((item, i) => {
                                                                let status = "";
                                                                if (item.status == 'open') {
                                                                    status = 'created a ticket'
                                                                } else if (item.status == 'edit') {
                                                                    status = 'updated a ticket'
                                                                } else if (item.status == 'assigned' && !item.accepted) {
                                                                    status = 'assigned a ticket to'
                                                                } else if (item.status == 'inprogress') {
                                                                    status = 'accepted a ticket'
                                                                } else if (item.status == 'reassigned') {
                                                                    status = 'rejected a ticket'
                                                                } else if (item.status == 'reopened') {
                                                                    status = 'reopened a ticket'
                                                                } else if (item.status == 'inreview') {
                                                                    status = 'closed the ticket'
                                                                } else if (item.status == 'completed') {
                                                                    status = 'completed the ticket'
                                                                } else if (item.status == 'isDeleted') {
                                                                    status = 'deleted the ticket'
                                                                } else if (item.accepted) {
                                                                    status = 'accepted the ticket'
                                                                }
                                                                return (
                                                                    (<div className="timeline_item ">
                                                                        <img className="tl_avatar" src={item.user && item.user.logo ? ImportedUrl.FILEURL + item.user.logo : "../assets/images/xs/avatar1.jpg"} alt="avatar" onError={onErrorImage} />
                                                                        <span >
                                                                            {item.role} - <span className="font600">{item.user ? item.user.name : ''}</span>  {status} {!item.accepted && <span className="font600">{item.employeename ? item.employeename : ''} </span>}
                                                                            <small className="float-right text-right">{moment(item.time).format(dtFormat)}</small>
                                                                        </span>
                                                                        <div className="msg">
                                                                        </div>
                                                                    </div>)
                                                                )
                                                            }) : ''
                                                        }
                                                    </div>
                                                </div>
                                                : []}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='csv_model'>
                        <Modal show={this.state.show} onHide={this.handleClose} size="lg" >
                            <Modal.Header closeButton>
                                <Modal.Title>Video Attachment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ height: '500px' }}>
                                <video controls autoPlay style={{ width: '100%', height: '400px' }}>
                                    <source src={ImportedUrl.FILEURL + this.state.video} type="video/mp4" />
                                </video>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div >

                < div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }
                }></div >
                {
                    this.state.spinner ?
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                        : ""
                }
                {
                    this.state.nxpvspinner ?
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                        : ''
                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    UserState: state.user,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ListUser: AC_LIST_USER }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewTicket);