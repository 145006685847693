import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import Chart from "react-apexcharts";
import Spinner from "react-bootstrap/Spinner";
import ImportedURL from "../../common/api";
import { bindActionCreators } from "redux";
import { AC_LIST_GROUP } from "../../actions/groupAction";
import { Error } from "../../common/swal";
import { AC_LIST_HOTEL } from "../../actions/hotelAction";

import {
  imageCellRendererList,
  objectToQueryString,
  onErrorImage,
} from "../../common/validate";
//import { status } from "../../../schemas/hotel";

class ReportCreateAndClosedTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      tickets: [],
      originalData: [],
      userTickets: [],
      categoryCount: [],
      hotelCounts: [],
      lowcount: 0,
      mediumcount: 0,
      highcount: 0,
      ticketid: "",
      hotel: "",
      category: "",
      username: "",
      fromdate: "",
      todate: "",
      month: "",
      day: "",
      status: "",
      hotelName: "",
      showTicket: true,
      openStatus: false,
      assignedStatus: false,
      inprogressStatus: false,
      inreviewStatus: false,
      completedStatus: false,
      reopenedStatus: false,
      reassignedStatus: false,
      totalStatus: true,
      spinner: "",
      columnreset: true,
      ticketCount: "",
      categoryName: "",
      allOption: true,
      allcategoryOption: true,
      isFilterShow: false,
      fromdateValue: "",
      todateValue: "",
      fromdate: '',
      todate: '',
      groupoption: { label: "All", value: "", name: "hotel" },
      statusoption: { label: "All", value: "", name: "status" },
      priorityValue: { label: "All", value: "All", name: "priority" },
      OrderByoption: { label: "All", value: "", name: "orderby" },
      perPage: 25,
      selectedOption: [{ label: "All", value: "", name: "hotel" }],
      categoryOption: [{ label: "All", value: "", name: "category" }],
      isChartView: false,
      categoryState: [],

     
      complianceStatus: this.props.complianceStatus || {
        isMandatory: false, 
      },
      dailyStatus: this.props.dailyStatus || {
        isMandatory: false,  
      },
      weeklyStatus: this.props.weeklyStatus || {
        isMandatory: false,  
      },
      monthlyStatus: this.props.monthlyStatus || {
        isMandatory: false, 
      },
      quarterlyStatus: this.props.quarterlyStatus || {
        isMandatory: false,  
      },
      halfyearlyStatus: this.props.halfyearlyStatus || {
        isMandatory: false,  
      },
      yearlyStatus: this.props.yearlyStatus || {
        isMandatory: false,  
      },
      AdhocStatus: this.props.AdhocStatus || {
        isMandatory: false,  
      },
      activeStatus: this.props.activeStatus || {
        isMandatory: false,  
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { AccountState } = this.props;
    const role = AccountState.role;
   
  }

  componentDidMount() {
  
    const { AccountState } = this.props;
    const account = AccountState.account;
    
    this.setState({ hotelName: account.hotelName ? account.hotelName : "" });
   
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
   
  }
  

 

  handleChange = (e) => {
    var value = [];
    let label = "";
    let dataStatus = true;
    let dataArr = [];
    if (e.length > 0) {
      e.map((item, i) => {
        if (e[e.length - 1].label == "All") {
          value = [];
        }
        if (item.label == "All") {
          dataStatus = false;
          this.setState({ allOption: false });
        } else {
          if (item.label != "All") {
            dataArr.push(item);
            value.push(item.value);
            this.setState({ allOption: true });
            dataStatus = true;
          }
          if (e.length == 1 && i == 0) {
            label = item.label;
          }
        }
        if (value.length == 1) {
          label = item.label;
        } else {
          label = "";
        }
        
      });

    }
   
    this.setState({
      hotel: value,
      hotelName: label,
      selectedOption: dataStatus
        ? dataArr
        : [{ label: "All", value: "", name: "hotel" }],
    });
    //this.resetstatus();
    if (this.state.isChartView) {
      this.fetchCreateTicketReportData();
    }
  };

  setGroup = (selectedOption) => {
    this.setState({ groupoption: selectedOption });
  };
  handleCategoryChange = (e) => {
    var value = [];
    let label = "";
    let dataStatus = true;
    let dataArr = [];
    if (e.length > 0) {
      e.map((item, i) => {
        if (e[e.length - 1].label == "All") {
          value = [];
        }
        if (item.label == "All") {
          dataStatus = false;
          this.setState({ allcategoryOption: false });
        } else {
          if (item.label != "All") {
            dataArr.push(item);
            value.push(item.value);
            this.setState({ allcategoryOption: true });
            dataStatus = true;
          }
          if (e.length == 1 && i == 0) {
            label = item.label;
          }
        }
        if (value.length == 1) {
          label = item.label;
        } else {
          label = "";
        }
      

      });

    }

   
  };

  setPriority = (e) => {
    const { name, value, label } = e; // Destructure the value and label from the selected option
    this.setState({ priorityValue: e });

    this.setState({
      totalStatus: true,
    });
    
   
  };

  fetchConfiguration = () => {
    const { account } = this.props.AccountState;
    const languageData = account.SelectedlanguageData;

    // Make the API request with GET method
    axios
      .get(ImportedURL.API.fetchConfiguration)
      .then((res) => {
        if (res.data) {
          this.setState({ categoryState: res.data });
        }
      })
      .catch((error) => {
        const { response } = error;
        const status = response?.status;
        const statusText = response?.statusText ?? "An error occurred";

        switch (status) {
          case 500:
            Error(
              `${status}: ${languageData?.INTERNAL_SERVER_ERROR ?? "Internal Server Error"
              }`
            );
            break;
          case 502:
            Error(`${status}: ${languageData?.BAD_GATEWAY ?? "Bad Gateway"}`);
            break;
          default:
            Error(`${status}: ${statusText}`);
            break;
        }
      });
  };

  setOrderBy = (e) => {
    const { name, value, label } = e; // Destructure the value and label from the selected option
    this.setState({ OrderByoption: e });

    this.setState({
      orderby: value,
      // OrderByoption:
      // label == "All" ? [{ label: "All", value: "", name: "orderby" }] : [],
      totalStatus: true,
    });
   
    this.setState({ [name + "option"]: e });
  };
  setStatus = (e) => {
    const { name, value, label } = e; // Destructure the value and label from the selected option
    this.setState({ statusoption: e });

    this.setState({
      totalStatus: true,
    });
    

    this.setState({ [name + "option"]: e });
   
  };
  setDateReport = (e) => {
    const { AccountState } = this.props;
    const { name, value } = e.target;
    const account = AccountState.account;
    let tzone = "America/chicago";

    if (account && account.tzone) {
      tzone = account.tzone;
    }

    if (name === "fromdate") {
      this.setState({
        fromdate: value,
        fromdateValue: value,
        todate: "",
        todateValue: "",
        month: "",
      });
     

     
    } else if (name === "todate") {
      this.setState({ todate: value, todateValue: value, month: "" });
     
    }

    
  };

  

 
  filterReset = (e) => {
    this.resetLocalStorage();    
    localStorage.setItem("searchfromdate", this.state.fromdate ? this.state.fromdate + "T00:00:00" : "");
    localStorage.setItem("searchtodate", this.state.todate ? this.state.todate + "T23:59:59" : "");
    this.setState(
      {
        
        selectedOption: [{ label: "All", value: "", name: "hotel" }],
        searchhotel: "",
        searchcategory: "",
       
        hotel: "",
        hotelName: this.props.AccountState.account.hotelName ? this.props.AccountState.account.hotelName : "",
        groupoption: { label: "All", value: "", name: "hotel" },
       
        statusoption: { label: "All", value: "", name: "status" },
        categoryOption: { label: "All", value: "", name: "category" },
        fromdateValue: this.state.fromdate,
        todateValue: this.state.todate,
        statusProgress: { label: "All", value: 0 },
        isFilterShow: false,
        status: "",
        group: "",
        day: ""
      });
     

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  // Method to handle checkbox change
  handleComplianceChange = (event) => {
    const { checked } = event.target;
    this.setState(prevState => ({
      complianceStatus: {
        ...prevState.complianceStatus,
        isMandatory: checked,  // Update compliance status based on checkbox state
      },
    }), () => {
     
    });
  };
  handleDailyChange = (event) => {
    const { checked } = event.target;
    this.setState(prevState => ({
      dailyStatus: {
        ...prevState.dailyStatus,
        isMandatory: checked,  // Update compliance status based on checkbox state
      },
    }), () => {
     
    });
  };
  handleWeeklyChange = (event) => {
    const { checked } = event.target;
    this.setState(prevState => ({
      weeklyStatus: {
        ...prevState.weeklyStatus,
        isMandatory: checked,  // Update compliance status based on checkbox state
      },
    }), () => {
     
    });
  };
  handleMonthlyChange = (event) => {
    const { checked } = event.target;
    this.setState(prevState => ({
      monthlyStatus: {
        ...prevState.monthlyStatus,
        isMandatory: checked,  // Update compliance status based on checkbox state
      },
    }), () => {
     
    });
  };
  handleQuarterlyChange = (event) => {
    const { checked } = event.target;
    this.setState(prevState => ({
      quarterlyStatus: {
        ...prevState.quarterlyStatus,
        isMandatory: checked,  // Update compliance status based on checkbox state
      },
    }), () => {
     
    });
  };
  handleHalfYearlyChange = (event) => {
    const { checked } = event.target;
    this.setState(prevState => ({
      halfyearlyStatus: {
        ...prevState.halfyearlyStatus,
        isMandatory: checked,  // Update compliance status based on checkbox state
      },
    }), () => {
     
    });
  };
  handleYearlyChange = (event) => {
    const { checked } = event.target;
    this.setState(prevState => ({
      yearlyStatus: {
        ...prevState.yearlyStatus,
        isMandatory: checked,  // Update compliance status based on checkbox state
      },
    }), () => {
     
    });
  };
  handleAdhocChange = (event) => {
    const { checked } = event.target;
    this.setState(prevState => ({
      AdhocStatus: {
        ...prevState.AdhocStatus,
        isMandatory: checked,  // Update compliance status based on checkbox state
      },
    }), () => {
     
    });
  };
  handleActiveStatus = (event) => {
    const { checked } = event.target;
    this.setState(prevState => ({
      activeStatus: {
        ...prevState.activeStatus,
        isMandatory: checked,  // Update compliance status based on checkbox state
      },
    }), () => {
     
    });
  };

  SubmitForm = () => {
   
   
  }

  render() {
    const { AccountState, LandingState } = this.props;
    const { complianceStatus,dailyStatus,weeklyStatus,monthlyStatus,yearlyStatus,halfyearlyStatus,quarterlyStatus,AdhocStatus,activeStatus } = this.state;
  
    const listHotels =
      LandingState.landingdata &&
        LandingState.landingdata.data &&
        LandingState.landingdata.data.length > 1
        ? LandingState.landingdata.data[0]
        : [];

    const account = AccountState.account;
    var dtFormat =
      (account.dateformate != "" ? account.dateformate : "MM/DD/YYYY") +
      " " +
      (account.timeformat != "" ? account.timeformat : "hh:mm A");

    const { lowcount, mediumcount, highcount } = this.state;


    // Creating bar graph for priority
    const options = {
      chart: {
        type: "bar",
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
        events: {
          mouseLeave: function (event, chartContext, config) {
            chartContext.resetSeries();
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true, // Ensures that each bar gets a different color
        },
      },
      colors: ["#FF0000", "#FFA500", "#008000"], // Colors for High, Medium, Low respectively
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex].data[
            opts.dataPointIndex
          ];
        },
      },
      xaxis: {
        categories: ["High", "Medium", "Low"],
      },
      labels: ["High", "Medium", "Low"],
    };

    const series = [
      {
        name: "Priority",
        // data: [lowcount, mediumcount, highcount],
        data: [highcount, mediumcount, lowcount],
      },
    ];


    // ------------------------ends creating bar graph for priority
    // -----------------------------category

    const categories = this.state.categoryCount && this.state.categoryCount.length > 0 ? this.state.categoryCount.map(item => item.name) : [];
    const data = this.state.categoryCount && this.state.categoryCount.length > 0 ? this.state.categoryCount.map(item => item.count) : [];



    const options1 = {
      chart: {
        type: "bar",
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
        events: {
          mouseLeave: function (event, chartContext, config) {
            chartContext.resetSeries();
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true, // Ensures each bar has a different color
        },
      },
      colors: ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#FF8C33", "#33FFF1", "#FFD700", "#8A2BE2"], // Example colors for each category
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex].data[
            opts.dataPointIndex
          ];
        },
      },
      xaxis: {
        categories: categories, // Use extracted categories here
      },
      labels: categories, // Use extracted categories here
      yaxis: {
        labels: {
          formatter: function (val) {
            return Math.floor(val); // Rounds down to the nearest integer
          },
        },
      },

    };

    const series1 = [
      {
        name: "Category Count",
        data: data, // Use extracted data here
      },
    ];

    // ---------------end category

    if (this.state.tickets) {
      this.state.tickets.forEach((object) => {
        object.dtFormat = dtFormat;
      });
    }
    if (this.state.modalType === "View") {
      const myObject = {
        ticketid: this.state.ticketid,
        hotel: this.state.hotel,
        status: this.state.status,
        group: this.state.group,
        fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : "",
        todate: this.state.todate ? this.state.todate + "T23:59:59" : "",
      };
      const objectString = objectToQueryString(myObject);
      return <Redirect to={"view-ticket/" + this.state.id + objectString} />;
    }
   

    const priorityList = [
      { value: "All", label: "All" },
      { value: "1", label: "High" },
      { value: "2", label: "Medium" },
      { value: "3", label: "Low" },
    ];
    const statusList = [
      { value: " ", label: "All" },
      { value: "created", label: "Created" },
      { value: "completed", label: "Completed" }
    ];
    const OrderByList = [
      { value: "All", label: "All" },
      { value: "priority", label: "Priority" },
      { value: "assignee", label: "Assignee" },
      { value: "category", label: "Issue Category" },
    ];
    // -----------------------------------------------------------------------------------------------
    var categoryOptions = [];
    const categoryList = this.state.categoryState;

    if (Array.isArray(categoryList) && categoryList.length > 0) {
      categoryList.forEach((item) => {
        if (item && !item.isdeleted && item.status) {
          const typeLabel = item.name || "Unknown"; // Use the name of the category or 'Unknown' if not defined
          const valueId = item._id || ""; // Use the _id as the value
          categoryOptions.push({
            label: typeLabel,
            value: valueId,
            name: "category",
          });
        }
      });
    }
    if (
      Array.isArray(categoryOptions) &&
      categoryOptions.length !== categoryList.length
    ) {
      categoryOptions.unshift({ label: "All", value: "", name: "category" });
    }

    // Ensure categoryOptions is defined and check its length
    if (Array.isArray(categoryOptions) && categoryOptions.length === 1) {
      categoryOptions = [];
    }
    //   ---------------------------------------------------------------------------------------------------------------

    var hotelOptions = [];

    if (listHotels.length > 0) {
      listHotels.map((item) => {
        if (this.state.groupoption && this.state.groupoption.value) {
          if (this.state.groupoption.value == item.group._id) {
            hotelOptions.push({
              label: `${item.name} ${item.city} ${item.state}`,
              value: item._id,
              logo: item.logo,
              name: "hotel",
            });
          }
        } else {
          hotelOptions.push({
            label: `${item.name} ${item.city} ${item.state}`,
            value: item._id,
            logo: item.logo,
            name: "hotel",
          });
        }
      });
    }
    if (hotelOptions.length != this.state.selectedOption.length) {
      hotelOptions.unshift({ label: "All", value: "", name: "hotel" });
    }
    if (hotelOptions.length == 1) {
      hotelOptions = [];
    }
    const listGroup =
      LandingState.landingdata &&
        LandingState.landingdata.data &&
        LandingState.landingdata.data.length > 1
        ? LandingState.landingdata.data[3]
        : [];
    const groupOptions = [];
    if (listGroup.length > 0) {
      groupOptions.push({ label: "All", value: "", name: "group" });
    }
    if (listGroup.length > 0) {
      listGroup &&
        listGroup
          ?.filter((filterItem) => filterItem.status === true)
          .map((item) => {
            groupOptions.push({
              label: item.name,
              value: item._id,
              name: "group",
            });
          });
    }
    // const { percentage } = this.props; // Assuming percentage is passed as a prop

    const languageData = AccountState.account.SelectedlanguageData;
    const title = this.state.hotelName
      ? this.state.hotelName != "All"
        ? this.state.hotelName + " - Overall Maintenance Reports"
        : "Overall Maintenance Reports"
      : "Overall Maintenance Reports";
    return (
      <>
        
          <div>
            <div className="breadcrump">
              <p>
                {" "}
                <Link to="/">
                  <h6>
                    {languageData && languageData.DASHBOARD
                      ? languageData.DASHBOARD
                      : "Dashboard"}
                  </h6>
                </Link>{" "}
                <span>
                  <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                </span>{" "}
                <Link to="/add-todo-listitem">
                  <h6 className="highlights_breadcrump">
                    {" "}
                    {languageData && languageData.ADD_TO_DO_ITEMS
                      ? languageData.ADD_TO_DO_ITEMS
                      : "Add to do Items"}
                  </h6>
                </Link>
              </p>
            </div>
            {/* {this.state.showTicket ? */}
            <div className="section-body">
              <div className="container-fluid">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="Departments-list"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div
                        className="card-header res_tit_tick"
                        style={{ height: "65px" }}
                      >
                        <div className="d-flex">
                          <div className="rounded_icon">
                            <i className="fa fa-ticket mr-2 "></i>
                          </div>
                          <div className="d-flex align-items-center">
                            <h3 className="card-title">
                              {" "}
                              {languageData &&
                                languageData.ADD_TO_DO_ITEMS
                                ? languageData.ADD_TO_DO_ITEMS
                                : "Add to do Items"}
                            </h3>
                          </div>
                        </div>
                        <div className="card-options">
                          {this.state.isFilterShow && (
                            <button
                              className="btn topsocial_rest_btn btn-primarys "
                              type="button"
                              onClick={this.filterReset}
                              style={{
                                height: "45px !important",
                                width: "115pxpx !important",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa fa-refresh"
                                aria-hidden="true"
                                style={{
                                  fontSize: "20px !importent",
                                  marginRight: "7px",
                                }}
                              ></i>{" "}
                              Reset
                            </button>
                          )}

                          {this.state.tickets.length > 0 ? (
                            <>

                              <button
                                className="btn btn-sm btn-default"
                                style={{ fontSize: "25px" }}
                                onClick={this.toggleView}
                              >
                                <span
                                  className={
                                    this.state.isChartView
                                      ? "fa fa-bar-chart"
                                      : "fe fe-grid"
                                  }
                                ></span>
                              </button>
                            
                            </>
                          ) : (
                            []
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                       
                          {account.allhotels &&
                            account.allhotels?.length > 0 ? (
                            ""
                          ) : (
                            <>
                              <div className="col-md-6 col-sm-6">
                                <label className="form-label">
                                  {languageData && languageData.GROUP
                                    ? languageData.GROUP
                                    : "Group"}
                                </label>
                                <div className="form-group">
                                  <Select
                                    value={
                                      this.state.groupoption
                                        ? {
                                          label: this.state.groupoption.label,
                                        }
                                        : ""
                                    }
                                    onChange={this.setGroup}
                                    options={groupOptions}
                                  />
                                </div>
                              </div>
                              
                              <div className="col-md-6 col-sm-6  ">
                                <label className="form-label">
                                  {languageData && languageData.HOTEL
                                    ? languageData.HOTEL
                                    : "Hotel"}<span className="ml-1" style={{ color: 'red' }}>*</span>
                                </label>
                                <div
                                  className="form-group"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Select
                                    isMulti={true}
                                    value={this.state.selectedOption}
                                    onChange={this.handleChange}
                                    options={hotelOptions}
                                    formatOptionLabel={(e) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {e.label != "All" && (
                                          <img
                                            className="all_search_image_size contain_image"
                                            src={ImportedURL.FILEURL + e.logo}
                                            onError={onErrorImage}
                                          />
                                        )}
                                        <div
                                          style={
                                            e.label != "All"
                                              ? { paddingLeft: "10px" }
                                              : {}
                                          }
                                        >
                                          <span className="all_search_text_size">
                                            {e.label}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    styles={{
                                      menuList: (provided, state) => ({
                                        ...provided,
                                        maxHeight: "200px", // Set the maximum height for the dropdown list
                                        overflowY: "auto", // Enable vertical scroll if needed
                                      }),
                                    }}
                                  />
                                </div>
                              </div>



                            </>
                          )}
                            <div className="col-md-6 col-sm-6 ">
                            <label className="form-label">
                              {"Issue "}
                              {languageData && languageData.CATEGORY
                                ? languageData.CATEGORY
                                : "Category"}<span className="ml-1" style={{ color: 'red' }}>*</span>
                            </label>
                            <div
                              className="form-group"
                              style={{ marginBottom: "10px" }}
                            >
                              <Select
                                isMulti={true}
                                value={this.state.categoryOption}
                                onChange={this.handleCategoryChange}
                                options={categoryOptions}
                                formatOptionLabel={(e) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={
                                        e.label != "All"
                                          ? { paddingLeft: "10px" }
                                          : {}
                                      }
                                    >
                                      <span className="all_search_text_size">
                                        {e.label}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                styles={{
                                  menuList: (provided, state) => ({
                                    ...provided,
                                    maxHeight: "200px", // Set the maximum height for the dropdown list
                                    overflowY: "auto", // Enable vertical scroll if needed
                                  }),
                                }}
                              />
                            </div>
                          </div>
                           <div className="col-md-6 col-md-6">
                                    <label className="form-label" >Title<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                <div className="form-group">
                                    <input type="text" ref={this.titleInputRef} className="form-control" placeholder="Title"  name="title" onChange={this.handleChange} />
                                   
                                </div>
                            </div>
                               <div className="col-md-6 col-md-6">
                                    <label className="form-label" >Todo<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                <div className="form-group">
                                    <input type="text" ref={this.todoInputRef} className="form-control" placeholder="Todo"  name="todo" onChange={this.handleChange} />
                                   
                                </div>
                            </div>
                            <div className='col-md-6 col-md-6'>
                            <div className="form-group" style={{ position: "relative" }}>
                                <label className="form-label">Description</label>
                                <textarea className='form-control w-100' name="description" rows="3" cols="50" onChange={this.handleChange}  placeholder={"Description"} />
                            </div>
                            </div>
                        

                          <div className="col-md-6 col-sm-6 ">
                            <label className="form-label">
                              {languageData && languageData.Priority
                                ? languageData.Priority
                                : "Priority"}<span className="ml-1" style={{ color: 'red' }}>*</span>
                            </label>
                            <div
                              className="form-group"
                              style={{ marginBottom: "10px" }}
                            >
                              <Select
                                value={this.state.priorityValue}
                              
                                onChange={this.setPriority}
                                options={priorityList}
                              />
                            </div>
                          </div>

                         
                          <div className="col-md-6 col-sm-6">
        <label className="form-label">
          {languageData && languageData.Status
            ? languageData.Mandatory_Compliance
            : "Mandatory Compliance"}
          <span className="ml-1" style={{ color: 'red' }}>*</span>
        </label>
        <div className="form-group" style={{ marginBottom: "10px" }}>
          <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
            <input
              type="checkbox"
              checked={complianceStatus.isMandatory}
              name="custom-switch-checkbox"
              className="custom-switch-input"
              onChange={this.handleComplianceChange}
              style={{ cursor: 'pointer' }}
            />
            <span className="custom-switch-indicator"></span>
            <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
              {complianceStatus.isMandatory ? "Mandatory" : "Non Mandatory"}
            </span>
          </label>
        </div>
      </div>
                          <div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Frequency Selection <span className="ml-1" style={{ color: 'red' }}>*</span>
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <div className="row">
          <label className="form-label">
            
          </label>
          
          <div className="col-md-12 col-sm-12">
            <div className="row">
              
              <div className="col-md-3 col-sm-3">
                <label className="form-label">
                  {languageData && languageData.Status ? languageData.Daily : "Daily"}
                </label>
                <div className="form-group" style={{ marginBottom: "10px" }}>
                  <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
                    <input
                      type="checkbox"
                      checked={dailyStatus.isMandatory}
                      name="custom-switch-checkbox"
                      className="custom-switch-input"
                      onChange={this.handleDailyChange}
                      style={{ cursor: 'pointer' }}
                    />
                    <span className="custom-switch-indicator"></span>
                    <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                      {dailyStatus.isMandatory ? "Enable" : "Disable"}
                    </span>
                  </label>
                </div>
              </div>

              <div className="col-md-3 col-sm-3">
                <label className="form-label">
                  {languageData && languageData.Status ? languageData.Weekly : "Weekly"}
                </label>
                <div className="form-group" style={{ marginBottom: "10px" }}>
                  <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
                    <input
                      type="checkbox"
                      checked={weeklyStatus.isMandatory}
                      name="custom-switch-checkbox"
                      className="custom-switch-input"
                      onChange={this.handleWeeklyChange}
                      style={{ cursor: 'pointer' }}
                    />
                    <span className="custom-switch-indicator"></span>
                    <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                      {weeklyStatus.isMandatory ? "Enable" : "Disable"}
                    </span>
                  </label>
                </div>
              </div>

              <div className="col-md-3 col-sm-3">
                <label className="form-label">
                  {languageData && languageData.Status ? languageData.Monthly : "Monthly"}
                </label>
                <div className="form-group" style={{ marginBottom: "10px" }}>
                  <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
                    <input
                      type="checkbox"
                      checked={monthlyStatus.isMandatory}
                      name="custom-switch-checkbox"
                      className="custom-switch-input"
                      onChange={this.handleMonthlyChange}
                      style={{ cursor: 'pointer' }}
                    />
                    <span className="custom-switch-indicator"></span>
                    <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                      {monthlyStatus.isMandatory ? "Enable" : "Disable"}
                    </span>
                  </label>
                </div>
              </div>

              <div className="col-md-3 col-sm-3">
                <label className="form-label">
                  {languageData && languageData.Status ? languageData.Quarterly : "Quarterly"}
                </label>
                <div className="form-group" style={{ marginBottom: "10px" }}>
                  <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
                    <input
                      type="checkbox"
                      checked={quarterlyStatus.isMandatory}
                      name="custom-switch-checkbox"
                      className="custom-switch-input"
                      onChange={this.handleQuarterlyChange}
                      style={{ cursor: 'pointer' }}
                    />
                    <span className="custom-switch-indicator"></span>
                    <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                      {quarterlyStatus.isMandatory ? "Enable" : "Disable"}
                    </span>
                  </label>
                </div>
              </div>

              <div className="col-md-3 col-sm-3">
                <label className="form-label">
                  {languageData && languageData.Status ? languageData.Half : "Half-yearly"}
                </label>
                <div className="form-group" style={{ marginBottom: "10px" }}>
                  <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
                    <input
                      type="checkbox"
                      checked={halfyearlyStatus.isMandatory}
                      name="custom-switch-checkbox"
                      className="custom-switch-input"
                      onChange={this.handleHalfYearlyChange}
                      style={{ cursor: 'pointer' }}
                    />
                    <span className="custom-switch-indicator"></span>
                    <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                      {halfyearlyStatus.isMandatory ? "Enable" : "Disable"}
                    </span>
                  </label>
                </div>
              </div>

              <div className="col-md-3 col-sm-3">
                <label className="form-label">
                  {languageData && languageData.Status ? languageData.Yearly : "Yearly"}
                </label>
                <div className="form-group" style={{ marginBottom: "10px" }}>
                  <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
                    <input
                      type="checkbox"
                      checked={yearlyStatus.isMandatory}
                      name="custom-switch-checkbox"
                      className="custom-switch-input"
                      onChange={this.handleYearlyChange}
                      style={{ cursor: 'pointer' }}
                    />
                    <span className="custom-switch-indicator"></span>
                    <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                      {yearlyStatus.isMandatory ? "Enable" : "Disable"}
                    </span>
                  </label>
                </div>
              </div>

              <div className="col-md-3 col-sm-3">
                <label className="form-label">
                  {languageData && languageData.Status ? languageData.Adhoc : "Adhoc"}
                </label>
                <div className="form-group" style={{ marginBottom: "10px" }}>
                  <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
                    <input
                      type="checkbox"
                      checked={AdhocStatus.isMandatory}
                      name="custom-switch-checkbox"
                      className="custom-switch-input"
                      onChange={this.handleAdhocChange}
                      style={{ cursor: 'pointer' }}
                    />
                    <span className="custom-switch-indicator"></span>
                    <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                      {AdhocStatus.isMandatory ? "Enable" : "Disable"}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </div>
  </div>
</div>

<div className="col-md-6 col-sm-6">
            <label className="form-label">
              {languageData && languageData.Status ? languageData.Active : "Active"}
            </label>
            <div className="form-group" style={{ marginBottom: "10px" }}>
              <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
                <input
                  type="checkbox"
                  checked={activeStatus.isMandatory}
                  name="custom-switch-checkbox"
                  className="custom-switch-input"
                  onChange={this.handleActiveStatus}
                  style={{ cursor: 'pointer' }}
                />
                <span className="custom-switch-indicator"></span>
                <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                  {activeStatus.isMandatory ? "Mandatory" : "Non Mandatory"}
                </span>
              </label>
            </div>
          </div>

                      

                        <div>
                        
                        </div>

                      </div>
                    </div>
                    <div className="card-footer text-right mandatory">
                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                        <div className="text-right">
                         
                              <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                            
                              <button type="submit" className="btn commor_save" onClick={this.SubmitForm}>
                                <i className="fe fe-save mr-2"></i>Update
                              </button>
                         
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div
                id="overlay"
                style={{
                  display:
                    this.state.spinner
                      ? "block"
                      : "none",
                }}
              ></div>
              {(this.state.spinner && this.state.isChartView) && (
                <div className="common_loader">
                  <img
                    className="loader_img_style_common"
                    src="../../assets/images/load.png"
                  />
                  <Spinner
                    className="spinner_load_common"
                    animation="border"
                    variant="info"
                  ></Spinner>
                </div>
              )}
            </div>
          </div>
        
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  AccountState: state.account,
  LandingState: state.landing,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ListGroup: AC_LIST_GROUP, ListHotel: AC_LIST_HOTEL },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportCreateAndClosedTicket);
