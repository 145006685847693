import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const InvalidAccessPage = () => {
    
    return (
        <div className="auth">
            <div className="auth_left">
                <div className="card">
                    <div className="card-body">
                        <div className="display-3 text-muted mb-5">
                            <i className="si si-exclamation" /> 410
                        </div>
                        <h1 className="h3 mb-3">Invalid Access</h1>
                        <p className="h6 text-muted font-weight-normal mb-3">
                        The resource you are trying to access is no longer available.
                        </p>
                        <Link className="btn btn-primary" to="/tickets">
                            <i className="fe fe-arrow-left mr-2" />
                            Back
                        </Link>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default InvalidAccessPage;

