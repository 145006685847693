import axios from 'axios';
import moment from 'moment-timezone';
import React, { Component } from 'react'
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ImportedURL from '../../../common/api';
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import { FileAndImagevalidation, onErrorImage } from '../../../common/validate';
import { AC_LIST_USER } from '../../../actions/userAction';
import { AC_ADD_TODO_APIS, AC_EMPTY_TODO_LISTITEMS, AC_EMPTY_TO_DO_LIST, AC_HANDLE_TO_DO_LIST_CHANGE, AC_LIST_TODO_MY_LIST, AC_LIST_TODO_TAGS, AC_LIST_TO_DO_LIST_ITEMS, AC_PASS_VIEW_TO_DO_LIST, AC_SPINNER_TO_DO_LIST, AC_VIEW_TO_DO_LIST } from '../../../actions/todolistAction';
import { GrAttachment } from "react-icons/gr";
import HotelSelectComponent from '../../../utilities/SelectOption/hotelselect';
import MultiSelectComponent from '../../../utilities/SelectOption/multiselect';
import DateComponent from '../../../utilities/Date/date';
import SelectComponent from '../../../utilities/SelectOption/select';
import { Error, Success } from '../../../common/swal';
import DateTimeComponent from '../../../utilities/Date/dateandtime';
import AddTagandMylistModal from './addtagandmylistmodal';
import SpinnerComponent from '../../../utilities/Spinner/spinner';

const initialState = {
    modalType: "Add",
    id: "",
    initial: true,
    categorySelectAll: true,
    usersSelectAll: true,
    daysArray: [
        { date: "", day: "Sun", id: "" },
        { date: "", day: "Mon", id: "" },
        { date: "", day: "Tue", id: "" },
        { date: "", day: "Wed", id: "" },
        { date: "", day: "Thu", id: "" },
        { date: "", day: "Fri", id: "" },
        { date: "", day: "Sat", id: "" }
    ]
}

class EditTaskReminder extends Component {
    constructor(props) {
        super(props);
        this.state = (initialState)
        this.hotelInputRef = React.createRef();
        this.titleInputRef = React.createRef();
        this.resetRef = React.createRef()
        this.resetRefTag = React.createRef()
    }

    componentWillUpdate(nextProps) {
        const newaccount = nextProps.AccountState.account;
        if (this.state.initial) {
            if (!ImportedURL.SUPERADMIN.includes(nextProps.AccountState.role)) {
                this.props.HandleInputChange('hotel', newaccount.hotel)
            }
            this.setState({ initial: false })
        }
    }
    componentDidMount() {
        const { tzone } = this.props.AccountState.account;
        this.props.EmptyTodoList();
        this.props.EmptyTodoListItems();
        this.apiCall();
        const { params, path } = this.props.match;
        this.props.ListUser({ status: true });
        if (params.id) {
            this.props.SpinnerTodoList();
            this.setState({ modalType: path === "/add-todo-list" ? "Add" : "Edit", id: params.id })
            axios.get(ImportedURL.API.viewTodo + "/" + params.id)
                .then((res) => {
                    const data = res.data.data ? res.data.data : {}
                    this.arrageDays(data.startdate);
                    this.props.ListTodoListItems({ hotel: data.hotel, category: data.category });
                    this.props.ViewTodoList(data);
                }).catch((err) => {
                    this.props.ViewTodoList({});
                });
        } else {
            const currentDate = moment(new Date()).tz(tzone);
            const startdate = currentDate.format("MM-DD-YYYY hh:mm A");
            this.props.HandleInputChange('startdate', startdate);
            this.props.HandleInputChange('enddate', startdate);
            const newDate = currentDate.clone().add(1, 'hour');
            const endate = newDate.format("MM-DD-YYYY hh:mm A");
            this.props.HandleInputChange('duedate', endate);
            this.arrageDays(startdate);
        }
    }
    apiCall = (query = {}) => {
        let formData = { parentslug: ['todo-priority', 'todo-duration', 'todo-number-of-interval', 'todo-occurrence', 'todo-reminder'], ...query }
        this.props.AddTodoApis(formData);
        this.props.HandleInputChange('endtype', 'enddate');
        this.props.HandleInputChange('numberofoccurrence', 1);
        this.props.HandleInputChange('numberofinterval', 1);
        this.props.HandleInputChange('neverends', false);
    }
    arrageDays = (date) => {
        var daysArray = this.state.daysArray ? [...this.state.daysArray] : []
        for (let i = 0; i < 7; i++) {
            let startDate = new Date(date);
            startDate.setDate(startDate.getDate() + i);
            let index = daysArray.findIndex((e) => e.day == moment(startDate).format("ddd"))
            if (index !== -1) {
                daysArray = [
                    ...daysArray.slice(0, index),
                    { ...daysArray[index], date: moment(startDate).format("MM-DD-YYYY hh:mm A"), id: moment(startDate).format("dddd") },
                    ...daysArray.slice(index + 1)
                ]
            }
        }
        this.setState({ daysArray: daysArray });
    }
    handleChangeSelect = (e) => {
        const { TodoListState, UserState, ConfigurationState, AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const { tzone } = AccountState.account;
        const currentDateTime = moment().tz(tzone)
        const listDurationConfigOptions = ConfigurationState.listDurationConfigOptions;
        const listUser = UserState.listusers;
        const data = TodoListState.todo;
        console.log(data);
        const { name, value, selectAll } = e;
        const Error1 = name + "Error";
        const SelectAll = name + "SelectAll";
        this.setState({ [Error1]: false, [SelectAll]: selectAll });
        if (name == 'tags' || name == 'mylist') {
            if (name == 'tags' && value.includes('Add')) {
                this.setState({ showcanvasTag: true });
                this.resetRefTag.current.resetRefTagFunction();
            }
            if (name == 'mylist' && value == 'Add') {
                this.setState({ showcanvasMylist: true });
                this.resetRef.current.resetRefFunction();
            }
            if (value != 'Add') this.props.HandleInputChange(name, value);

        } else if (name == 'reminderselect') {
            const startDate = moment(data.startdate);
            const reminderDate = startDate.subtract({ minutes: value });
            // this.props.HandleInputChange('reminderdate', moment(reminderDate).format('MM-DD-YYYY hh:mm A'));
            let calcuateMoment = moment(reminderDate)
            const calcuateMomentformatted = moment(calcuateMoment, "MM-DD-YYYY hh:mm A").format("MM-DD-YYYY hh:mm A");
            const currentDateformatted = moment(currentDateTime, "MM-DD-YYYY hh:mm A").format("MM-DD-YYYY hh:mm A");
            if (value) {
                if (value && value != "custom") {
                    if (calcuateMomentformatted > currentDateformatted) {
                        this.props.HandleInputChange(name, value);
                        this.props.HandleInputChange('reminderdate', moment(reminderDate).format('MM-DD-YYYY hh:mm A'));
                    } else {
                        Error(languageData && languageData.TODO_REMAINDER_ERROR_ALERT_SELECT ? languageData.TODO_REMAINDER_ERROR_ALERT_SELECT : "To set reminder select the start time few minutes ahead of current time")
                    }
                } else {
                    this.props.HandleInputChange(name, value);
                    this.props.HandleInputChange('reminderdate', moment(data.startdate));
                }
            } else {
                this.props.HandleInputChange('reminderdate', moment(data.startdate));
                this.props.HandleInputChange(name, "");
            }
            this.props.HandleInputChange('reminderminute', 0);
            this.props.HandleInputChange('reminderhour', 0);
            this.props.HandleInputChange('reminderday', 0);
        } else {
            this.props.HandleInputChange(name, value);
        }

        if (name == 'category') {
            let userId = []
            let userData = listUser.filter((e) => (data.users && data.users.includes(e._id)))
            if (userData.length > 0) {
                userData.map((item) => {
                    if (value.includes(item.categoryid)) userId.push(item._id)
                })
            }
            this.props.HandleInputChange('users', userId);
            if (value && value.length > 0) {
                this.props.ListTodoListItems({ hotel: data.hotel, category: value });
            } else {
                this.props.EmptyTodoListItems()
            }
        }
        if (name == 'hotel') {
            this.props.HandleInputChange('category', []);
            this.props.HandleInputChange('users', []);
            this.apiCall({ hotel: value });
        }
        if (name == 'numberofinterval') {
            if (value && Number(value) > 1) {
                const listDurationConfigOptions = ConfigurationState.listDurationConfigOptions;
                let duration = listDurationConfigOptions.find((e) => e._id === data.duration);
                let added = Number(value) * (duration ? duration.suboption : 0);
                const currentDate = moment(data.startdate);
                const newDate = currentDate.clone().add(added, 'day');
                const endate = newDate.format("MM-DD-YYYY hh:mm A");
                this.props.HandleInputChange('enddate', endate);
            } else {
                this.props.HandleInputChange('enddate', data.startdate);
            }
        }
    }
    calcuateReminder = (name, number) => {
        const data = this.props.TodoListState.todo;
        const reminderDays = name == 'reminderday' ? Number(number) : (data.reminderday ? Number(data.reminderday) : 0);
        const reminderHours = name == 'reminderhour' ? Number(number) : (data.reminderhour ? Number(data.reminderhour) : 0);
        const reminderMinutes = name == 'reminderminute' ? Number(number) : (data.reminderminute ? Number(data.reminderminute) : 0);
        const startDate = moment(data.startdate);
        const reminderDate = startDate.subtract({
            days: reminderDays,
            hours: reminderHours,
            minutes: reminderMinutes
        });
        return moment(reminderDate).format()
    }
    handleChange = e => {
        const data = this.props.TodoListState.todo;
        const { tzone } = this.props.AccountState.account;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const currentDateTime = moment().tz(tzone)
        const { name, value, checked } = e.target;
        const Error1 = name + "Error";
        this.setState({ [Error1]: false });
        if (name == 'neverends') {
            this.props.HandleInputChange(name, checked);
        } else if (name == 'endtype') {
            if (value == 'enddate') {
                this.props.HandleInputChange('enddate', data.startdate);
                this.props.HandleInputChange('numberofinterval', 1);
            }
            if (value == 'occurrence') {
                this.props.HandleInputChange('numberofoccurrence', 1);
            }
            this.props.HandleInputChange(name, value);
        } else if (name == 'reminderminute' || name == 'reminderhour' || name == 'reminderday') {
            var number = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            let calcuate = this.calcuateReminder(name, number);
            let calcuateMoment = moment(calcuate)
            const calcuateMomentformatted = moment(calcuateMoment, "MM-DD-YYYY hh:mm A").format("MM-DD-YYYY hh:mm A");
            const currentDateformatted = moment(currentDateTime, "MM-DD-YYYY hh:mm A").format("MM-DD-YYYY hh:mm A");
            if (number) {
                if (calcuateMomentformatted > currentDateformatted) {
                    this.props.HandleInputChange(name, number);
                    this.props.HandleInputChange('reminderdate', moment(calcuate).format("MM-DD-YYYY hh:mm A"));
                } else {
                    Error(languageData && languageData.TODO_REMAINDER_ERROR_ALERT_SELECT ? languageData.TODO_REMAINDER_ERROR_ALERT_SELECT : "To set reminder select the start time few minutes ahead of current time")
                }
            } else {
                this.props.HandleInputChange('reminderdate', moment(data.startdate));
                this.props.HandleInputChange(name, number);
            }
        } else if (name == "title") {
            if (value.length <= 50) {
                this.props.HandleInputChange(name, value);
            } else {
                Error(languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED ? (languageData.MAXIMUM_CHAR_LIMIT_REACHED) : "Maximum character limit reached")
            }
        } else if (name == "description") {
            if (value.length <= 250) {
                this.props.HandleInputChange(name, value);
            } else {
                Error(languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED ? (languageData.MAXIMUM_CHAR_LIMIT_REACHED) : "Maximum character limit reached")
            }
        } else {
            this.props.HandleInputChange(name, value);
        }
    }
    handleChangeDate = (e, name) => {
        const data = this.props.TodoListState.todo;
        console.log(data);
        const value = moment(e?.toDate?.().toString()).format("MM-DD-YYYY hh:mm A")
        this.props.HandleInputChange(name, value);
        const { tzone } = this.props.AccountState.account;
        const currentDateTime = moment().tz(tzone)
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (name == 'startdate') {
            if (data.numberofinterval && (Number(data.numberofinterval) > 1)) {
                const listDurationConfigOptions = this.props.ConfigurationState.listDurationConfigOptions;
                let duration = listDurationConfigOptions.find((e) => e._id === data.duration);
                let added = Number(data.numberofinterval) * (duration ? duration.suboption : 0);
                const currentDate = moment(value);
                const newDate = currentDate.clone().add(added, 'day');
                const endate = newDate.format("MM-DD-YYYY hh:mm A");
                this.props.HandleInputChange('enddate', endate);
            } else {
                this.props.HandleInputChange('enddate', value);
            }
            const currentDate = moment(value);
            const newDate = currentDate.clone().add(1, 'hour');
            const endate = newDate.format("MM-DD-YYYY hh:mm A");
            this.props.HandleInputChange('duedate', endate);
            this.arrageDays(value);
            if (data.reminderselect !== undefined) {
                const currentDate = moment(value);
                let reminder = (data.reminderselect && data.reminderselect != "custom") ? Number(data.reminderselect) : this.convertToMinutes(data.reminderday, data.reminderhour, data.reminderminute);
                const reminderDate = currentDate.subtract({ minutes: reminder });
                let calcuateMoment = moment(reminderDate)
                const calcuateMomentformatted = moment(calcuateMoment, "MM-DD-YYYY hh:mm A").format("MM-DD-YYYY hh:mm A");
                const currentDateformatted = moment(currentDateTime, "MM-DD-YYYY hh:mm A").format("MM-DD-YYYY hh:mm A");
                if (calcuateMomentformatted > currentDateformatted) {
                    this.props.HandleInputChange('reminderdate', moment(reminderDate).format('MM-DD-YYYY hh:mm A'));
                } else {
                    this.props.HandleInputChange('reminderselect', "")
                    this.props.HandleInputChange('reminderdate', "")
                    Error(languageData && languageData.TODO_REMAINDER_ERROR_ALERT_SELECT ? languageData.TODO_REMAINDER_ERROR_ALERT_SELECT : "To set reminder select the start time few minutes ahead of current time")
                }
            }
        }
    }
    convertToMinutes = (reminderday, reminderhour, reminderminute) => {
        let days = reminderday ? Number(reminderday) : 0
        let hours = reminderhour ? Number(reminderhour) : 0
        let minutes = reminderminute ? Number(reminderminute) : 0
        const totalMinutes = (days * 24 * 60) + (hours * 60) + minutes;
        return totalMinutes;
    }
    handleChangeTime = (e, name) => {
        this.props.HandleInputChange(name, e);
    }
    handleChangeCustomizeSelect = (name, value) => {
        const emptyList = name + "List";
        this.setState({ [emptyList]: [] });
        this.props.HandleInputChange(name, value);
    }
    handleChangeFile = (e) => {
        const { name, value, files } = e.target;
        const data = this.props.TodoListState.todo;
        const oldFiles = data[name] ? [...data[name]] : [];
        const filesList = Array.from(files);
        const newFiles = [];
        for (var i = 0; i < filesList.length; i++) {
            const imgvalidate = FileAndImagevalidation(filesList[i]);
            if (imgvalidate) {
                newFiles.push(filesList[i])
            } else {
                Error('Invalid file is discarded');
            }
        }
        let concat = oldFiles.concat(newFiles)
        this.fileInputLengthChange(concat, name);
        this.props.HandleInputChange(name, concat);
    }
    fileInputLengthChange = (Array, name) => {
        if (Array.length > 0) {
            let content = Array.length + (Array.length > 1 ? " files" : " file")
            const tempFiles = new File(['Dummy File Content'], content, { type: 'text/plain' });
            const fileList = new DataTransfer();
            fileList.items.add(tempFiles);
            const fileInput = document.getElementById(name + 'Id');
            fileInput.files = fileList.files;
        };
    }
    findImageSrc = (image) => {
        let src = ''
        if (typeof image == 'string') {
            const fileType = image.toLowerCase();
            const isPDF = fileType.endsWith('.pdf');
            if (isPDF) {
                src = '../../assets/images/pdf.png';
            } else {
                src = ImportedURL.FILEURL + image
            }
        } else {
            const fileType = image.type.toLowerCase();
            const isPDF = fileType.startsWith('application/');
            if (isPDF) {
                src = '../../assets/images/pdf.png';
            } else {
                src = window.URL.createObjectURL(image);
            }
        }
        return src
    }
    handleChangeRemoveImg = (index, name) => {
        const data = this.props.TodoListState.todo;
        const fileList = data[name] ? [...data[name]] : [];
        const file = fileList.filter((item, i) => index !== i);
        this.fileInputLengthChange(file, name);
        this.props.HandleInputChange(name, file);
    }
    handleChangeDuration = (name, value) => {
        const data = this.props.TodoListState.todo;
        this.props.HandleInputChange(name, value);
        this.props.HandleInputChange('numberofinterval', 1);
        this.props.HandleInputChange('enddate', data.startdate);
    }
    findDurationName = (id) => {
        const { ConfigurationState } = this.props;
        const listDurationConfigOptions = ConfigurationState.listDurationConfigOptions;
        let data = listDurationConfigOptions.find((e) => e._id == id)
        return data ? data.name : ''
    }
    handleChangeDays = (e) => {
        const { date } = e;
        this.props.HandleInputChange('startdate', date);
        this.props.HandleInputChange('enddate', date);
        this.props.HandleInputChange('duedate', date);
    }
    submit = () => {
        const { AccountState, TodoListState, ConfigurationState } = this.props;
        const listDurationConfigOptions = ConfigurationState.listDurationConfigOptions;

        const account = AccountState.account;
        const newData = TodoListState.todo;
        const languageData = AccountState.account.SelectedlanguageData
        var data = { ...newData }
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }
        let isValid = true;
        if (!data.title) {
            this.setState({ titleError: true })
            isValid = false;
            this.titleInputRef.current.focus();
        }
        if (!data.hotel) {
            this.setState({ hotelError: true })
            isValid = false;
            this.hotelInputRef.current.focus();
        }
        var editedby = data.editedby ? data.editedby : []
        if (account.userId && this.state.modalType == 'Edit') {
            if (!editedby.includes(account.userId)) editedby.push(account.userId)
        }
        data['editedby'] = editedby
        if (isValid) {
            if (data.reminderselect != undefined) {
                if (!['custom', 'Custom'].includes(data.reminderselect)) {
                    data['reminder'] = Number(data.reminderselect);
                } else {
                    data['reminder'] = this.convertToMinutes(data.reminderday, data.reminderhour, data.reminderminute);
                }
            } else {
                data['reminder'] = Number(0);
            }
            if (data.endtype && data.endtype == "occurrence") {
                const currentDate = moment(data.startdate);
                let duration = listDurationConfigOptions.find((e) => e._id === data.duration);
                if ((data.numberofinterval && Number(data.numberofinterval) > 1) && (data.numberofoccurrence && Number(data.numberofoccurrence) > 1)) {
                    let added = ((data.numberofinterval ? Number(data.numberofinterval) : 0) * (data.numberofoccurrence ? Number(data.numberofoccurrence - 1) : 0)) * (duration ? Number(duration.suboption) : 0)
                    const newDate = currentDate.clone().add(added, 'day');
                    const endate = newDate.format("MM-DD-YYYY hh:mm A");
                    data['enddate'] = endate;
                } else if (data.numberofoccurrence && Number(data.numberofoccurrence) > 1) {
                    let added = ((data.numberofoccurrence ? Number(data.numberofoccurrence - 1) : 0)) * (duration ? Number(duration.suboption) : 0)
                    const newDate = currentDate.clone().add(added, 'day');
                    const endate = newDate.format("MM-DD-YYYY hh:mm A");
                    data['enddate'] = endate;
                } else {
                    data['enddate'] = currentDate.format("MM-DD-YYYY hh:mm A");
                }
            }
            const formData = new FormData();
            for (let key in data) {
                if (key === 'attachments') continue;
                else if (key === 'category') formData.append('category', JSON.stringify(data.category));
                else if (key === 'users') formData.append('users', JSON.stringify(data.users));
                else if (key === 'tags') formData.append('tags', JSON.stringify(data.tags));
                else if (key === 'tasklist') formData.append('tasklist', JSON.stringify(data.tasklist));
                else if (key === 'neverends') formData.append('neverends', JSON.stringify(data.neverends));
                else if (key === 'reminder') formData.append('reminder', data.reminder ? JSON.stringify(data.reminder) : '');
                else if (key === 'numberofinterval') formData.append('numberofinterval', JSON.stringify(data.numberofinterval));
                else if (key === 'numberofoccurrence') formData.append('numberofoccurrence', JSON.stringify(data.numberofoccurrence));
                else formData.append(key, data[key])
            }
            let existingAttachments = [];
            let currentAttachments = [];
            if (data.attachments && data.attachments.length > 0) {
                data.attachments.map((item) => {
                    if (typeof item == 'string') {
                        existingAttachments.push(item);
                    } else {
                        currentAttachments.push(item);
                    }
                });
            }
            for (const file of currentAttachments) {
                formData.append('attachments', file);
            };
            formData.append('existingAttachments', JSON.stringify(existingAttachments));
            this.setState({ saving: true })
            if (this.state.modalType == "Add") {
                axios.post(ImportedURL.API.addTodolist, formData)
                    .then((res) => {
                        this.setState({ saving: false, listRedirect: true })
                        Success((languageData && languageData.TODO_MY_TASK ? (languageData.TODO_MY_TASK) : "My Task") + (languageData && languageData.CREATED_SUCCESSFULLY ? (languageData.CREATED_SUCCESSFULLY) : " created successfully"));
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        this.ErrorResponse(response)
                    });
            } else {
                axios.post(ImportedURL.API.editTodolist + '/' + this.state.id, formData)
                    .then((res) => {
                        this.setState({ saving: false, listRedirect: true })
                        Success((languageData && languageData.TODO_MY_TASK ? (languageData.TODO_MY_TASK) : "My Task") + (languageData && languageData.UPDATED_SUCCESSFULLY ? (languageData.UPDATED_SUCCESSFULLY) : " Updated Successfully"))
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        this.ErrorResponse(response)
                    });
            }
        }
    }
    ErrorResponse = (response) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData;
        if (response) {
            if (response.status == 500) {
                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
            } else if (response.status == 502) {
                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
            } else if (response.status == 400) {
                Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
            } else if (response.status == 406) {
                Error(languageData && languageData.TODO_LIST_CANNOT_EDIT ? languageData.TODO_LIST_CANNOT_EDIT : "You can't edit todos created by others")
            } else {
                Error(response.statusMessage)
            }
        } else {
            Error("Bad request")
        }
    }
    tagSubmit = () => {
        const data = this.props.TodoListState.todo;
        this.setState({ showcanvasTag: false });
        this.props.ListTodoTag({ hotel: data.hotel });
    }
    mylistSubmit = () => {
        const data = this.props.TodoListState.todo;
        this.setState({ showcanvasMylist: false });
        this.props.ListTodoMyList({ hotel: data.hotel });
    }
    onChangeCheckList = (id) => {
        const data = this.props.TodoListState.todo;
        var tasklist = data.tasklist ? [...data.tasklist] : []
        let status = tasklist.some((e) => e == id)
        if (status) {
            let arr = tasklist.filter((e) => e != id)
            this.props.HandleInputChange('tasklist', arr);
        } else {
            tasklist.push(id)
            this.props.HandleInputChange('tasklist', tasklist);
        }
    }
    render() {
        const { AccountState, LandingState, UserState, TodoListState, ConfigurationState } = this.props;
        const path = TodoListState.changepath;
        if (this.state.listRedirect) {
            return <Redirect to={path} />
        }
        const languageData = AccountState.account.SelectedlanguageData
        const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 0 ? LandingState.landingdata.data[0] : [];
        const listCategory = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 0 ? LandingState.landingdata.data[7] : [];
        const listUser = UserState.listusers;
        const listMyList = TodoListState.listtodomylist;
        const data = TodoListState.todo;
        const listPriorityConfigOptions = ConfigurationState.listPriorityConfigOptions;
        const listDurationConfigOptions = ConfigurationState.listDurationConfigOptions;
        const listNumberOfIntervalConfigOptions = ConfigurationState.listNumberOfIntervalConfigOptions;
        const listtodooccurrenceConfigOptions = ConfigurationState.listtodooccurrenceConfigOptions;
        const listTodoReminderConfigOptions = ConfigurationState.listTodoReminderConfigOptions;
        const todolistitems = TodoListState.todolistitems;
        const account = AccountState.account;
        const listTag = TodoListState.listtodotags;
        const spinner = TodoListState.spinner;

        var selectedHotel = '';
        const hotelOptions = [];
        if (listHotels && listHotels.length > 0) {
            listHotels.map((item) => {
                if (data.hotel == item._id) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
            })
        }

        const categoryOption = [];
        const selectedCategory = [];
        listCategory.map(item => {
            if (data.category && data.category.length > 0 && data.category.includes(item._id)) {
                selectedCategory.push({ label: item.name, value: item._id, name: 'category' });
            }
            let userIncludes = listUser.some((e) => (e.categoryid == item._id && data.hotel == e.hotelid))
            if (data.hotel && userIncludes) {
                categoryOption.push({ label: item.name, value: item._id, name: 'category' });
            }
        })
        let showCategoryOption = categoryOption.filter((e) => !(data.category && data.category.includes(e.value)));
        if (showCategoryOption.length > 1 && categoryOption.length != selectedCategory.length && this.state.categorySelectAll) {
            categoryOption.unshift({ label: "All", value: '', name: 'category' })
        }

        const userOption = [];
        const selectedUser = [];
        listUser.map(item => {
            if (data.users && data.users.length > 0 && data.users.includes(item._id)) {
                selectedUser.push({ label: item.name, value: item._id, name: 'users' });
            }
            if (data.category && data.category.length > 0 && data.category.includes(item.categoryid) && data.hotel == item.hotelid) {
                userOption.push({ label: item.name, value: item._id, name: 'users' });
            }
        })
        let showUserOption = userOption.filter((e) => !(data.users && data.users.includes(e.value)));
        if (showUserOption.length > 1 && userOption.length != selectedUser.length && this.state.usersSelectAll) {
            userOption.unshift({ label: "All", value: '', name: 'users' })
        }

        let priorityOption = []
        let prioritySelect = ''
        if (listPriorityConfigOptions && listPriorityConfigOptions.length > 0) {
            listPriorityConfigOptions.map((item) => {
                if (data.priority == item._id) {
                    prioritySelect = { label: `${item.name}`, value: item._id, name: 'priority' }
                }
                priorityOption.push({ label: `${item.name}`, value: item._id, name: 'priority' });
            })
        }

        let mylistOption = []
        let mylistSelect = ''
        if (listMyList && listMyList.length > 0) {
            listMyList.map((item) => {
                if (data.mylist == item._id) {
                    mylistSelect = { label: `${item.filename}`, value: item._id, name: 'mylist' }
                }
                mylistOption.push({ label: `${item.filename}`, value: item._id, name: 'mylist' });
            })
        }
        mylistOption.push({ label: `+ Add`, value: 'Add', name: 'mylist' });

        let intervalOption = []
        let intervalSelect = ''
        if (listNumberOfIntervalConfigOptions && listNumberOfIntervalConfigOptions.length > 0) {
            listNumberOfIntervalConfigOptions.map((item) => {
                if (data.numberofinterval == item.name) {
                    intervalSelect = { label: `${item.name}`, value: Number(item.name), name: 'numberofinterval' }
                }
                intervalOption.push({ label: `${item.name}`, value: Number(item.name), name: 'numberofinterval' });
            })
        }

        let occurrenceOption = []
        let occurrenceSelect = ''
        if (listtodooccurrenceConfigOptions && listtodooccurrenceConfigOptions.length > 0) {
            listtodooccurrenceConfigOptions.map((item) => {
                if (data.numberofoccurrence == item.name) {
                    occurrenceSelect = { label: `${item.name}`, value: Number(item.name), name: 'numberofoccurrence' }
                }
                occurrenceOption.push({ label: `${item.name}`, value: Number(item.name), name: 'numberofoccurrence' });
            })
        }

        let reminderOption = []
        let reminderSelect = ''
        if (listTodoReminderConfigOptions && listTodoReminderConfigOptions.length > 0) {
            listTodoReminderConfigOptions.map((item) => {
                if (data.reminderselect == item.suboption) {
                    reminderSelect = { label: `${item.name}`, value: item.suboption, name: 'reminderselect' }
                } else if (data.reminderselect == 'custom') {
                    reminderSelect = { label: `Custom`, value: 'custom', name: 'reminderselect' }
                }
                reminderOption.push({ label: `${item.name}`, value: item.suboption, name: 'reminderselect' });
            })
            reminderOption.push({ label: `Custom`, value: 'custom', name: 'reminderselect' });
        }

        const tagOption = [];
        const tagSelect = [];
        if (listTag && listTag.length > 0) {
            listTag.map(item => {
                if (data.tags && data.tags.includes(item._id)) {
                    tagSelect.push({ label: item.tag, value: item._id, name: 'tags' });
                }
                tagOption.push({ label: item.tag, value: item._id, name: 'tags' });
            })
        }
        tagOption.push({ label: `+ Add`, value: 'Add', name: 'tags' });
        let showTagOption = tagOption.filter((e) => !(data.tags && data.tags.includes(e.value)));
        if (showTagOption.length > 1 && tagOption.length != tagSelect.length && this.state.usersSelectAll) {
            tagOption.unshift({ label: "All", value: '', name: 'tags' })
        }

        const days = data.startdate ? moment(data.startdate).format("dddd") : '';
        let dueDate = ''
        if (data.startdate) {
            const originalDateTime = moment(data.startdate, "MM-DD-YYYY hh:mm A");
            const dateTimePlusOneMinute = originalDateTime.clone().add(1, 'minute');
            dueDate = dateTimePlusOneMinute.format("MM-DD-YYYY hh:mm A");
        }

        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i class="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to={path}><h6>{path == '/todo-my-task' ? (languageData && languageData.TODO_MY_TASK ? languageData.TODO_MY_TASK : "My Task") : (languageData && languageData.ALL_MY_TASK ? languageData.ALL_MY_TASK : "All My Task")}{languageData && languageData.LIST ? languageData.LIST : " List"}</h6></Link> <span><i class="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>{this.state.modalType} {languageData && languageData.TO_DO ? languageData.TO_DO : "To-Do"}</h6></p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid add_to_do_list">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel" >
                                    <div className="card">
                                        <div className="card-body border-0">
                                            <div className='title_mt'>
                                                <div className='d-flex'>
                                                    <div className='rounded_icon'><i class="fa fa-calendar mr-2"></i></div>
                                                    <div>
                                                        <h3 className="card-title" style={{ marginTop: '10px' }}>{this.state.modalType} {languageData && languageData.TO_DO ? languageData.TO_DO : "To-Do"}</h3>
                                                    </div>
                                                </div>
                                                <div className="header-action" style={{ marginTop: '5px' }}>
                                                    <Link to={path}><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body  border-0">
                                            <form id='form_reset'>
                                                <Row>
                                                    {
                                                        ImportedURL.SUPERADMIN.includes(account.role) ?
                                                            <div className="col-sm-12 col-md-12">
                                                                <div className="form-group">
                                                                    <label className="form-label">Hotel<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                    <HotelSelectComponent {...this} value={selectedHotel} onChange={this.handleChangeSelect} options={hotelOptions} selectRef={this.hotelInputRef} />
                                                                    <div className="invalid-feedback" style={{ display: this.state.hotelError ? "block" : 'none' }}>Hotel is required</div>
                                                                </div>
                                                            </div>
                                                            : ''
                                                    }
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Assign Users Category</label>
                                                            <MultiSelectComponent value={selectedCategory} onChange={this.handleChangeSelect} options={categoryOption} name='category' />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Assign User Name</label>
                                                            <MultiSelectComponent value={selectedUser} onChange={this.handleChangeSelect} options={userOption} name='users' />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label" >Title<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" ref={this.titleInputRef} className="form-control" placeholder="Title" value={data.title ? data.title : ''} name="title" onChange={this.handleChange} />
                                                            <div className="invalid-feedback" style={{ display: this.state.titleError ? "block" : 'none' }}>Title is required</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6 col-md-6'>
                                                        <div className="form-group" style={{ position: "relative" }}>
                                                            <label className="form-label">Description</label>
                                                            <textarea className='form-control w-100' name="description" rows="3" cols="50" onChange={this.handleChange} value={data.description ? data.description : ''} placeholder={"Description"} />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6 col-md-6'>
                                                        <div className='form-group'>
                                                            <label className="form-label">Start Date & Time</label>
                                                            <DateTimeComponent onChange={(e) => this.handleChangeDate(e, 'startdate')} value={data.startdate} minDate={moment(new Date()).tz(account.tzone).format("MM-DD-YYYY hh:mm A")} editable={false} />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6 col-md-6'>
                                                        <div className='form-group'>
                                                            <label className="form-label">Due Date & Time</label>
                                                            <DateTimeComponent onChange={(e) => this.handleChangeDate(e, 'duedate')} value={data.duedate} minDate={data.startdate ? dueDate : moment(new Date()).tz(account.tzone).format("MM-DD-YYYY hh:mm A")} editable={false} minTime={data.startdate ? moment(data.startdate).add(1, 'minute') : moment(new Date()).tz(account.tzone).format("MM-DD-YYYY hh:mm A")} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label" >My List</label>
                                                            <SelectComponent value={mylistSelect} options={mylistOption} onChange={this.handleChangeSelect} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Priority</label>
                                                            <SelectComponent value={prioritySelect} options={priorityOption} onChange={this.handleChangeSelect} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label" >Tag</label>
                                                            <MultiSelectComponent value={tagSelect} onChange={this.handleChangeSelect} options={tagOption} name='tags' />
                                                        </div>
                                                    </div>
                                                    <div className='add_todo_list_tabs'>
                                                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                            <li class="nav-item" role="presentation">
                                                                <button class="nav-link active" id="attachment-tab" data-bs-toggle="pill" data-bs-target="#attachments" type="button" role="tab" aria-controls="attachments" aria-selected="false"><GrAttachment style={{ marginRight: '5px' }} />Attachment</button>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <button class="nav-link" id="recurring-tab" data-bs-toggle="pill" data-bs-target="#recurring" type="button" role="tab" aria-controls="recurring" aria-selected="true"><i class="fa fa-refresh mr-2" aria-hidden="true"></i>Recurring</button>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <button class="nav-link" id="reminder-tab" data-bs-toggle="pill" data-bs-target="#reminder" type="button" role="tab" aria-controls="reminder" aria-selected="false"><i class="fa fa-calendar mr-2" aria-hidden="true"></i>Reminder</button>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <button class="nav-link" id="task-list-tab" data-bs-toggle="pill" data-bs-target="#task-list" type="button" role="tab" aria-controls="task-list" aria-selected="false"><i class="fa fa-list-ul mr-2" aria-hidden="true"></i>Check List</button>
                                                            </li>
                                                        </ul>
                                                        <div class="tab-content" id="pills-tabContent">
                                                            <div class="tab-pane fade show active" id="attachments" role="tabpanel" aria-labelledby="attachment-tab">
                                                                <Row>
                                                                    <div className="col-lg-12 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Attachment</label>
                                                                            <input type="file" className="form-control" name="attachments" accept="application/pdf,image/png,image/jpg,image/jpeg" id='attachmentsId' multiple onChange={this.handleChangeFile} />
                                                                        </div>
                                                                        <div className="photos_wid">
                                                                            {
                                                                                (data.attachments && data.attachments.length > 0)
                                                                                    ?
                                                                                    <>{
                                                                                        data.attachments.map((img, i) => {
                                                                                            return (
                                                                                                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }} key={i}>
                                                                                                    <div style={{ position: 'relative' }}>
                                                                                                        <img src={this.findImageSrc(img)} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative', objectFit: 'contain' }} onError={onErrorImage} /><i className="fa fa-times img_remove" onClick={() => this.handleChangeRemoveImg(i, 'attachments')} ></i>
                                                                                                    </div>
                                                                                                </div >
                                                                                            )
                                                                                        })
                                                                                    }</> : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                            <div class="tab-pane fade" id="recurring" role="tabpanel" aria-labelledby="recurring-tab">
                                                                <Row>
                                                                    {
                                                                        listDurationConfigOptions && listDurationConfigOptions.length > 0
                                                                            ?
                                                                            <>
                                                                                <div className="col-lg-12 col-md-12">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label">Duration</label>
                                                                                        <div className='todo_duration'>
                                                                                            {
                                                                                                listDurationConfigOptions.map((item, i) => {
                                                                                                    return (
                                                                                                        <div className='todo_duration_box' key={i}>
                                                                                                            <button type='button' className={`btn btn-primery ${data.duration == item._id ? ' todo_duration_button_active' : ' todo_duration_button'}`} onClick={() => this.handleChangeDuration('duration', item._id)} ><i class="fa fa-calendar-o mr-2" aria-hidden="true"></i>{item.name}</button>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </> : ''
                                                                    }
                                                                    <div className="col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">{"Number Of Interval"}</label>
                                                                            <SelectComponent value={intervalSelect} options={intervalOption} onChange={this.handleChangeSelect} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 col-md-6">
                                                                        <div className="mb-4 todo-list-end-task-taggle">
                                                                            <label className="form-label">Task Never Ends</label>
                                                                            <label class="toggle mt-2">
                                                                                <input type="checkbox" name="neverends" checked={data.neverends} onClick={this.handleChange} />
                                                                                <span class="slider"></span>
                                                                                <span class="labels" data-on="ON" data-off="OFF"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        (!data.neverends) ?
                                                                            <>
                                                                                <div className='col-sm-6 col-md-6'>
                                                                                    <div className='form-group'>
                                                                                        <label className="form-label">Task Ends</label>
                                                                                        <input type="radio" className="radio-button" checked={data.endtype == "enddate"} id="enddateId" value="enddate" name="endtype" onChange={this.handleChange} /> <label className="radio-button-label" for="enddateId" style={{ cursor: 'pointer' }}>End Date</label>
                                                                                        <input type="radio" className="radio-button" checked={data.endtype == "occurrence"} id="occurrenceId" value="occurrence" name="endtype" onChange={this.handleChange} style={{ marginLeft: "30px" }} /> <label className="radio-button-label" for="occurrenceId" style={{ cursor: 'pointer' }}>End Occurrence</label>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    data.endtype == "enddate" ? <>
                                                                                        <div className='col-sm-6 col-md-6'>
                                                                                            <div className='form-group'>
                                                                                                <label className="form-label">End Date</label>
                                                                                                <DateComponent onChange={(e) => this.handleChangeDate(e, 'enddate')} value={data.enddate} minDate={data.startdate ? moment(data.startdate).format("MM-DD-YYYY") : moment(new Date()).tz(account.tzone).format("MM-DD-YYYY")} editable={false} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </> : ""
                                                                                }
                                                                                {
                                                                                    data.endtype == "occurrence" ? <>
                                                                                        <div className="col-lg-6 col-md-6">
                                                                                            <div className="form-group">
                                                                                                <label className="form-label">Number Of Occurrence</label>
                                                                                                <SelectComponent value={occurrenceSelect} options={occurrenceOption} onChange={this.handleChangeSelect} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </> : ""
                                                                                }
                                                                            </> : ''
                                                                    }
                                                                    {/* <>
                                                                        {
                                                                            (this.findDurationName(data.duration) == 'Weekly' && this.state.daysArray && this.state.daysArray.length > 0) ?
                                                                                <>
                                                                                    <div className="col-lg-12 col-md-12">
                                                                                        <div className="day-container my-2">
                                                                                            {
                                                                                                this.state.daysArray.map((item, i) => {
                                                                                                    return (
                                                                                                        <div className={days == item.id ? "day-circle-active" : "day-circle"} onClick={() => this.handleChangeDays(item)} key={i}>{item.day}</div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </> : ''
                                                                        }
                                                                    </> */}
                                                                </Row>
                                                            </div>
                                                            <div class="tab-pane fade" id="reminder" role="tabpanel" aria-labelledby="reminder-tab">
                                                                <Row>
                                                                    <div className="col-lg-12 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Reminder</label>
                                                                            <SelectComponent value={reminderSelect} options={reminderOption} onChange={this.handleChangeSelect} />
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        data.reminderselect == 'custom'
                                                                            ?
                                                                            <>
                                                                                <div className="col-lg-4 col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label" >Number Of Day</label>
                                                                                        <input type="text" className="form-control" placeholder="Number Of Day" value={data.reminderday ? data.reminderday : ''} name="reminderday" onChange={this.handleChange} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label" >Number Of Hour</label>
                                                                                        <input type="text" className="form-control" placeholder="Number Of Hour" value={data.reminderhour ? data.reminderhour : ''} name="reminderhour" onChange={this.handleChange} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-label" >Number Of Minute</label>
                                                                                        <input type="text" className="form-control" placeholder="Number Of Minute" value={data.reminderminute ? data.reminderminute : ''} name="reminderminute" onChange={this.handleChange} />
                                                                                    </div>
                                                                                </div>
                                                                            </> : ''
                                                                    }
                                                                    {
                                                                        data.reminderdate ?
                                                                            <div className="col-lg-12 col-md-12">
                                                                                <div className="form-group">
                                                                                    <label className="form-label" >{"Reminder at " + moment(data.reminderdate).format('ddd DD MMM, YYYY hh:mm A')}</label>
                                                                                </div>
                                                                            </div>
                                                                            : ""
                                                                    }
                                                                </Row>
                                                            </div>
                                                            <div class="tab-pane fade" id="task-list" role="tabpanel" aria-labelledby="task-list-tab">
                                                                <Row>
                                                                    <div className="col-lg-12 col-md-12">
                                                                        {
                                                                            todolistitems && todolistitems.length > 0
                                                                                ?
                                                                                <>
                                                                                    <label className="form-label">Check List</label>
                                                                                    <ul class="list-group todo_task_list_scroll">
                                                                                        {
                                                                                            todolistitems.map((item, i) => {
                                                                                                const checked = (data.tasklist && data.tasklist.length > 0) ? data.tasklist.includes(item._id) : false
                                                                                                let categoryName = item.category && item.category.name && selectedCategory && selectedCategory.length > 1 ? "( " + item.category.name + " )" : ''
                                                                                                return (
                                                                                                    <li class="list-group-item" key={i}>
                                                                                                        <div class="custom-control custom-checkbox">
                                                                                                            <input type="checkbox" class="custom-control-input" checked={checked} id={item._id} />
                                                                                                            <label class="custom-control-label" for={item._id} onClick={() => this.onChangeCheckList(item._id)}>{item.itemvalue} {categoryName}</label>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                </> :
                                                                                <div className="card-body text-center d-flex align-items-center justify-content-center pt-0">
                                                                                    <div style={{ maxWidth: "340px" }}>
                                                                                        <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid" style={{ maxWidth: "272px" }} />
                                                                                        <h5 className="mt-4">No record to display</h5>
                                                                                    </div>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </form>
                                        </div>
                                        <div className="card-footer text-right mandatory">
                                            <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                            <div className="text-right">
                                                {
                                                    this.state.saving ?
                                                        <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>{this.state.modalType == "Edit" ? "Updating" : "Saving"}</button>
                                                        :
                                                        <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                            <i className="fe fe-save mr-2"></i>{this.state.modalType == "Edit" ? "Update" : "Save"}
                                                        </button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
                <AddTagandMylistModal {...this.props} title={'Tag'} showcanvas={this.state.showcanvasTag} ref={this.resetRefTag} onHidecanvas={() => this.setState({ showcanvasTag: false })} type='tag' data={data} submit={this.tagSubmit} />
                <AddTagandMylistModal {...this.props} title={'My List'} showcanvas={this.state.showcanvasMylist} ref={this.resetRef} onHidecanvas={() => this.setState({ showcanvasMylist: false })} type='mylist' data={data} submit={this.mylistSubmit} />
                <SpinnerComponent spinner={spinner} />
            </>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    LandingState: state.landing,
    TodoListState: state.todolist,
    UserState: state.user,
    ConfigurationState: state.configuration,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListUser: AC_LIST_USER,
        HandleInputChange: AC_HANDLE_TO_DO_LIST_CHANGE,
        ListTodoMyList: AC_LIST_TODO_MY_LIST,
        ListTodoTag: AC_LIST_TODO_TAGS,
        ListTodoListItems: AC_LIST_TO_DO_LIST_ITEMS,
        EmptyTodoList: AC_EMPTY_TO_DO_LIST,
        SpinnerTodoList: AC_SPINNER_TO_DO_LIST,
        ViewTodoList: AC_PASS_VIEW_TO_DO_LIST,
        EmptyTodoListItems: AC_EMPTY_TODO_LISTITEMS,
        AddTodoApis: AC_ADD_TODO_APIS,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTaskReminder);