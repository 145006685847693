import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_LIST_TICKET_COUNT(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listTicketCount + "?hotel=" + params.hotel)
			.then((res) => {
				dispatch({ type: "LIST_TICKET_COUNT", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_CATEGORY_WISE_COUNT_COMPLETED(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.ticketCountByCategoryCompleted, { params: params })
			.then((res) => {
				if (res.data)
					dispatch({ type: "LIST_CATEGORY_WISE_COUNT_COMPLETED", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_GET_DESCRIPTION_WISE_TICKET_BY_ID(formData, type = 'last') {
	return function (dispatch) {
		return axios.post(ImportedURL.API.getTicketByDescription, formData)
			.then((res) => {
				dispatch({ type: "DESCRIPTION_BY_TICKET", payload: res.data, gettype: type })
			}).catch((err) => { console.log(err); });
	};
}
export function AC_EMPTY_TICKET_COUNT() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_TICKET_COUNT" })
	}
}
export function AC_HANDLE_TICKET_KEY_SEARCH(value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_TICKET_KEY_SEARCH", value: value })
	};
}

// --------------optimise api--------

export function AC_GET_TICKET_REPORT(formData = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.ticketReportInAll, formData)
			.then((res) => {
				if (res.data) {
					dispatch({ type: "LIST_TICKET_COUNT", payload: res.data.ticketCounts })
					dispatch({ type: "DESCRIPTION_WISE_TICKET", payload: res.data.sentimentalCounts })
					dispatch({ type: "LIST_TICKET_PRIORITY_COUNT", payload: res.data.ticketPriorityCounts })
					dispatch({ type: "LIST_CATEGORY_WISE_COUNT", payload: res.data.ticketCountByCategory, status: "open" })
					dispatch({ type: "LIST_CATEGORY_WISE_COUNT", payload: res.data.ticketCountByCategoryCompleted, status: "completed" })
				}
			}).catch((err) => { console.log(err); });
	};
}

export function AC_GET_HOTEL_REPORT(formData = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.hotelReportInAll, formData)
			.then((res) => {
				if (res.data) {
					dispatch({ type: "LIST_USER_TICKETS_COUNT", payload: res.data.allTicketStatus });
					dispatch({ type: "LIST_PLACE_TICKETS_COUNT", payload: res.data.getplaceCount })
					dispatch({ type: "LIST_SOCIAL_REVIEWS_COUNT", payload: res.data.getSocialReviewsCount })
				}
			}).catch((err) => { console.log(err); });
	};
}
// export function AC_HANDLE_DATE_TIME_CHANGE(name, value) {
//     return function (dispatch) {
//         dispatch({ type: "HANDLE_DATE_TIME_CHANGE", name: name, value: value })
//     };
// }
