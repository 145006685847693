const LIVE = true;
const ENV = false ? 'prod' : 'dev';
const SITEURL = "https://testadmin.myhotelai.com/"
const LIVEURL = LIVE ? SITEURL : "http://localhost:4001/";
const ROOTURL = LIVEURL + 'api/v1/';
const MOBILEURL = LIVEURL + 'api/v1/mobile/';
const LOCALURL = SITEURL;
const FILEURL = ENV == 'dev' ? "https://test-assets-myhotelai.s3.us-east-2.amazonaws.com/" : 'https://assets-myhotelai.s3.us-east-2.amazonaws.com/';
const GUESTINVITEURL = SITEURL;
const BOOKINGENGINEURL = LIVE ? "https://myhotelai.bookingengine.com/" : "http://localhost:3000/";
const LOCALIMAGEURL = LIVE ? SITEURL : "http://localhost:3000/";
const MANAGEMENTCATEGORY = ['OWNER', 'GM', 'AGM'];
const SUPERADMIN = ['admin'];
const HOTELOWNER = 'OWNER';

const API = {
  getLoginHistory: ROOTURL + 'loginhistory/getLoginHistory',
  listLogHistory: ROOTURL + 'loghistory/listLogHistory',
  getUserVisitingHistory: ROOTURL + 'uservisitinghistory/getUserVisitingHistory',

  addHotel: ROOTURL + 'hotel/addHotel',
  listHotel: ROOTURL + 'hotel/listHotel',
  listHotelCount: ROOTURL + 'hotel/listHotelCount',
  viewHotel: ROOTURL + 'hotel/viewHotel',
  updateHotel: ROOTURL + 'hotel/updateHotel',
  softDeleteHotel: ROOTURL + 'hotel/softDeleteHotel',
  viewHotelData: ROOTURL + 'hotel/viewHotelData',
  listTrashHotel: ROOTURL + 'hotel/listTrashHotel',
  listUserOwner: ROOTURL + 'hotel/listUserOwner',
  fetchELFData: ROOTURL + 'hotel/fetchELFData',


  login: ROOTURL + 'account/login',
  accountDetails: ROOTURL + 'account/accountDetails',
  getHistory: ROOTURL + 'history/getHistory',
  getACWHouse: ROOTURL + 'history/getACWHouse',
  previleges: ROOTURL + 'account/previleges',
  getPrevileges: ROOTURL + 'account/getPrevileges',
  forgotPassword: ROOTURL + 'account/forgotPassword',
  changePassword: ROOTURL + 'account/changePassword',
  changeHotelLogin: ROOTURL + 'account/changeHotelLogin',
  changeAdmin: ROOTURL + 'account/updateAdmin',
  livekeysetting: ROOTURL + 'account/livekeysetting',
  viewAdmin: ROOTURL + 'account/viewAccount',
  updateSettingReport: ROOTURL + 'account/updateSettingReport',
  sendotpadminpassword: ROOTURL + 'account/sendotpadminpassword',
  setadminpassword: ROOTURL + 'account/setadminpassword',

  updateReviewExpDaysByHotel: ROOTURL + 'hotel/updateReviewExpDaysByHotel',

  addCategory: ROOTURL + 'category/addCategory',
  listCategory: ROOTURL + 'category/listCategory',
  listCategoryDragDrop: ROOTURL + 'category/listCategoryDragDrop',
  listTrashCategory: ROOTURL + 'category/listTrashCategory',
  viewCategory: ROOTURL + 'category/viewCategory',
  updateCategory: ROOTURL + 'category/updateCategory',
  softDeleteCategory: ROOTURL + 'category/softDeleteCategory',
  listDynamicCategory: ROOTURL + 'category/listDynamicCategory',
  addDynamicCategory: ROOTURL + 'category/addDynamicCategory',
  statusDynamicCategory: ROOTURL + 'category/statusDynamicCategory',
  listHotelDynamicCatgory: ROOTURL + 'category/listHotelDynamicCatgory',
  listCategoryStatus: ROOTURL + 'category/listCategoryStatus',
  viewHotelDynamicCatgory: ROOTURL + 'category/viewHotelDynamicCatgory',
  madatoryStatus: ROOTURL + 'category/madatoryStatus',
  webAccessStatus: ROOTURL + 'category/webAccessStatus',

  addUser: ROOTURL + 'user/addUser',
  addUserPrevilege: ROOTURL + 'user/addUserPrevilege',
  listUser: ROOTURL + 'user/listUser',
  listUserCount: ROOTURL + 'user/listUserCount',
  viewUser: ROOTURL + 'user/viewUser',
  updateUser: ROOTURL + 'user/updateUser',
  updatePrevilegeStatus: ROOTURL + 'user/updatePrevilegeStatus',
  updatePmsPrevilegeStatus: ROOTURL + 'user/updatePmsPrevilegeStatus',
  deleteUser: ROOTURL + 'user/deleteUser',
  sendOtp: ROOTURL + 'user/sendOtp',
  sendInvite: ROOTURL + 'user/sendInvite',
  resetUserPassword: ROOTURL + 'user/resetUserPassword',
  updateAllUsers: ROOTURL + 'user/updateAllUsers',
  updateAllVendors: ROOTURL + 'user/updateAllVendors',
  nullnotificationTokenUsers: ROOTURL + 'user/nullnotificationTokenUsers',
  updateTimezone: ROOTURL + 'inventory/updateTimezone',
  updateInventoryDates: ROOTURL + 'inventory/updateInventoryDates',

  addCountry: ROOTURL + 'country/addCountry',
  listCountry: ROOTURL + 'country/listCountry',
  listCountryCategory: ROOTURL + 'country/listCountryCategory',
  viewCountry: ROOTURL + 'country/viewCountry',
  updateCountry: ROOTURL + 'country/updateCountry',
  deleteCountry: ROOTURL + 'country/deleteCountry',
  softDeleteCountry: ROOTURL + 'country/softDeleteCountry',
  listTrashCountry: ROOTURL + 'country/listTrashCountry',

  addCurrency: ROOTURL + 'currency/addCurrency',
  listCurrency: ROOTURL + 'currency/listCurrency',
  viewCurrency: ROOTURL + 'currency/viewCurrency',
  updateCurrency: ROOTURL + 'currency/updateCurrency',
  softDeleteCurrency: ROOTURL + 'currency/softDeleteCurrency',
  deleteCurrency: ROOTURL + 'currency/deleteCurrency',
  getCurrencyByHotel: ROOTURL + 'currency/getCurrencyByHotel',

  addRoomtype: ROOTURL + 'roomtype/addRoomtype',
  listRoomtype: ROOTURL + 'roomtype/listRoomtype',
  listTrashRoomtype: ROOTURL + 'roomtype/listTrashRoomtype',
  viewRoomtype: ROOTURL + 'roomtype/viewRoomtype',
  updateRoomtype: ROOTURL + 'roomtype/updateRoomtype',
  softDeleteRoomtype: ROOTURL + 'roomtype/softDeleteRoomtype',


  getSettings: ROOTURL + 'settings/getSettings',
  updateSettings: ROOTURL + 'settings/updateSettings',

  sendReview: ROOTURL + 'review/sendReview',
  sendBulkReview: ROOTURL + 'review/sendBulkReview',
  sendFeedback: ROOTURL + 'review/sendFeedback',
  badGuestFeedback: ROOTURL + 'review/badGuestFeedback',
  getFeedback: ROOTURL + 'review/getFeedback',
  getFeedbackCount: ROOTURL + 'review/getFeedbackCount',
  viewFeedback: ROOTURL + 'review/viewFeedback',
  getReview: ROOTURL + 'review/getReview',
  viewReviews: ROOTURL + 'review/viewReviews',
  reviewSeen: ROOTURL + 'review/reviewSeen',
  reviewUpdate: ROOTURL + 'review/reviewUpdate',
  getReport: ROOTURL + 'review/getReport',
  getMonthlyReport: ROOTURL + 'review/getMonthlyReport',
  getSocialReviews: ROOTURL + 'review/getSocialReviews',
  reviewByClient: ROOTURL + 'review/reviewByClient',
  reviewKeyword: ROOTURL + 'review/reviewKeyword',
  getReviewById: ROOTURL + 'review/getReviewById',
  importSocialReviews: ROOTURL + 'review/importSocialReviews',
  getSocialReviewsCount: ROOTURL + 'review/getSocialReviewsCount',
  sendReply: ROOTURL + 'review/sendReply',
  getPieChart: ROOTURL + 'review/getPieChart',
  getSocialReview: ROOTURL + 'review/getSocialReview',
  getStarCount: ROOTURL + 'review/getStarCount',
  getQueuelist: ROOTURL + 'review/getQueuelist',
  deleteQueue: ROOTURL + 'review/deleteQueue',
  overallreport: ROOTURL + 'review/overallreport',
  guestoverallreport: ROOTURL + 'guesttouch/guestoverallreport',
  sendGuestReview: ROOTURL + 'guesttouch/sendGuestReview',
  sendBulkGuestReview: ROOTURL + 'guesttouch/sendBulkGuestReview',
  getGuestReview: ROOTURL + 'guesttouch/getGuestReview',
  getGuestReport: ROOTURL + 'guesttouch/getGuestReport',
  getGuestFeedback: ROOTURL + 'guesttouch/getGuestFeedback',
  sendQueue: ROOTURL + 'review/sendQueue',
  sendQueueGuestouch: ROOTURL + 'guesttouch/sendQueueGuestouch',
  getIndividualReviews: ROOTURL + 'review/getIndividualReviews',

  listTicket: ROOTURL + 'ticket/listTicket',
  createTicketReport: ROOTURL + 'ticket/createTicketReport',
  createVsCloseTicketReport: ROOTURL + 'ticket/createVsCloseTicketReport',
  ageingReport: ROOTURL + 'ticket/ageingReport',
  //fetchPriorityCounts: ROOTURL + 'ticket/fetchPriorityCounts',
  fetchcategoryCounts: ROOTURL + 'ticket/fetchcategoryCounts',
  fetchConfiguration: ROOTURL + 'ticket/fetchConfiguration',
  ticketsDateCount: ROOTURL + 'ticket/ticketsDateCount',
  listTrashTickets: ROOTURL + 'ticket/listTrashTickets',
  softDeleteTickets: ROOTURL + 'ticket/softDeleteTickets',
  viewTicket: ROOTURL + 'ticket/viewTicket',
  addCommonIssue: ROOTURL + 'ticket/addCommonIssue',
  listCommonIssues: ROOTURL + 'ticket/listCommonIssues',
  viewCommonIssue: ROOTURL + 'ticket/viewCommonIssue',
  updateCommonIssue: ROOTURL + 'ticket/updateCommonIssue',
  deleteCommonIssue: ROOTURL + 'ticket/deleteCommonIssue',
  listTicketUser: ROOTURL + 'ticket/listTicketUser',
  listComments: ROOTURL + 'ticket/listComments',
  listTicketCount: ROOTURL + 'ticket/listTicketCount',
  ticketReport: ROOTURL + 'ticket/ticketReport',
  addComment: ROOTURL + 'ticket/addComment',
  userTicket: ROOTURL + 'ticket/userTicket',
  userTicketCount: ROOTURL + 'ticket/userTicketCount',
  assignTaskerToTicket: MOBILEURL + 'ticket/assignTaskerToTicket',
  ticketData: ROOTURL + 'ticket/ticketData',
  viewTicketDataUser: ROOTURL + 'ticket/viewTicketDataUser',

  listIncidentTicket: ROOTURL + 'incidentticket/listIncidentTicket',
  createIncidentTicketReport: ROOTURL + 'incidentticket/createIncidentTicketReport',
  reportCreateIncidentTicket: ROOTURL + 'incidentticket/reportCreateIncidentTicket',
  viewIncidentTicket: ROOTURL + 'incidentticket/viewIncidentTicket',

  listNotification: ROOTURL + 'notification/listNotification',
  getNotificationById: ROOTURL + 'notification/getNotificationById',
  locationRestrict: ROOTURL + 'common/locationRestrict',
  statusChange: ROOTURL + 'common/statusChange',
  statusChangeCountry: ROOTURL + 'common/statusChangeCountry',


  addGroup: ROOTURL + 'group/addGroup',
  listGroup: ROOTURL + 'group/listGroup',
  listTrashGroup: ROOTURL + 'group/listTrashGroup',
  viewGroup: ROOTURL + 'group/viewGroup',
  updateGroup: ROOTURL + 'group/updateGroup',
  softDeleteGroup: ROOTURL + 'group/softDeleteGroup', // -- soft delete
  listHotelsByGroup: ROOTURL + 'group/listHotelsByGroup',

  listRoom: ROOTURL + 'settingroom/listSettingRoom',


  getTicketPriorityCounts: ROOTURL + 'dashboard/getTicketPriorityCounts',
  getBadReviews: ROOTURL + 'dashboard/getBadReviews',
  getEmployeeCount: ROOTURL + 'dashboard/getEmployeeCount',

  addSocialMedia: ROOTURL + 'socialmedia/addSocialMedia',
  listSocialMedia: ROOTURL + 'socialmedia/listSocialMedia',
  viewSocialMedia: ROOTURL + 'socialmedia/viewSocialMedia',
  updateSocialMedia: ROOTURL + 'socialmedia/updateSocialMedia',
  deleteSocialMedia: ROOTURL + 'socialmedia/deleteSocialMedia',
  listSocialMediaDragDrop: ROOTURL + 'socialmedia/listSocialMediaDragDrop',

  ticketCountByCategory: ROOTURL + 'reports/ticketCountByCategory',
  ticketCountByCategoryCompleted: ROOTURL + 'reports/ticketCountByCategoryCompleted',
  allUserTicketStatus: ROOTURL + 'reports/allTicketStatus',
  getplaceCount: ROOTURL + 'reports/getplaceCount',
  getSocialReviewsCount: ROOTURL + 'reports/getSocialReviewsCount',
  getDescriptionWiseTickets: ROOTURL + 'reports/descriptionWiseTickets',
  getTicketByDescription: ROOTURL + 'reports/ticketByDescription',
  ticketReportInAll: ROOTURL + 'reports/ticketReport',
  hotelReportInAll: ROOTURL + 'reports/hotelReport',

  addTodolistitem: ROOTURL + 'todolistitem/addTodolistitem',
  listTodolistitem: ROOTURL + 'todolistitem/listTodolistitem',
  viewTodolistitem: ROOTURL + 'todolistitem/viewtodolistitem',
  deletetodolistitem: ROOTURL + 'todolistitem/deletetodolistitem',
  updatetodolistitem: ROOTURL + 'todolistitem/updatetodolistitem',
  listtodolistitemTrash: ROOTURL + 'todolistitem/listtodolistitemTrash',
  softDeletetodolistitem: ROOTURL + 'todolistitem/softDeletetodolistitem',

  addTodoTag: ROOTURL + 'todotag/addTodoTag',
  listTodoTag: ROOTURL + 'todotag/listTodoTag',
  viewTodoTag: ROOTURL + 'todotag/viewTodoTag',
  updateTodoTag: ROOTURL + 'todotag/updateTodoTag',

  addTodoMyList: ROOTURL + 'todomylist/addTodoMyList',
  listTodoMyList: ROOTURL + 'todomylist/listTodoMyList',
  viewTodoMyList: ROOTURL + 'todomylist/viewTodoMyList',
  updateTodoMyList: ROOTURL + 'todomylist/updateTodoMyList',

  addTimezone: ROOTURL + 'timezone/addTimezone',
  listTimezone: ROOTURL + 'timezone/listTimezone',
  listTrashTimezone: ROOTURL + 'timezone/listTrashTimezone',
  viewTimezone: ROOTURL + 'timezone/viewTimezone',
  updateTimezone: ROOTURL + 'timezone/updateTimezone',
  softDeleteTimezone: ROOTURL + 'timezone/softDeleteTimezone',


  addshortenurl: ROOTURL + 'shortenurl/addshortenurl',
  code: ROOTURL + 'shortenurl/code',

  listtodolistprogress: ROOTURL + 'todolistprogress/listtodolistprogress',
  viewtodolistprogress: ROOTURL + 'todolistprogress/viewtodolistprogress',
  updateTodoListProgress: ROOTURL + 'todolistprogress/updateTodoListProgress',
  listTodoListReport: ROOTURL + 'todolistprogress/listTodoListReport',


  addProblemKeyWord: ROOTURL + 'problemkeyword/addProblemKeyWord',
  listProblemKeyWord: ROOTURL + 'problemkeyword/listProblemKeyWord',
  viewProblemKeyWord: ROOTURL + 'problemkeyword/viewProblemKeyWord',
  updateProblemKeyWord: ROOTURL + 'problemkeyword/updateProblemKeyWord',
  softDeleteProblemKeyWord: ROOTURL + 'problemkeyword/softDeleteProblemKeyWord',
  listTrashProblemKeyWord: ROOTURL + 'problemkeyword/listTrashProblemKeyWord',

  addDesignation: ROOTURL + 'designation/addDesignation',
  listDesignation: ROOTURL + 'designation/listDesignation',
  listTrashDesignation: ROOTURL + 'designation/listTrashDesignation',
  softDeleteDesignation: ROOTURL + 'designation/softDeleteDesignation',
  viewDesignation: ROOTURL + 'designation/viewDesignation',
  updateDesignation: ROOTURL + 'designation/updateDesignation',

  getPushmessage: ROOTURL + 'settings/getPushmessage',
  updatePushmessage: ROOTURL + 'settings/updatePushmessage',

  addPhoneNumberBlockList: ROOTURL + 'phonenumberblocklist/addPhoneNumberBlockList',
  listPhoneNumberBlockList: ROOTURL + 'phonenumberblocklist/listPhoneNumberBlockList',
  listTrashPhoneNumberBlockList: ROOTURL + 'phonenumberblocklist/listTrashPhoneNumberBlockList',
  viewPhoneNumberBlockList: ROOTURL + 'phonenumberblocklist/viewPhoneNumberBlockList',
  updatePhoneNumberBlockList: ROOTURL + 'phonenumberblocklist/updatePhoneNumberBlockList',
  softDeletePhoneNumberBlockList: ROOTURL + 'phonenumberblocklist/softDeletePhoneNumberBlockList',


  listSettingRoomtype: ROOTURL + 'settingroomtype/listSettingRoomtype',
  listTrashSettingRoomtype: ROOTURL + 'settingroomtype/listTrashSettingRoomtype',
  addSettingRoomtype: ROOTURL + 'settingroomtype/addSettingRoomtype',
  viewSettingRoomtype: ROOTURL + 'settingroomtype/viewSettingRoomtype',
  updateSettingRoomtype: ROOTURL + 'settingroomtype/updateSettingRoomtype',
  softDeleteSettingRoomtype: ROOTURL + 'settingroomtype/softDeleteSettingRoomtype',
  statusChangeRoomType: ROOTURL + 'settingroomtype/statusChangeRoomType',
  listRoomtypeHistory: ROOTURL + 'settingroomtype/listRoomtypeHistory',

  listSettingRoom: ROOTURL + 'settingroom/listSettingRoom',
  listRoomWithoutAggregation: ROOTURL + 'settingroom/listRoomWithoutAggregation',
  listSettingRoomAllDetails: ROOTURL + 'settingroom/listSettingRoomAllDetails',
  listTrashSettingRoom: ROOTURL + 'settingroom/listTrashSettingRoom',
  addSettingRoom: ROOTURL + 'settingroom/addSettingRoom',
  viewSettingRoom: ROOTURL + 'settingroom/viewSettingRoom',
  viewSettingRoomQrData: ROOTURL + 'settingroom/viewSettingRoomQrData',
  updateSettingRoom: ROOTURL + 'settingroom/updateSettingRoom',
  softDeleteSettingRoom: ROOTURL + 'settingroom/softDeleteSettingRoom',
  listSettingOutofservice: ROOTURL + 'settingroom/listSettingOutofservice',
  updateSettingRoomServiceStatus: ROOTURL + 'settingroom/updateSettingRoomServiceStatus',
  updateOutOfServiceRoom: ROOTURL + 'settingroom/updateOutOfServiceRoom',
  statusChangeRoom: ROOTURL + 'settingroom/statusChangeRoom',
  listSettingRoomHistory: ROOTURL + 'settingroom/listSettingRoomHistory',
  checkTheRservationInOutOfService: ROOTURL + 'settingroom/checkTheRservationInOutOfService',
  reasignRoomDataInReservation: ROOTURL + 'settingroom/reasignRoomDataInReservation',

  addConfiguration: ROOTURL + 'configuration/addConfiguration',
  listConfiguration: ROOTURL + 'configuration/listConfiguration',
  listTrashConfiguration: ROOTURL + 'configuration/listTrashConfiguration',
  viewConfiguration: ROOTURL + 'configuration/viewConfiguration',
  updateConfiguration: ROOTURL + 'configuration/updateConfiguration',
  softDeleteConfiguration: ROOTURL + 'configuration/softDeleteConfiguration',
  sortConfigOptions: ROOTURL + 'configuration/sortConfigOptions',

  listConfigurationSignup: ROOTURL + 'configuration/listConfigurationSignup',
  listConfigOptionSignup: ROOTURL + 'configuration/listConfigOptionSignup',

  viewConfigOption: ROOTURL + 'configuration/viewConfigOption',
  listConfigOption: ROOTURL + 'configuration/listConfigOption',
  listConfigOptionSlug: ROOTURL + 'configuration/listConfigOptionSlug',

  addConfigurationMattress: ROOTURL + 'configuration/addConfigurationMattress',
  addConfigurationBedroomtype: ROOTURL + 'configuration/addConfigurationBedroomtype',

  addRoomareaAmenity: ROOTURL + 'roomareaamenity/addRoomareaAmenity',
  viewRoomareaAmenity: ROOTURL + 'roomareaamenity/viewRoomareaAmenity',
  listRoomareaAmenity: ROOTURL + 'roomareaamenity/listRoomareaAmenity',
  softDeleteRoomareaAmenity: ROOTURL + 'roomareaamenity/softDeleteRoomareaAmenity',
  listTrashRoomareaAmenity: ROOTURL + 'roomareaamenity/listTrashRoomareaAmenity',
  updateRoomareaAmenity: ROOTURL + 'roomareaamenity/updateRoomareaAmenity',
  listSettingRoomAmenity: ROOTURL + 'roomareaamenity/listSettingRoomAmenity',

  addLanguage: ROOTURL + 'language/addLanguage',
  listLanguage: ROOTURL + 'language/listLanguage',
  listTrashLanguage: ROOTURL + 'language/listTrashLanguage',
  viewLanguage: ROOTURL + 'language/viewLanguage',
  updateLanguage: ROOTURL + 'language/updateLanguage',
  softDeleteLanguage: ROOTURL + 'language/softDeleteLanguage',
  selectedLanguage: ROOTURL + 'language/selectedLanguage',

  addTicketWorkFlow: ROOTURL + 'ticketworkflow/addTicketWorkFlow',
  customTicketWorkFlow: ROOTURL + 'ticketworkflow/customTicketWorkFlow',
  viewTicketWorkFlow: ROOTURL + 'ticketworkflow/viewTicketWorkFlow',
  listTicketWorkFlow: ROOTURL + 'ticketworkflow/listTicketWorkFlow',
  defaultTicketFlow: ROOTURL + 'ticketworkflow/defaultTicketFlow',
  defaultTicketFlowRemainder: ROOTURL + 'ticketworkflow/defaultTicketFlowRemainder',
  defaultTicketPriority: ROOTURL + 'ticketpriority/defaultTicketPriority',
  viewTicketPriority: ROOTURL + 'ticketpriority/viewTicketPriority',
  updateTicketPriority: ROOTURL + 'ticketpriority/updateTicketPriority',

  defaultReviewFlow: ROOTURL + 'reviewflow/defaultReviewFlow',
  addReviewFlow: ROOTURL + 'reviewflow/addReviewFlow',
  viewReviewFlow: ROOTURL + 'reviewflow/viewReviewFlow',

  addDynamicManagement: ROOTURL + 'dynamicmanagement/addDynamicManagement',

  //Everything Ag-Grid Update Sort
  sortCategory: ROOTURL + 'category/sortCategory',
  sortFood: ROOTURL + 'food/sortFood',
  sortSubFoodCategory: ROOTURL + 'subfoodcategory/sortSubFoodCategory',
  sortFoodCategory: ROOTURL + 'foodcategory/sortFoodCategory',
  sortSocialMedia: ROOTURL + 'socialmedia/sortSocialMedia',

  updateReminder: ROOTURL + 'reminder/updateReminder',
  viewReminder: ROOTURL + 'reminder/viewReminder',

  downloadPdf: ROOTURL + 'pdfexport/downloadPdf',

  getTodolistitembyHotel: ROOTURL + 'todolist/getTodolistitembyHotel',
  addTodolist: ROOTURL + 'todolist/addTodolist',
  viewTodo: ROOTURL + 'todolist/viewTodo',
  editTodolist: ROOTURL + 'todolist/editTodolist',
  mytodos: ROOTURL + 'todolist/mytodos',
  mytodosTrash: ROOTURL + 'todolist/mytodosTrash',
  updateTodoStatus: ROOTURL + 'todolist/updateTodoStatus',
  sortToDoProgress: ROOTURL + 'todolist/sortToDoProgress',
  softDeleteToDoProgress: ROOTURL + 'todolist/softDeleteToDoProgress',
  allmytask: ROOTURL + 'todolist/allmytask',
  pendingTodoList: ROOTURL + 'todolist/pendingTodoList',
  toodAddAPI: ROOTURL + 'todolist/toodAddAPI',
  todolistProgress: ROOTURL + 'todolist/todolistProgress',
  googleSync: ROOTURL + 'todolist/googleSync',
  userstaskreports: ROOTURL + 'todolist/userstaskreports',

  // Code Optimization :: DONT Touch this part (Venky)
  getLandingData: ROOTURL + 'dashboard/commonPageData',
  getLanguageData: ROOTURL + 'dashboard/getAllLanguageData',
  getDashboardPageData: ROOTURL + 'dashboard/getDashboardPageData',
  getCounts: ROOTURL + 'dashboard/getCounts',

  configurationArray: ROOTURL + 'myhotelaipms/configurationArray',

  addPMSPrivileges: ROOTURL + 'pmsprivileges/addPMSPrivileges',
  listPMSPrivileges: ROOTURL + 'pmsprivileges/listPMSPrivileges',
  viewPMSPrivileges: ROOTURL + 'pmsprivileges/viewPMSPrivileges',
  updatePMSPrivileges: ROOTURL + 'pmsprivileges/updatePMSPrivileges',

  addDeveshGuest: ROOTURL + 'deveshguest/addDeveshGuest',
  listDeveshGuest: ROOTURL + 'deveshguest/listDeveshGuest',
  sendDeveshInvite: ROOTURL + 'deveshguest/sendDeveshInvite',
  updateDevesGuestMessage: ROOTURL + 'deveshguest/updateDevesGuestMessage',
  listDeveshGuestMessageCount: ROOTURL + 'deveshguest/listDeveshGuestMessageCount',

  addBookingStatus: ROOTURL + 'bookingstatus/addBookingStatus',
  updateBookingStatus: ROOTURL + 'bookingstatus/updateBookingStatus',
  listBookingStatus: ROOTURL + 'bookingstatus/listBookingStatus',

  updateDefaultTicketWorkFlow: ROOTURL + 'hotelworkflow/updateDefaultTicketWorkFlow',
  updateDefaultManagement: ROOTURL + 'hotelworkflow/updateDefaultManagement',
}


const ImportedURL = {
  API: API,
  LIVEURL: LIVEURL,
  LOCALURL: LOCALURL,
  FILEURL: FILEURL,
  LIVE: LIVE,
  GUESTINVITEURL: GUESTINVITEURL,
  BOOKINGENGINEURL: BOOKINGENGINEURL,
  LOCALIMAGEURL: LOCALIMAGEURL,
  MANAGEMENTCATEGORY: MANAGEMENTCATEGORY,
  SUPERADMIN: SUPERADMIN,
  HOTELOWNER: HOTELOWNER,
}
export default ImportedURL;
