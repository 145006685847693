import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import Chart from "react-apexcharts";
import Spinner from "react-bootstrap/Spinner";
import ImportedURL from "../../common/api";
import { bindActionCreators } from "redux";
import { AC_LIST_GROUP } from "../../actions/groupAction";
import { Error } from "../../common/swal";
import { AC_LIST_HOTEL } from "../../actions/hotelAction";
import showSwalDialog from '../../utilities/Modal/swal';
import Ticketpdf from "./ticketpdf";
import {
  objectToQueryString,
  onErrorImage,
} from "../../common/validate";
//import { status } from "../../../schemas/hotel";

const getTicket = (value) => {
  if (value == 'open') {
    return "Open";
  } else if (value === 'inprogress') {
    return "Inprogress";
  } else if (value === 'reopened') {
    return "Reopened";
  } else if (value === 'assigned') {
    return "Assigned";
  } else if (value === 'inreview') {
    return "Inreview";
  } else if (value === 'closed') {
    return "Closed";
  } else if (value === 'completed') {
    return "Completed";
  } else if (value === 'Deleted') {
    return "Deleted";
  } else {
    return value;
  }
}

class ReportCreateTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        { headerName: "Hotel", field: "hotel", width: 250, hide: true },
        {
          headerName: "Ticket #",
          field: "ticketid",
          width: 200,
          floatingFilter: false,
          cellStyle: { "text-align": "center" },
        },
        {
          headerName: "Created Date",
          field: "time",
          width: 150,
          cellStyle: { "text-align": "center" },
          cellRenderer: function (params) {
            return params.value
              ? `<span>` +
              moment(params.data?.time).format(params.data?.dtFormat) +
              `</span>`
              : "";
          },
        },

        {
          headerName: "Issue Category",
          field: "issuecategory",
          width: 200,
          floatingFilter: false,
          cellStyle: { "text-align": "center" },
          valueGetter: function (params) {
            return params.data && params.data.issuecategory ? params.data.issuecategory : "---";
          },
        },
        {
          headerName: "Title",
          field: "title",
          width: 200,
          floatingFilter: false,
          cellStyle: { "text-align": "center" },
        },
        {
          headerName: "Description",
          field: "description",
          width: 200,
          floatingFilter: false,
          valueGetter: function (params) {
            if (params.data && params.data.description) {
              return params.data.description;
            } else if (params.data && params.data.description == "") {
              return "---";
            }
          },
        },
        {
          headerName: "Assigned To",
          field: "assignee",
          width: 150,
          floatingFilter: false,
          valueGetter: function (params) {
            return params.data && params.data.assignee ? params.data.assignee : "---";
          },
        },
        {
          headerName: "Due Date",
          field: "dueDate",
          width: 150,
          cellStyle: { "text-align": "center" },
          cellRenderer: function (params) {
            return params.value
              ? `<span>` +
              moment(params.data?.dueDate).format(params.data?.dtFormat) +
              `</span>`
              : "";
          },
        },
        {
          headerName: "Priority",
          field: "priority",
          width: 150,
          floatingFilter: false,
          valueGetter: (params) => {
            switch (params.data && params.data?.priority) {
              case "1":
                return "High";
              case "2":
                return "Medium";
              case "3":
                return "Low";
              default:
                return "Unknown";
            }
          },
        },
        {
          headerName: "Status",
          width: 250,
          field: "status",
          cellStyle: { "text-align": "center" },
          suppressMenu: true,
          valueGetter: function (params) {
            return getTicket(params.data ? params.data.status : "");
          },
          cellRenderer: function (params) {
            if (params.value == 'Open') {
              return `<span class="tag ticket_open">Open</span>`;
            } else if (params.value === 'Inprogress') {
              return '<span class="tag ticket_inprogress">Inprogress</span>';
            } else if (params.value === 'Reopened') {
              return '<span class="tag ticket_reopened">Reopened</span>';
            } else if (params.value === 'Assigned') {
              return '<span class="tag ticket_assigned">Assigned</span>';
            } else if (params.value === 'Inreview') {
              return '<span class="tag ticket_inreview">Inreview</span>';
            } else if (params.value === 'Closed') {
              return '<span class="tag ticket_closed">Closed</span>';
            } else if (params.value === 'Completed') {
              return '<span class="tag ticket_completed">Completed</span>';
            } else if (params.value === 'Deleted') {
              return '<span class="tag ticket_deleted">Deleted</span>';
            } else {
              return `<span class="tag default">${params.value}</span>`;
            }
          }
        },
      ],
      defaultColumDef: {
        editable: false,
        sortable: false,
        resizable: true,
        filter: false,
        flex: 1,
        minWidth: 100,
      },
      tickets: [],
      originalData: [],
      userTickets: [],
      categoryCount: [],
      hotelCounts: [],
      lowcount: 0,
      mediumcount: 0,
      highcount: 0,
      ticketid: "",
      hotel: "",
      category: "",
      username: "",
      fromdate: "",
      todate: "",
      month: "",
      day: "",
      status: "",
      hotelName: "",
      showTicket: true,
      openStatus: false,
      assignedStatus: false,
      inprogressStatus: false,
      inreviewStatus: false,
      completedStatus: false,
      reopenedStatus: false,
      reassignedStatus: false,
      totalStatus: true,
      spinner: "",
      columnreset: true,
      ticketCount: "",
      categoryName: "",
      allOption: true,
      allcategoryOption: true,
      isFilterShow: false,
      fromdateValue: "",
      todateValue: "",
      fromdate: "",
      todate: "",
      groupoption: { label: "All", value: "", name: "hotel" },
      statusoption: { label: "All", value: "", name: "status" },
      priorityValue: { label: "All", value: "All", name: "priority" },
      OrderByoption: { label: "All", value: "", name: "orderby" },
      perPage: 25,
      selectedOption: [{ label: "All", value: "", name: "hotel" }],
      categoryOption: [{ label: "All", value: "", name: "category" }],
      isChartView: false,
      categoryState: [],

    };
  }
  getDataSource = (query = {}, statuscheck = false) => {
    let group =
      query.group && query.group != undefined
        ? query.group
        : localStorage.getItem("searchgroup");
    let hotel =
      query.hotel && query.hotel != undefined
        ? query.hotel
        : localStorage.getItem("searchhotel");
    let priority =
      query.priority && query.priority != undefined
        ? query.priority
        : localStorage.getItem("searchpriority");
    let orderby =
      query.orderby && query.orderby != undefined
        ? query.orderby
        : localStorage.getItem("searchorderby");
    let category =
      query.category && query.category != undefined
        ? query.category
        : localStorage.getItem("searchcategory");
    let fromdate =
      query.fromdate && query.fromdate != undefined
        ? query.fromdate
        : localStorage.getItem("searchfromdate");
    let todate =
      query.todate && query.todate != undefined
        ? query.todate
        : localStorage.getItem("searchtodate");
    let status =
      query.status && query.status != undefined
        ? query.status
        : localStorage.getItem("searchstatus");
    if (group) query["group"] = group;
    if (priority && priority !== "All") query["priority"] = priority;
    if (orderby) query["orderby"] = orderby;
    if (hotel)
      query["hotel"] = typeof hotel === "string" ? JSON.parse(hotel) : hotel;
    if (category)
      query["category"] =
        typeof category === "string" ? JSON.parse(category) : category;
    if (fromdate) query["fromdate"] = fromdate;
    if (todate) query["todate"] = todate;
    if (status) query["status"] = status;
    return {
      getRows: async (params) => {
        const account = this.props.AccountState.account;
        const dtFormat =
          (account.dateformate != "" ? account.dateformate : "MM/DD/YYYY") +
          " " +
          (account.timeformat != "" ? account.timeformat : "hh:mm A");
        const page = Math.floor(params.startRow / this.state.perPage);
        const filter = params.filterModel;
        const sortModel = params.sortModel;
        this.setState({ spinner: true });
        var tzone = "America/chicago";
        if (
          account != undefined &&
          account &&
          account.tzone != undefined &&
          account.tzone
        ) {
          tzone = account.tzone;
        }
        axios
          .post(ImportedURL.API.createTicketReport, {
            perPage: this.state.perPage,
            page: page,
            filter: filter,
            sort: sortModel.length ? sortModel[0] : {},
            ...query,
          })
          .then((res) => {
            if (res.data) {
              res.data.data.forEach((object) => {
                object.actions = `<div><button type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-eye" style='color: #1DC9B7 !important' data-action-type="View"></i></button></div>`;
                object.tzone = tzone;
                object.dtFormat = dtFormat;
              });
              if (res.data)
                this.setState({
                  tickets: res.data.data,
                  categoryCount: res.data.categoryCounts,
                  hotelCounts: res.data.hotelCounts,
                  originalData: res.data.data,
                  lowcount: res.data.low || 0,
                  highcount: res.data.high || 0,
                  mediumcount: res.data.medium || 0,
                  spinner: false,
                });
              params.successCallback(res.data.data, res.data.total);
              const lbLastRowOnPageEl = document.querySelector(
                `[ref=\'lbLastRowOnPage\']`
              );
              if (lbLastRowOnPageEl) {
                lbLastRowOnPageEl.innerHTML =
                  res.data && res.data.data
                    ? res.data.data.length + params.startRow
                    : 0;
              }
              this.setState({ spinner: false });
              this.UpdateColumnDefs();
            }
          })
          .catch(({ response }) => {
            this.setState({ spinner: false });
            Error("Couldn't fetch records");
            params.successCallback([], 0);
          });
      },
    };
  };
  componentDidUpdate(prevProps, prevState) {
    const { AccountState } = this.props;
    const role = AccountState.role;
    if (
      this.gridApi &&
      role &&
      ImportedURL.SUPERADMIN.includes(role) &&
      this.state.columnreset
    ) {
      this.UpdateColumnDefs();
      this.setState({ columnreset: false });
    }
    if (this.gridApi) {
      if (AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
        const dataSource = this.getDataSource();
        this.gridApi.setDatasource(dataSource);
      }
    }
    if (
      prevState.categoryCount !== this.state.categoryCount ||
      prevState.hotelCounts !== this.state.hotelCounts ||
      prevState.lowcount !== this.state.lowcount ||
      prevState.mediumcount !== this.state.mediumcount ||
      prevState.highcount !== this.state.highcount
    ) {
    }
  }
  componentDidMount() {
    this.resetLocalStorage();
    const { AccountState } = this.props;
    const account = AccountState.account;
    this.setState({ hotelName: account.hotelName ? account.hotelName : "" });
    this.fetchConfiguration();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.setInitialDates();
  }
  setInitialDates = () => {
    const today = moment();
    const todate = today.format('YYYY-MM-DD');
    const fromdate = today.subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    localStorage.setItem("searchfromdate", fromdate ? fromdate + "T00:00:00" : "");
    localStorage.setItem("searchtodate", todate ? todate + "T23:59:59" : "");
    this.setState({
      fromdate,
      fromdateValue: fromdate,
      todate,
      todateValue: todate,
      month: ''
    }, () => {
      if (this.gridApi) {
        const dataSource = this.getDataSource({
          hotel: this.state.hotel,
          group: this.state.group,
          status: this.state.status,
          fromdate: fromdate ? fromdate + "T00:00:00" : '',
          todate: todate ? todate + "T23:59:59" : ''
        });
        this.gridApi.setDatasource(dataSource);
      } else {
        console.error("gridApi is not initialized");
      }
      if (this.state.isChartView) {
        this.fetchCreateTicketReportData({});
      }
    });
  }
  resetLocalStorage = () => {
    localStorage.removeItem("searchgroup");
    localStorage.removeItem("searchpriority");
    localStorage.removeItem("searchorderby");
    localStorage.removeItem("searchhotel");
    localStorage.removeItem("searchcategory");
    // localStorage.removeItem("searchfromdate");
    //  localStorage.removeItem("searchtodate");
    localStorage.removeItem("searchstatus");
  };
  handleChange = (e) => {
    var value = [];
    let label = "";
    let dataStatus = true;
    let dataArr = [];
    if (e.length > 0) {
      e.map((item, i) => {
        if (e[e.length - 1].label == "All") {
          value = [];
        }
        if (item.label == "All") {
          dataStatus = false;
          this.setState({ allOption: false });
        } else {
          if (item.label != "All") {
            dataArr.push(item);
            value.push(item.value);
            this.setState({ allOption: true });
            dataStatus = true;
          }
          if (e.length == 1 && i == 0) {
            label = item.label;
          }
        }
        if (value.length == 1) {
          label = item.label;
        } else {
          label = "";
        }
        if (item.label == "All") {
          if (this.state.fromdate || this.state.todate || this.state.group) {
            this.setState({ isFilterShow: true });
          } else {
            this.setState({ isFilterShow: false });
          }
        } else {
          this.setState({ isFilterShow: true });
        }
      });
    }
    localStorage.setItem("searchhotel", JSON.stringify(value));
    const dataSource = this.getDataSource();
    this.gridApi.setDatasource(dataSource);
    this.setState({
      hotel: value,
      hotelName: label,
      selectedOption: dataStatus
        ? dataArr
        : [{ label: "All", value: "", name: "hotel" }],
    });
    if (this.state.isChartView) {
      this.fetchCreateTicketReportData();
    }
  };
  setGroup = (e) => {
    const { name, value, label } = e;
    this.setState({
      group: value,
      hotel: "",
      hotelName: "",
      selectedOption:
        label == "All" ? [{ label: "All", value: "", name: "hotel" }] : [],
      totalStatus: true,
    });
    if (label == "All") {
      if (this.state.fromdate || this.state.todate) {
        this.setState({ isFilterShow: true });
      } else {
        this.setState({ isFilterShow: false });
      }
    } else {
      this.setState({ isFilterShow: true });
    }
    localStorage.setItem("searchgroup", value);
    localStorage.removeItem("searchhotel");
    const dataSource = this.getDataSource();
    this.gridApi.setDatasource(dataSource);

    this.setState({ [name + "option"]: e });
    if (this.state.isChartView) {
      this.fetchCreateTicketReportData();
    }
  };
  handleCategoryChange = (e) => {
    var value = [];
    let label = "";
    let dataStatus = true;
    let dataArr = [];
    if (e.length > 0) {
      e.map((item, i) => {
        if (e[e.length - 1].label == "All") {
          value = [];
        }
        if (item.label == "All") {
          dataStatus = false;
          this.setState({ allcategoryOption: false });
        } else {
          if (item.label != "All") {
            dataArr.push(item);
            value.push(item.value);
            this.setState({ allcategoryOption: true });
            dataStatus = true;
          }
          if (e.length == 1 && i == 0) {
            label = item.label;
          }
        }
        if (value.length == 1) {
          label = item.label;
        } else {
          label = "";
        }
        if (item.label == "All") {
          if (this.state.fromdate || this.state.todate || this.state.group) {
            this.setState({ isFilterShow: true });
          } else {
            this.setState({ isFilterShow: false });
          }
        } else {
          this.setState({ isFilterShow: true });
        }
      });
    }

    localStorage.setItem("searchcategory", JSON.stringify(value));
    const dataSource = this.getDataSource();
    this.gridApi.setDatasource(dataSource);
    this.setState({
      category: value,
      categoryName: label,
      categoryOption: dataStatus
        ? dataArr
        : [{ label: "All", value: "", name: "category" }],
    });
    if (this.state.isChartView) {
      this.fetchCreateTicketReportData();
    }
  };
  setPriority = (e) => {
    const { name, value, label } = e;
    this.setState({ priorityValue: e });
    this.setState({
      totalStatus: true,
    });
    if (label === "All") {
      if (this.state.fromdate || this.state.todate) {
        this.setState({ isFilterShow: true });
      } else {
        this.setState({ isFilterShow: false });
      }
    } else {
      this.setState({ isFilterShow: true });
    }
    localStorage.setItem("searchpriority", value);
    const dataSource = this.getDataSource();
    this.gridApi.setDatasource(dataSource);
    this.setState({ [name + "option"]: e });
    if (this.state.isChartView) {
      this.fetchCreateTicketReportData();
    }
  };
  fetchCreateTicketReportData = (query = {}) => {
    const { account } = this.props.AccountState;
    const languageData = account.SelectedlanguageData;
    let group = query.group || localStorage.getItem("searchgroup");
    let hotel = query.hotel || localStorage.getItem("searchhotel");
    let priority = query.priority || localStorage.getItem("searchpriority");
    let orderby = query.orderby || localStorage.getItem("searchorderby");
    let category = query.category || localStorage.getItem("searchcategory");
    let fromdate = query.fromdate || localStorage.getItem("searchfromdate");
    let todate = query.todate || localStorage.getItem("searchtodate");
    let status = query.status || localStorage.getItem("searchstatus");
    const requestBody = {};
    if (group) requestBody.group = group;
    if (priority && priority !== "All") {
      requestBody.priority = priority;
    }
    if (orderby) requestBody.orderby = orderby;
    if (hotel)
      requestBody.hotel = typeof hotel === "string" ? JSON.parse(hotel) : hotel;
    if (category)
      requestBody.category =
        typeof category === "string" ? JSON.parse(category) : category;
    if (fromdate) requestBody.fromdate = fromdate;
    if (todate) requestBody.todate = todate;
    if (status) requestBody.status = status;
    this.setState({ spinner: true });
    axios
      .post(ImportedURL.API.createTicketReport, requestBody)
      .then((res) => {
        if (res.data) {
          this.setState(
            {
              categoryCount: res.data.categoryCounts,
              hotelCounts: res.data.hotelCounts,
              lowcount: res.data.low || 0,
              mediumcount: res.data.medium || 0,
              highcount: res.data.high || 0,
              spinner: false
            },
            () => { }
          );
        }
      })
      .catch(({ response }) => {
        this.setState({ spinner: false });
        if (response.status === 500) {
          Error(
            response.status +
            (languageData && languageData.INTERNAL_SERVER_ERROR
              ? languageData.INTERNAL_SERVER_ERROR
              : " Internal Server Error")
          );
        } else if (response.status === 502) {
          Error(
            response.status +
            (languageData && languageData.BAD_GATEWAY
              ? languageData.BAD_GATEWAY
              : " Bad Gateway")
          );
        } else {
          Error(response.statusMessage);
        }
      });
  };
  fetchConfiguration = () => {
    const { account } = this.props.AccountState;
    const languageData = account.SelectedlanguageData;
    axios
      .get(ImportedURL.API.fetchConfiguration)
      .then((res) => {
        if (res.data) {
          this.setState({ categoryState: res.data });
        }
      })
      .catch((error) => {
        const { response } = error;
        const status = response?.status;
        const statusText = response?.statusText ?? "An error occurred";
        switch (status) {
          case 500:
            Error(
              `${status}: ${languageData?.INTERNAL_SERVER_ERROR ?? "Internal Server Error"
              }`
            );
            break;
          case 502:
            Error(`${status}: ${languageData?.BAD_GATEWAY ?? "Bad Gateway"}`);
            break;
          default:
            Error(`${status}: ${statusText}`);
            break;
        }
      });
  };
  setOrderBy = (e) => {
    const { name, value, label } = e;
    this.setState({ OrderByoption: e });
    this.setState({
      orderby: value,
      totalStatus: true,
    });
    if (label === "All") {
      if (this.state.fromdate || this.state.todate) {
        this.setState({ isFilterShow: true });
      } else {
        this.setState({ isFilterShow: false });
      }
    } else {
      this.setState({ isFilterShow: true });
    }
    localStorage.setItem("searchorderby", value);
    const dataSource = this.getDataSource();
    this.gridApi.setDatasource(dataSource);
    this.setState({ [name + "option"]: e });
  };
  setStatus = (e) => {
    const { name, value, label } = e;
    this.setState({ statusoption: e });
    this.setState({
      totalStatus: true,
    });
    if (label === "All") {
      if (this.state.fromdate || this.state.todate) {
        this.setState({ isFilterShow: true });
      } else {
        this.setState({ isFilterShow: false });
      }
    } else {
      this.setState({ isFilterShow: true });
    }
    localStorage.setItem("searchstatus", value);
    const dataSource = this.getDataSource();
    this.gridApi.setDatasource(dataSource);
    this.setState({ [name + "option"]: e });
    if (this.state.isChartView) {
      this.fetchCreateTicketReportData();
    }
  };



  setDateReport = async (e) => {
    const { AccountState } = this.props;
    const { name, value } = e.target;
    const account = AccountState.account;
    let tzone = "America/Chicago";

    if (account && account.tzone) {
      tzone = account.tzone;
    }

    let newValue = value;

    if (!newValue) {
      let confirmationResult;

      if (name === "fromdate") {
        confirmationResult = await showSwalDialog(
          'Do you want to load all the data?',
          '',
          'Ok',
          'Cancel',
          'assets/images/status.png'
        );
      } else {
        confirmationResult = await showSwalDialog(
          'Do you want to empty To Date?',
          '',
          'Ok',
          'Cancel',
          'assets/images/status.png'
        );
      }

      if (!confirmationResult.isConfirmed) {
        return; // User chose not to confirm, exit the function
      }

      // User confirmed, clear the value
      newValue = "";
    }

    // Proceed with setting the date values and updating the state
    if (name === "fromdate") {
      this.setState({
        fromdate: newValue,
        fromdateValue: newValue,
        todate: "",
        todateValue: "",
        month: "",
      });
      localStorage.setItem("searchfromdate", newValue ? newValue + "T00:00:00" : "");
      localStorage.removeItem("searchtodate");
    } else if (name === "todate") {
      this.setState({ todate: newValue, todateValue: newValue, month: "" });
      localStorage.setItem("searchtodate", newValue ? newValue + "T23:59:59" : "");
    }

    if (this.gridApi) {
      const dataSource = this.getDataSource();
      this.gridApi.setDatasource(dataSource);
    } else {
      console.error("gridApi is not defined when setting the datasource.");
    }

    if (
      !localStorage.getItem("searchgroup") &&
      !localStorage.getItem("searchhotel") &&
      !localStorage.getItem("searchfromdate") &&
      !localStorage.getItem("searchcategory") &&
      !localStorage.getItem("searchorderby") &&
      !localStorage.getItem("searchpriority") &&
      value
    ) {
      this.setState({ isFilterShow: false });
    } else {
      this.setState({ isFilterShow: true });
    }

    if (this.state.isChartView) {
      this.fetchCreateTicketReportData();
    }
  };




  getMonthlyReport = (e) => {
    const account = this.props.AccountState.account;
    const { AccountState } = this.props;
    const languageData = AccountState.account.SelectedlanguageData;
    var dFormat =
      account.dateformate != "" ? account.dateformate : "MM/DD/YYYY";
    const { value } = e.target;
    this.setState({ isFilterShow: true });
    const newval = value == 0 ? 0 : value == 1 ? 1 : 2;
    let date = new Date();
    let startDate = new Date(date.getFullYear(), date.getMonth() - newval, 1);
    let endDate = new Date(date.getFullYear(), date.getMonth() - newval + 1, 0);
    localStorage.setItem(
      "searchfromdate",
      startDate ? moment(startDate).format("YYYY-MM-DD") + "T00:00:00" : ""
    );
    localStorage.setItem(
      "searchtodate",
      endDate ? moment(endDate).format("YYYY-MM-DD") + "T23:59:59" : ""
    );

    this.setState({

      fromdate: moment(startDate).format("YYYY-MM-DD"),
      todate: moment(endDate).format("YYYY-MM-DD"),
      month: value,
      fromdateValue: moment(startDate).format("YYYY-MM-DD"),
      todateValue: moment(endDate).format("YYYY-MM-DD"),
      day: "",
    });
    const dataSource = this.getDataSource();
    this.gridApi.setDatasource(dataSource);
    if (this.state.isChartView) {
      this.fetchCreateTicketReportData();
    }
  };
  getMonths = (mon) => {
    let date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() - mon);
    let monthName = new Intl.DateTimeFormat("en", { month: "short" }).format(
      date
    );
    return `${monthName}`;
  };
  getselecteddayReport = (e) => {
    const account = this.props.AccountState.account;
    const { AccountState } = this.props;
    const languageData = AccountState.account.SelectedlanguageData;
    const dFormat =
      account.dateformate !== "" ? account.dateformate : "MM/DD/YYYY";
    const { value } = e.target;
    this.setState({ isFilterShow: true });
    let date = new Date();
    let startDate, endDate;
    if (value === "yesterday") {
      startDate = new Date();
      startDate.setDate(date.getDate() - 1);
      endDate = startDate; // Same day for yesterday
    } else if (value === "7days") {
      startDate = new Date();
      startDate.setDate(date.getDate() - 7);
      endDate = date; // Current date
    } else if (value === "30days") {
      startDate = new Date();
      startDate.setDate(date.getDate() - 30);
      endDate = date; // Current date
    }
    localStorage.setItem(
      "searchfromdate",
      startDate ? moment(startDate).format("YYYY-MM-DD") + "T00:00:00" : ""
    );
    localStorage.setItem(
      "searchtodate",
      endDate ? moment(endDate).format("YYYY-MM-DD") + "T23:59:59" : ""
    );
    this.setState({
      fromdate: moment(startDate).format("YYYY-MM-DD"),
      todate: moment(endDate).format("YYYY-MM-DD"),
      day: value,
      fromdateValue: moment(startDate).format("YYYY-MM-DD"),
      todateValue: moment(endDate).format("YYYY-MM-DD"),
      month: "",
    });
    const dataSource = this.getDataSource();
    this.gridApi.setDatasource(dataSource);
    if (this.state.isChartView) {
      this.fetchCreateTicketReportData();
    }
  };
  getYesterday = () => {
    let formattedDate = "Yesterday";
    return formattedDate;
  };
  get7DaysAgo = () => {
    let formattedDate = "Last 7 Days";
    return formattedDate;
  };
  get30DaysAgo = () => {
    let formattedDate = "Last 30 Days";
    return formattedDate;
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.setState({ gridApi: params.api });
  };
  refreshGridDataSource = () => {
    this.gridApi.purgeInfiniteCache();
    const newDataSource = this.getDataSource();
    if (this.gridApi) {
      this.gridApi.setDatasource(newDataSource);
    }
    this.UpdateColumnDefs();
  };
  onPerPageChange = (e) => {
    const { value } = e;
    this.setState({ perPage: value }, () => {
      if (this.gridApi) {
        this.gridApi.paginationSetPageSize(parseInt(value, 10));
        this.gridApi.purgeInfiniteCache();
      }
    });
  };
  UpdateColumnDefs = () => {
    const role = this.props.AccountState.role;
    if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role)) {
      let updatedColumnDefs = [...this.state.columnDefs];
      updatedColumnDefs = updatedColumnDefs.map((col) => {
        if (col.field === "hotel" || col.field === "logo") {
          return { ...col, hide: false };
        }
        return col;
      });
      this.gridApi.setColumnDefs(updatedColumnDefs);
    }
  };
  exportToCSV = () => {
    const account = this.props.AccountState.account;
    const dtFormat =
      (account.dateformate !== "" ? account.dateformate : "MM/DD/YYYY") +
      " " +
      (account.timeformat !== "" ? account.timeformat : "hh:mm A");
    var firstRow = this.gridApi.getFirstDisplayedRow();
    var lastRow = this.gridApi.getLastDisplayedRow();
    this.gridApi.exportDataAsCsv({
      columnKeys: [
        "hotel",
        "ticketid",
        "time",
        "issuecategory",
        "title",
        "description",
        "assignee",
        "dueDate",
        "priority",
        "status",
      ],
      fileName:
        "Created Ticket-Reports" +
        "_" +
        (this.state.hotelName && this.state.hotelName.replace(/ /g, "_")) +
        account.currentTime.replace(/ /g, "_") +
        ".csv",
      shouldRowBeSkipped: (params) => {
        return (
          params.node.rowIndex < firstRow || params.node.rowIndex > lastRow
        );
      },
      processCellCallback: function (params) {
        if (params && params.column && params.column.colId === "time") {
          return moment(params.value).format(dtFormat);
        } else {
          return params.value;
        }
      },
    });
  };
  total = (e) => {
    this.setState({
      spinner: true,
      openStatus: false,
      assignedStatus: false,
      inprogressStatus: false,
      inreviewStatus: false,
      completedStatus: false,
      reopenedStatus: false,
      reassignedStatus: false,
      totalStatus: true,
      isDeletedStatus: false,
    });
    const dataSource = this.getDataSource();
    this.gridApi.setDatasource(dataSource);
  };
  filterReset = (e) => {
    this.resetLocalStorage();
    localStorage.setItem("searchfromdate", this.state.fromdate ? this.state.fromdate + "T00:00:00" : "");
    localStorage.setItem("searchtodate", this.state.todate ? this.state.todate + "T23:59:59" : "");
    this.setState(
      {
        selectedOption: [{ label: "All", value: "", name: "hotel" }],
        searchhotel: "",
        searchcategory: "",
        fromdate: "",
        todate: "",
        month: "",
        day: "",
        hotel: "",
        hotelName: this.props.AccountState.account.hotelName ? this.props.AccountState.account.hotelName : "",
        groupoption: { label: "All", value: "", name: "hotel" },
        priorityValue: { label: "All", value: "", name: "priority" },
        OrderByoption: { label: "All", value: "", name: "orderby" },
        statusoption: { label: "All", value: "", name: "status" },
        categoryOption: { label: "All", value: "", name: "category" },
        fromdateValue: this.state.fromdate,
        todateValue: this.state.todate,
        statusProgress: { label: "All", value: 0 },
        isFilterShow: false,
        status: "",
        group: "",
      });
    this.setInitialDates();

    // Fetch new ticket report data and reset grid datasource
    // this.fetchCreateTicketReportData();
    const dataSource = this.getDataSource({}, true);
    this.gridApi.setDatasource(dataSource);
  };

  handleExportPDF = () => {
    const account = this.props.AccountState.account;
    const dtFormat =
      (account.dateformate !== "" ? account.dateformate : "MM/DD/YYYY") +
      " " +
      (account.timeformat !== "" ? account.timeformat : "hh:mm A");

    const selectedProperties = this.state.hotelName
      ? ["ticketid", "createdAt", "issuecategory", "title", "description", "assignee", "dueDate", "priority", "status"]
      : ["hotel", "ticketid", "createdAt", "issuecategory", "title", "description", "assignee", "dueDate", "priority", "status"];

    const selectedTickets = this.state.tickets.map((ticket) => {
      const selectedTicket = {};
      selectedProperties.forEach((property) => {
        if (ticket.hasOwnProperty(property)) {
          if (property === "createdAt" || property === "dueDate") {
            // Format date if present; otherwise, use "---"
            selectedTicket[property] = ticket[property] ? moment(ticket[property]).format(dtFormat) : "---";
          } else if (property == "status") {
            selectedTicket[property] = ticket[property] ? getTicket(ticket[property]) : "---"
          } else if (property === "priority") {
            // Convert priority to readable string or use "---"
            selectedTicket[property] =
              ticket[property] == 1 ? "High" :
                ticket[property] == 2 ? "Medium" :
                  ticket[property] == 3 ? "Low" : "---";
          } else {
            // Replace blank or undefined values with "---"
            selectedTicket[property] = ticket[property] ? ticket[property] : "---";
          }
        } else {
          // If property doesn't exist, set it to "---"
          selectedTicket[property] = "---";
        }
      });
      return selectedTicket;
    });

    const data = {
      title: this.state.hotelName
        ? this.state.hotelName !== "All"
          ? `${this.state.hotelName} - Created Ticket Reports`
          : "Created Ticket Reports"
        : "Created Ticket Reports",
      head: this.state.hotelName
        ? ["#", "Ticket #", "Created Date", "Issue Category", "Title", "Description", "Assigned To", "Due Date", "Priority", "Status"]
        : ["#", "Hotel", "Ticket #", "Created Date", "Issue Category", "Title", "Description", "Assigned To", "Due Date", "Priority", "Status"],
      body: selectedTickets,
      hotelNameExport: this.state.hotelName ? account.hotelNameExport : "",
      hoteladdressExport: this.state.hotelName ? account.hoteladdressExport : "",
      limit: this.state.perPage,
      ticketmanagement: true,
      hotelWidth: true,
    };

    this.setState({ spinner: true });

    axios
      .post(ImportedURL.API.downloadPdf, data, { responseType: "blob" })
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Created Ticket-Reports_${this.state.hotelName?.replace(/ /g, "_") || "All"}_${account.currentTime.replace(/ /g, "_")}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
        this.setState({ spinner: false });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ spinner: false });
      });
  };

  toggleView = () => {
    this.setState((prevState) => ({
      isChartView: !prevState.isChartView,
    }));
  };

  render() {

    const { AccountState, LandingState } = this.props;
    const listHotels =
      LandingState.landingdata &&
        LandingState.landingdata.data &&
        LandingState.landingdata.data.length > 1
        ? LandingState.landingdata.data[0]
        : [];
    const account = AccountState.account;
    var dtFormat =
      (account.dateformate != "" ? account.dateformate : "MM/DD/YYYY") +
      " " +
      (account.timeformat != "" ? account.timeformat : "hh:mm A");

    const { lowcount, mediumcount, highcount } = this.state;
    const options = {
      chart: {
        type: "bar",
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
        events: {
          mouseLeave: function (event, chartContext, config) {
            chartContext.resetSeries();
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true,
        },
      },
      colors: ["#FF0000", "#FFA500", "#008000"],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex].data[
            opts.dataPointIndex
          ];
        },
      },
      xaxis: {
        categories: ["High", "Medium", "Low"],
      },
      labels: ["High", "Medium", "Low"],
    };
    const series = [
      {
        name: "Priority",
        data: [highcount, mediumcount, lowcount],
      },
    ];
    const categories =
      this.state.categoryCount && this.state.categoryCount.length > 0
        ? this.state.categoryCount.map((item) => item.name)
        : [];
    const data =
      this.state.categoryCount && this.state.categoryCount.length > 0
        ? this.state.categoryCount.map((item) => item.count)
        : [];
    const options1 = {
      chart: {
        type: "bar",
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
        events: {
          mouseLeave: function (event, chartContext, config) {
            chartContext.resetSeries();
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true, // Ensures each bar has a different color
        },
      },
      colors: ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#FF8C33", "#33FFF1", "#FFD700", "#8A2BE2"],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex].data[
            opts.dataPointIndex
          ];
        },
      },
      xaxis: {
        categories: categories, // Use extracted categories here
      },
      labels: categories, // Use extracted categories here
      yaxis: {
        labels: {
          formatter: function (val) {
            return Math.floor(val); // Rounds down to the nearest integer
          },
        },
      },
    };

    const series1 = [{ name: "Category Count", data: data }];
    if (this.state.tickets) {
      this.state.tickets.forEach((object) => {
        object.dtFormat = dtFormat;
      });
    }
    if (this.state.modalType === "View") {
      const myObject = {
        ticketid: this.state.ticketid,
        hotel: this.state.hotel,
        status: this.state.status,
        group: this.state.group,
        fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : "",
        todate: this.state.todate ? this.state.todate + "T23:59:59" : "",
      };
      const objectString = objectToQueryString(myObject);
      return <Redirect to={"view-ticket/" + this.state.id + objectString} />;
    }
    const limitOptions = [
      { value: "25", label: "25" },
      { value: "50", label: "50" },
      { value: "100", label: "100" },
    ];

    const priorityList = [
      { value: "All", label: "All" },
      { value: "1", label: "High" },
      { value: "2", label: "Medium" },
      { value: "3", label: "Low" },
    ];
    const statusList = [
      { value: " ", label: "All" },
      { value: "open", label: "Open" },
      { value: "assigned", label: "Assigned" },
      { value: "inprogress", label: "Inprogress" },
      { value: "inreview", label: "Inreview" },
      { value: "completed", label: "Completed" },
      { value: "isDeleted", label: "Deleted" },
    ];
    const OrderByList = [
      { value: "All", label: "All" },
      { value: "priority", label: "Priority" },
      { value: "assignee", label: "Assignee" },
      { value: "category", label: "Issue Category" },
    ];
    // -----------------------------------------------------------------------------------------------
    var categoryOptions = [];
    const categoryList = this.state.categoryState;

    if (Array.isArray(categoryList) && categoryList.length > 0) {
      categoryList.forEach((item) => {
        if (item && !item.isdeleted && item.status) {
          const typeLabel = item.name || "Unknown"; // Use the name of the category or 'Unknown' if not defined
          const valueId = item._id || ""; // Use the _id as the value
          categoryOptions.push({
            label: typeLabel,
            value: valueId,
            name: "category",
          });
        }
      });
    }
    if (
      Array.isArray(categoryOptions) &&
      categoryOptions.length !== categoryList.length
    ) {
      categoryOptions.unshift({ label: "All", value: "", name: "category" });
    }
    if (Array.isArray(categoryOptions) && categoryOptions.length === 1) {
      categoryOptions = [];
    }

    var hotelOptions = [];

    if (listHotels.length > 0) {
      listHotels.map((item) => {
        if (this.state.groupoption && this.state.groupoption.value) {
          if (this.state.groupoption.value == item.group._id) {
            hotelOptions.push({
              label: `${item.name} ${item.city} ${item.state}`,
              value: item._id,
              logo: item.logo,
              name: "hotel",
            });
          }
        } else {
          hotelOptions.push({
            label: `${item.name} ${item.city} ${item.state}`,
            value: item._id,
            logo: item.logo,
            name: "hotel",
          });
        }
      });
    }
    if (hotelOptions.length != this.state.selectedOption.length) {
      hotelOptions.unshift({ label: "All", value: "", name: "hotel" });
    }
    if (hotelOptions.length == 1) {
      hotelOptions = [];
    }
    const listGroup =
      LandingState.landingdata &&
        LandingState.landingdata.data &&
        LandingState.landingdata.data.length > 1
        ? LandingState.landingdata.data[3]
        : [];
    const groupOptions = [];
    if (listGroup.length > 0) {
      groupOptions.push({ label: "All", value: "", name: "group" });
    }
    if (listGroup.length > 0) {
      listGroup &&
        listGroup
          ?.filter((filterItem) => filterItem.status === true)
          .map((item) => {
            groupOptions.push({
              label: item.name,
              value: item._id,
              name: "group",
            });
          });
    }

    const languageData = AccountState.account.SelectedlanguageData;
    const title = this.state.hotelName
      ? this.state.hotelName != "All"
        ? this.state.hotelName + " - Overall Maintenance Reports"
        : "Overall Maintenance Reports"
      : "Overall Maintenance Reports";
    return (
      <>
        <div>
          <div>
            <div className="breadcrump">
              <p>
                {" "}
                <Link to="/">
                  <h6>
                    {languageData && languageData.DASHBOARD
                      ? languageData.DASHBOARD
                      : "Dashboard"}
                  </h6>
                </Link>{" "}
                <span>
                  <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                </span>{" "}
                <Link to="/create-ticket-report">
                  <h6 className="highlights_breadcrump">
                    {" "}
                    {languageData && languageData.CREATE_TICKET_REPORTS
                      ? languageData.CREATE_TICKET_REPORTS
                      : "Created Ticket Report"}
                  </h6>
                </Link>
              </p>
            </div>

            <div className="section-body">
              <div className="container-fluid">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="Departments-list"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div
                        className="card-header res_tit_tick"
                        style={{ height: "65px" }}
                      >
                        <div className="d-flex">
                          <div className="rounded_icon">
                            <i className="fa fa-ticket mr-2 "></i>
                          </div>
                          <div className="d-flex align-items-center">
                            <h3 className="card-title">
                              {" "}
                              {languageData &&
                                languageData.CREATE_TICKET_REPORTS
                                ? languageData.CREATE_TICKET_REPORTS
                                : "Created Ticket Report"}
                            </h3>
                          </div>
                        </div>
                        <div className="card-options">
                          <button
                            className="btn topsocial_rest_btn btn-primarys"
                            type="button"
                            onClick={this.filterReset}
                            style={{
                              height: "45px !important",
                              width: "115px !important",
                              marginRight: "10px",
                              display: this.state.isFilterShow ? 'inline-block' : 'none'
                            }}
                          >
                            <i
                              className="fa fa-refresh"
                              aria-hidden="true"
                              style={{
                                fontSize: "20px !important",
                                marginRight: "7px",
                              }}
                            ></i>{" "}
                            Reset
                          </button>

                          {this.state.tickets.length > 0 ? (
                            <>
                              <button
                                className="btn btn-sm btn-default"
                                style={{ fontSize: "25px" }}
                                onClick={this.toggleView}
                              >
                                <span
                                  className={
                                    this.state.isChartView
                                      ? "fa fa-bar-chart"
                                      : "fe fe-grid"
                                  }
                                ></span>
                              </button>
                              {!this.state.isChartView && (
                                <>
                                  <a
                                    className="btn btn-primary btn-sm nav-link dropdown-toggle ticket_export"
                                    data-toggle="dropdown"
                                    role="button"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i
                                      className="fa fa-download mr-2"
                                      aria-hidden="true"
                                    ></i>
                                    Export
                                  </a>
                                  <div className="dropdown-menu">
                                    <a
                                      className="dropdown-item"
                                      type="button"
                                      onClick={this.exportToCSV}
                                    >
                                      <i className="dropdown-icon fa fa-file-excel-o"></i>{" "}
                                      Excel
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      type="button"
                                      onClick={this.handleExportPDF}
                                    >
                                      <i className="dropdown-icon fa fa-file-pdf-o"></i>{" "}
                                      PDF
                                    </a>
                                  </div></>)}
                            </>
                          ) : (
                            []
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          {!this.state.isChartView && (
                            <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3 mb-3 ">
                              <label className="form-label">
                                {languageData && languageData.LIMIT
                                  ? languageData.LIMIT
                                  : "Limit"}
                              </label>
                              <div className="form-group">
                                <Select
                                  onChange={this.onPerPageChange}
                                  options={limitOptions}
                                  defaultValue={limitOptions[0]}
                                  className="limit_size"
                                />
                              </div>
                            </div>)}
                          {account.allhotels &&
                            account.allhotels?.length > 0 ? (
                            ""
                          ) : (
                            <>
                              <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3 mb-3">
                                <label className="form-label">
                                  {languageData && languageData.GROUP
                                    ? languageData.GROUP
                                    : "Group"}
                                </label>
                                <div className="form-group">
                                  <Select
                                    value={
                                      this.state.groupoption
                                        ? {
                                          label: this.state.groupoption.label,
                                        }
                                        : ""
                                    }
                                    onChange={this.setGroup}
                                    options={groupOptions}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3 mb-3 ">
                                <label className="form-label">
                                  {languageData && languageData.HOTEL
                                    ? languageData.HOTEL
                                    : "Hotel"}
                                </label>
                                <div
                                  className="form-group"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Select
                                    isMulti={true}
                                    value={this.state.selectedOption}
                                    onChange={this.handleChange}
                                    options={hotelOptions}
                                    formatOptionLabel={(e) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {e.label != "All" && (
                                          <img
                                            className="all_search_image_size contain_image"
                                            src={ImportedURL.FILEURL + e.logo}
                                            onError={onErrorImage}
                                          />
                                        )}
                                        <div
                                          style={
                                            e.label != "All"
                                              ? { paddingLeft: "10px" }
                                              : {}
                                          }
                                        >
                                          <span className="all_search_text_size">
                                            {e.label}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    styles={{
                                      menuList: (provided, state) => ({
                                        ...provided,
                                        maxHeight: "200px", // Set the maximum height for the dropdown list
                                        overflowY: "auto", // Enable vertical scroll if needed
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                              {/* config */}
                            </>
                          )}
                          <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3 mb-3">
                            <label className="form-label">
                              {"Issue "}
                              {languageData && languageData.CATEGORY
                                ? languageData.CATEGORY
                                : "Category"}
                            </label>
                            <div
                              className="form-group"
                              style={{ marginBottom: "10px" }}
                            >
                              <Select
                                isMulti={true}
                                value={this.state.categoryOption}
                                onChange={this.handleCategoryChange}
                                options={categoryOptions}
                                formatOptionLabel={(e) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={
                                        e.label != "All"
                                          ? { paddingLeft: "10px" }
                                          : {}
                                      }
                                    >
                                      <span className="all_search_text_size">
                                        {e.label}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                styles={{
                                  menuList: (provided, state) => ({
                                    ...provided,
                                    maxHeight: "200px", // Set the maximum height for the dropdown list
                                    overflowY: "auto", // Enable vertical scroll if needed
                                  }),
                                }}
                              />
                            </div>
                          </div>
                          {/* config */}
                          <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3 mb-3  ">
                            <label className="form-label">
                              {languageData && languageData.Priority
                                ? languageData.Priority
                                : "Priority"}
                            </label>
                            <div
                              className="form-group"
                              style={{ marginBottom: "10px" }}
                            >
                              <Select
                                value={this.state.priorityValue}
                                onChange={this.setPriority}
                                options={priorityList}
                              />
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3 mb-3">
                            <label className="form-label">
                              {languageData && languageData.OrderBy
                                ? languageData.OrderBy
                                : "Order By"}
                            </label>
                            <div
                              className="form-group"
                              style={{ marginBottom: "10px" }}
                            >
                              <Select
                                value={this.state.OrderByoption}
                                onChange={this.setOrderBy}
                                options={OrderByList}
                              />
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3 mb-3">
                            <label className="form-label">
                              {languageData && languageData.Status
                                ? languageData.Status
                                : "Status"}
                            </label>
                            <div
                              className="form-group"
                              style={{ marginBottom: "10px" }}
                            >
                              <Select
                                value={this.state.statusoption}
                                onChange={this.setStatus}
                                options={statusList}
                              />
                            </div>
                          </div>

                          <>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3 mb-3">
                              <label className="form-label">From Date</label>
                              <div className="site_pass_calander">
                                <input
                                  type="date"
                                  className="form-control"
                                  name="fromdate"
                                  value={this.state.fromdateValue}
                                  onChange={this.setDateReport}
                                />
                                <i
                                  className="fa fa-calendar-check-o"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    opacity: "0.7",
                                  }}
                                ></i>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3 mb-3">
                              <label className="form-label">To Date</label>
                              <div className="site_pass_calander">
                                <input
                                  type="date"
                                  className="form-control removed-reset-clear"
                                  name="todate"
                                  min={this.state.fromdateValue || ""}
                                  value={this.state.todateValue}
                                  onChange={this.setDateReport}
                                />
                                <i
                                  className="fa fa-calendar-check-o"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    opacity: "0.7",
                                  }}
                                ></i>
                              </div>
                            </div>
                          </>

                          <div className="col-lg-3 col-md-4 col-sm-6 col-xl-3 mb-3">
                            <div className="form-group">
                              <label className="form-label">
                                Archived Reports By Months
                              </label>
                              <div className="selectgroup w-100">
                                <label
                                  className="selectgroup-item"
                                  style={{ zIndex: 0 }}
                                >
                                  <input
                                    type="radio"
                                    name="month"
                                    className="selectgroup-input"
                                    value={0}
                                    onChange={this.getMonthlyReport}
                                    checked={this.state.month === "0"}
                                  />
                                  <span className="selectgroup-button first_month">
                                    {this.getMonths(0)}
                                  </span>
                                </label>
                                <label
                                  className="selectgroup-item"
                                  style={{ zIndex: 0 }}
                                >
                                  <input
                                    type="radio"
                                    name="month"
                                    className="selectgroup-input"
                                    value={1}
                                    onChange={this.getMonthlyReport}
                                    checked={this.state.month === "1"}
                                  />
                                  <span className="selectgroup-button">
                                    {this.getMonths(1)}
                                  </span>
                                </label>
                                <label
                                  className="selectgroup-item"
                                  style={{ zIndex: 0 }}
                                >
                                  <input
                                    type="radio"
                                    name="month"
                                    className="selectgroup-input"
                                    value={2}
                                    onChange={this.getMonthlyReport}
                                    checked={this.state.month === "2"}
                                  />
                                  <span className="selectgroup-button last_month">
                                    {this.getMonths(2)}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6 col-sm-6 col-xl-4 mb-3">
                            <div className="form-group">
                              <label className="form-label">
                                Archived Reports By Days
                              </label>
                              <div className="selectgroup w-100">
                                <label
                                  className="selectgroup-item"
                                  style={{ zIndex: 0 }}
                                >
                                  <input
                                    type="radio"
                                    name="day"
                                    className="selectgroup-input"
                                    value="yesterday"
                                    onChange={this.getselecteddayReport}
                                    checked={this.state.day === "yesterday"}
                                  />
                                  <span className="selectgroup-button first_month">
                                    {this.getYesterday()}
                                  </span>
                                </label>
                                <label
                                  className="selectgroup-item"
                                  style={{ zIndex: 0 }}
                                >
                                  <input
                                    type="radio"
                                    name="day"
                                    className="selectgroup-input"
                                    value="7days"
                                    onChange={this.getselecteddayReport}
                                    checked={this.state.day === "7days"}
                                  />
                                  <span className="selectgroup-button">
                                    {this.get7DaysAgo()}
                                  </span>
                                </label>
                                <label
                                  className="selectgroup-item"
                                  style={{ zIndex: 0 }}
                                >
                                  <input
                                    type="radio"
                                    name="day"
                                    className="selectgroup-input"
                                    value="30days"
                                    onChange={this.getselecteddayReport}
                                    checked={this.state.day === "30days"}
                                  />
                                  <span className="selectgroup-button last_month">
                                    {this.get30DaysAgo()}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {!this.state.isChartView && (
                          <div className="Over_all_table_style table-responsive">
                            <div className="ag-theme-alpine">
                              <AgGridReact
                                key={this.state.perPage}
                                rowHeight={82}
                                paginationPageSize={this.state.perPage}
                                cacheBlockSize={this.state.perPage}
                                pagination={true}
                                //  onRowClicked={this.onRowClicked}
                                domLayout={"autoHeight"}
                                defaultColDef={this.state.defaultColumDef}
                                columnDefs={this.state.columnDefs}
                                rowDragManaged={true}
                                animateRows={true}
                                onGridReady={this.onGridReady}
                                overlayNoRowsTemplate={"No rows to display"}
                                rowModelType={"infinite"}
                              ></AgGridReact>
                            </div>
                            {this.state.tickets &&
                              this.state.tickets.length === 0 && (
                                <span className="ag_grid_no_record_show_span">
                                  No rows to display
                                </span>
                              )}
                            {this.state.spinner && (
                              <div className="common_loader">
                                <img
                                  className="loader_img_style_common"
                                  src="../../assets/images/load.png"
                                />
                                <Spinner
                                  className="spinner_load_common"
                                  animation="border"
                                  variant="info"
                                ></Spinner>
                              </div>
                            )}
                            {this.state.zipspinner && (
                              <div
                                className="common_loader"
                                style={{
                                  position: "fixed",
                                  left: "55%",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  className="loader_img_style_common"
                                  src="../../assets/images/load.png"
                                />
                                <Spinner
                                  className="spinner_load_common"
                                  animation="border"
                                  variant="info"
                                ></Spinner>
                                <p className="pt-2">
                                  Please wait while we are downloading your
                                  file. It might take some time.
                                </p>
                              </div>
                            )}
                          </div>
                        )}

                        <div>
                          <div className="row">
                            {!this.state.isChartView && (
                              <>
                                <h2 className="card-title mt-20">
                                  Summary Report
                                </h2>
                              </>
                            )}
                            {this.props.AccountState.account.role === 'admin' && (

                              <div className="col-xl-8 col-lg-8 col-md-8 mt-10">
                                <div className="card report_table_open_count">
                                  <div className="row text-center">
                                    <div className="Over_all_table_style table-responsive">
                                      <h6 className="tx-medium text-left">
                                        Hotel Wise Ticket Count
                                        <span
                                          style={{ cursor: "pointer" }}
                                        ></span>
                                      </h6>

                                      <table className="table table-striped table-vcenter mb-0">
                                        <thead>
                                          <tr>
                                            <th className="tx-medium text-left">
                                              Hotel
                                            </th>
                                            <th className="text-right">
                                              Ticket Count
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.hotelCounts.map(
                                            (hotel, index) => (
                                              <tr key={index}>
                                                <td className="tx-medium text-left">
                                                  {hotel.name}
                                                </td>
                                                <td className="text-right">
                                                  {hotel.count}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                          <tr>
                                            <td className="tx-medium text-left">
                                              Total
                                            </td>
                                            <td className="text-right">
                                              {this.state.hotelCounts.reduce(
                                                (acc, hotel) => acc + hotel.count,
                                                0
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            )}
                            <div className="col-xl-5 col-lg-5 col-md-5 mt-10">
                              <div className="card report_table_open_count">
                                <div className="row text-center">
                                  <div className="Over_all_table_style table-responsive">
                                    <h6 className="tx-medium text-left">
                                      Priority Wise Ticket Count
                                      <span
                                        style={{ cursor: "pointer" }}
                                      ></span>
                                    </h6>

                                    <table className="table table-striped table-vcenter mb-0">
                                      <thead>
                                        <tr>
                                          <th className="tx-medium text-left">
                                            Priority
                                          </th>
                                          <th className="text-right">
                                            Ticket Count
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="tx-medium text-left">
                                            High
                                          </td>
                                          <td className="text-right">
                                            {highcount}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="tx-medium text-left">
                                            Medium
                                          </td>
                                          <td className="text-right">
                                            {mediumcount}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="tx-medium text-left">
                                            Low
                                          </td>
                                          <td className="text-right">
                                            {lowcount}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="tx-medium text-left">
                                            Total
                                          </td>
                                          <td className="text-right">
                                            {highcount + mediumcount + lowcount}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {this.state.isChartView && (
                              <div className="col-xl-6 col-lg-6 col-md-6 mt-10">
                                <div className="card">
                                  <div className="row">
                                    <div className="col-sm-12 pb-4 pt-1">
                                      <Chart
                                        options={options}
                                        series={series}
                                        type="bar"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-xl-5 col-lg-5 col-md-5 mt-10">
                              <div className="card report_table_open_count">
                                <div className="row text-center">
                                  <div className="Over_all_table_style table-responsive">
                                    <h6 className="tx-medium text-left">
                                      Issue Category Wise Ticket Count
                                      <span
                                        style={{ cursor: "pointer" }}
                                      ></span>
                                    </h6>

                                    <table className="table table-striped table-vcenter mb-0">
                                      <thead>
                                        <tr>
                                          <th className="tx-medium text-left">
                                            Category
                                          </th>
                                          <th className="text-right">
                                            Ticket Count
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.categoryCount &&
                                          this.state.categoryCount.length > 0 ? (
                                          <>
                                            {this.state.categoryCount.map(
                                              (item, index) => (
                                                <tr key={index}>
                                                  <td className="tx-medium text-left">
                                                    {item.name}
                                                  </td>
                                                  <td className="text-right">
                                                    {item.count}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <tr>
                                          <td className="tx-medium text-left">
                                            Total
                                          </td>
                                          <td className="text-right">
                                            {this.state.categoryCount.reduce(
                                              (total, item) =>
                                                total + item.count,
                                              0
                                            )}
                                          </td>
                                        </tr>

                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {this.state.isChartView && (
                              <div className="col-xl-6 col-lg-6 col-md-6 mt-10">
                                <div className="card">
                                  <div className="row">
                                    <div className="col-sm-12 pb-4 pt-1">
                                      <Chart
                                        options={options1}
                                        series={series1}
                                        type="bar"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="overlay"
                style={{
                  display:
                    this.state.spinner || this.state.zipspinner
                      ? "block"
                      : "none",
                }}
              ></div>
              {(this.state.spinner && this.state.isChartView) && (
                <div className="common_loader">
                  <img
                    className="loader_img_style_common"
                    src="../../assets/images/load.png"
                  />
                  <Spinner
                    className="spinner_load_common"
                    animation="border"
                    variant="info"
                  ></Spinner>
                </div>
              )}
            </div>
          </div>

          <Ticketpdf
            data={this.state.tickets}
            title={title}
            dtFormat={dtFormat}
            role={account.role}
          />

        </div>

      </>
    );
  }
}
const mapStateToProps = (state) => ({
  AccountState: state.account,
  LandingState: state.landing,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ListGroup: AC_LIST_GROUP, ListHotel: AC_LIST_HOTEL },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportCreateTicket);
