import axios from 'axios';
import moment from 'moment';
import React from 'react';
import Chart from 'react-apexcharts';
import ImportedURL from '../../common/api';
import { AgGridReact } from 'ag-grid-react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Filter from './filter';
import { Link, Redirect } from 'react-router-dom';
import Ticketpdfanalytics from './ticketpdf';
import Select from 'react-select';
import { imageCellRendererList, objectToQueryString } from '../../common/validate';

const getTicket = (value) => {
    if (value == 'open') {
        return "Open";
    } else if (value === 'inprogress') {
        return "Inprogress";
    } else if (value === 'reopened') {
        return "Reopened";
    } else if (value === 'assigned') {
        return "Assigned";
    } else if (value === 'inreview') {
        return "Inreview";
    } else if (value === 'closed') {
        return "Closed";
    } else if (value === 'completed') {
        return "Completed";
    } else if (value === 'Deleted') {
        return "Deleted";
    } else {
        return value;
    }
}
class TimeSeriesChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            columnDefs: [
                { headerName: 'Ticket #', field: 'ticketid', width: 220, floatingFilter: true, cellStyle: { 'text-align': 'center' } },
                { headerName: 'Problem', field: 'title', width: 220, floatingFilter: true, cellStyle: { 'text-align': 'center' } },
                {
                    headerName: 'Description', field: 'description', width: 200, floatingFilter: true,
                    valueGetter: function (params) {
                        if (params.data && params.data.description) {
                            return params.data.description
                        } else if (params.data && params.data.description == "") {
                            return "---"
                        }
                    }
                },
                {
                    headerName: 'Room #', field: 'roomno', width: 150, floatingFilter: true, cellStyle: { 'text-align': 'center' },
                    valueGetter: function (params) {
                        if (params.data && params.data.roomno) {
                            return params.data.roomno
                        } else if (params.data && params.data.roomno == "") {
                            return "Other place"
                        }
                    }
                },
                {
                    headerName: 'Logo', width: 50, field: 'logo', hide: true, sortable: false, filter: false, "resizable": false,
                    cellRenderer: imageCellRendererList,
                },
                { headerName: 'Hotel', field: 'hotel', width: 250, hide: true, },
                { headerName: 'Reporter', field: 'reporter', width: 120 },
                { headerName: 'Assignee', field: 'assigneename', width: 120 },
                {
                    headerName: 'Status', width: 250, field: 'status', cellStyle: { 'text-align': 'center' }, suppressMenu: true,
                    valueGetter: function (params) {
                        return getTicket(params.data ? params.data.status : "");
                    },
                    cellRenderer: function (params) {
                        if (params.value == 'Open') {
                            return `<span class="tag ticket_open">Open</span>`;
                        } else if (params.value === 'Inprogress') {
                            return '<span class="tag ticket_inprogress">Inprogress</span>';
                        } else if (params.value === 'Reopened') {
                            return '<span class="tag ticket_reopened">Reopened</span>';
                        } else if (params.value === 'Assigned') {
                            return '<span class="tag ticket_assigned">Assigned</span>';
                        } else if (params.value === 'Inreview') {
                            return '<span class="tag ticket_inreview">Inreview</span>';
                        } else if (params.value === 'Closed') {
                            return '<span class="tag ticket_closed">Closed</span>';
                        } else if (params.value === 'Completed') {
                            return '<span class="tag ticket_completed">Completed</span>';
                        } else if (params.value === 'Deleted') {
                            return '<span class="tag ticket_deleted">Deleted</span>';
                        } else {
                            return `<span class="tag default">${params.value}</span>`;
                        }
                    }
                },
                {
                    headerName: 'Date', field: 'time', width: 150, cellStyle: { 'text-align': 'center' },
                    cellRenderer: function (params) {
                        return params.value ? `<span>` + moment(params.data?.time).format(params.data?.dtFormat) + `</span>` : ''
                    }
                },
            ],
            tickets: [],
            defaultColumDef: {
                editable: false,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
                minWidth: 100,
            },


            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
            options: {
                chart: {
                    type: 'bar',
                    id: "basic-bar",
                    height: 350,
                    zoom: false,
                    selection: {
                        enabled: false
                    },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            const clickedIndex = config.dataPointIndex;
                            this.handleBarClick(clickedIndex);
                        },
                        mouseLeave: function (event, chartContext, config) {
                            chartContext.resetSeries()
                        }
                    },
                },
                colors: ['#1c3078'],
                xaxis: {
                    type: 'datetime',
                    tickAmount: 30,
                    min: '',
                    max: '',
                    labels: {
                        datetimeUTC: true, // Set to true if your data is in UTC
                        format: 'dd' // Customize the date format as needed
                    }
                },
                yaxis: {
                    min: 0,
                    max: 100, // or any other maximum value you prefer
                    type: 'numeric',
                },
            },
            series: [
                {
                    name: 'Total Tickets',
                    data: [], // Leave this empty initially, data will be fetched asynchronously
                },
            ],
            spinner: false,
            columnreset: true,
            barClicked: false,
            count: 0,
            perPage: 25
        };
    }
    handleBarClick = (clickedIndex) => {
        if (this.state.clickedBarIndex == clickedIndex) {
            this.setState({ clickedBarIndex: null });
            this.myFunction(null); // Sending empty value
        } else {
            this.setState({ clickedBarIndex: clickedIndex });
            this.myFunction(clickedIndex);
        }
    }


    myFunction = (clickedIndex) => {
        const { user, category, hotel, group, status, currentMonth, currentYear, priority, roomno } = this.state

        if (clickedIndex) {
            const start = new Date(currentYear, currentMonth, clickedIndex)
            var startDate = moment(start).format('YYYY-MM-DD') + "T00:00:00"

            const end = new Date(currentYear, currentMonth, clickedIndex);
            var endDate = moment(end).format('YYYY-MM-DD') + "T23:59:59"
            this.setState({ startDate: startDate, endDate: endDate, singledate: true, isFilterShow: true })
            const dataSource = this.getDataSource({ hotel: hotel, group: group, category: category, user: user, status: status, startDate: startDate, priority: priority, roomno: roomno, endDate: endDate, singledate: true })
            this.gridApi.setDatasource(dataSource);
        } else {
            this.setState({ startDate: '', endDate: '', singledate: false, isFilterShow: false })
            const dataSource = this.getDataSource({ hotel: hotel, group: group, category: category, user: user, status: status, priority: priority, roomno: roomno })
            this.gridApi.setDatasource(dataSource);
        }
    }
    chartReset = () => {
        this.handleBarClick(this.state.clickedBarIndex);
    }


    handlePrevMonth = async () => {
        const { currentMonth, currentYear } = this.state;
        const newMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const newYear = currentMonth === 0 ? currentYear - 1 : currentYear;

        this.setState({  // Notice the "await" here
            currentMonth: newMonth,
            currentYear: newYear,
            spinner: true
        });
        this.handleBarClick(this.state.clickedBarIndex);
    }

    handleNextMonth = async () => {
        const { currentMonth, currentYear } = this.state;
        const newMonth = currentMonth === 11 ? 0 : currentMonth + 1;
        const newYear = currentMonth === 11 ? currentYear + 1 : currentYear;
        this.setState({  // Notice the "await" here
            currentMonth: newMonth,
            currentYear: newYear,
            spinner: true
        });
        this.handleBarClick(this.state.clickedBarIndex);
    }
    // -----------------------------------listticket copy------------------------------
    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {
                // Grid params for filter and sort
                const account = this.props.AccountState.account;
                let page = ''
                if (query.limitchange) {
                    page = Math.floor(params.startRow / query.limitchange);
                } else {
                    page = Math.floor(params.startRow / this.state.perPage);
                }
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                this.setState({ spinner: true });
                var tzone = 'America/chicago'
                if (account != undefined && account && account.tzone != undefined && account.tzone) {
                    tzone = account.tzone
                }
                // ------------------------bar chart---start---------
                const { currentYear, currentMonth } = this.state;

                const startDate = new Date(currentYear, currentMonth, 1);
                const endDate = new Date(currentYear, currentMonth + 1, 0);

                const minDate = new Date(currentYear, currentMonth, 1).getTime();
                const maxDate = new Date(currentYear, currentMonth, new Date(currentYear, currentMonth + 1, 0).getDate()).getTime();
                if (!this.state.singledate) {
                    let temps = moment(startDate).format('YYYY-MM-DD') + "T00:00:00"
                    let tempe = moment(endDate).format('YYYY-MM-DD') + "T23:59:59"
                    this.setState({ startDate: temps, endDate: tempe })
                    query["startDate"] = temps
                    query["endDate"] = tempe
                }
                // ---------------------------------bar chart---end---------------------
                const { user, category, hotel, group, status, priority, roomno, singledate } = this.state
                axios.post(ImportedURL.API.ticketsDateCount, { hotel: hotel, group: group, category: category, user: user, status: status, startDate: this.state.startDate, priority: priority, roomno: roomno, endDate: this.state.endDate, singledate: singledate, perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {}, ...query })
                    .then((res) => {
                        if (res.data) {
                            res.data.data.forEach(object => {
                                object.actions = `<div><button type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-eye" style='color: #1DC9B7 !important' data-action-type="View"></i></button></div>`;
                                object.tzone = tzone
                            });

                            this.setState({ tickets: res.data.data, originalData: res.data.data });
                            const lbLastRowOnPageEl = document.querySelector(`[ref=\'lbLastRowOnPage\']`);
                            if (lbLastRowOnPageEl) {
                                lbLastRowOnPageEl.innerHTML = (res.data && res.data.data) ? res.data.data.length + params.startRow : 0;
                            }
                            params.successCallback(res.data.data, res.data.total);

                            // ----------------------------------bar chart---start------------------
                            const dateCounts = res.data.dateCounts;
                            let highestCountDate = null;
                            let highestCount = -1;

                            for (const date in dateCounts) {
                                const count = dateCounts[date];
                                if (count > highestCount) {
                                    highestCount = count;
                                    highestCountDate = date;
                                }
                            }

                            this.setState({ count: highestCount })
                            if (dateCounts) {
                                const numberOfDays = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
                                const data = [];
                                for (let i = 0; i <= numberOfDays; i++) {
                                    const date = this.addDays(startDate, i);
                                    const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
                                    const count = dateCounts[formattedDate] || 0;
                                    data.push({
                                        x: formattedDate, // Use formatted date string for x-axis
                                        y: count
                                    });
                                }
                                this.setState(prevState => ({
                                    options: {
                                        ...prevState.options,
                                        xaxis: {
                                            ...prevState.options.xaxis,
                                            type: 'datetime',
                                            min: minDate,
                                            max: maxDate,
                                        },
                                        tooltip: {
                                            x: {
                                                format: 'dd MMM yyyy' // Tooltip date format
                                            }
                                        },
                                        yaxis: {
                                            max: highestCount + 10
                                        }
                                    },
                                    series: [{ ...prevState.series[0], data }],
                                }));
                                this.setState({ spinner: false })
                                // ----------------------------bar chart---end-------------------------------------------
                            } else {
                                this.setState({ spinner: false })
                            }
                        }
                    }).catch(({ response }) => {
                        params.successCallback([], 0);
                        this.setState({ spinner: false })
                    })
            }
        }
    }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    componentDidUpdate(prevProps, prevState) {
        const { AccountState } = this.props;
        const role = AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role) && this.state.columnreset) {
            this.UpdateColumnDefs();
            this.setState({ columnreset: false })
        }

        if (this.gridApi) {
            if (AccountState && prevState.gridApi !== this.state.gridApi) {
                this.getTickets();
            }
        }

        // if (prevState.perPage !== this.state.perPage) {
        //     this.refreshGridDataSource();
        // }
    }

    searchUrlParams = () => {
        const searchParams = new URLSearchParams(window.location.search.substring(1));
        const fromdate = searchParams.get('startDate') ? decodeURIComponent(searchParams.get('startDate')) : '';
        const todate = searchParams.get('endDate') ? decodeURIComponent(searchParams.get('endDate')) : '';
        const hotel = searchParams.get('hotel') ? decodeURIComponent(searchParams.get('hotel')) : '';
        const status = searchParams.get('status') ? decodeURIComponent(searchParams.get('status')) : '';
        const group = searchParams.get('group') ? decodeURIComponent(searchParams.get('group')) : '';
        const category = searchParams.get('category') ? decodeURIComponent(searchParams.get('category')) : '';
        const user = searchParams.get('user') ? decodeURIComponent(searchParams.get('user')) : '';
        const priority = searchParams.get('priority') ? decodeURIComponent(searchParams.get('priority')) : '';
        const roomno = searchParams.get('roomno') ? decodeURIComponent(searchParams.get('roomno')) : '';
        const clickedBarIndex = searchParams.get('clickedBarIndex') ? decodeURIComponent(searchParams.get('clickedBarIndex')) : '';
        let params = {}
        if (fromdate) {
            params["startDate"] = fromdate
            this.setState({ startDate: fromdate, singledate: true })
        }
        if (hotel) {
            params["hotel"] = hotel
            this.setState({ hotel: hotel })
        }
        if (todate) {
            params["endDate"] = todate
            this.setState({ endDate: todate, singledate: true })
        }
        if (status) {
            params["status"] = status
            this.setState({ status: status })

        }
        if (group) {
            params["group"] = group
            this.setState({ group: group })
        }
        if (category) {
            params["category"] = category
            this.setState({ category: category })
        }
        if (user) {
            params["user"] = user
            this.setState({ user: user })
        }
        if (priority) {
            params["priority"] = priority
            this.setState({ priority: priority })
        }
        if (roomno) {
            params["roomno"] = roomno
            this.setState({ roomno: roomno })
        }
        if (fromdate && todate) {
            if (moment(fromdate).format('YYYY-MM-DD') == moment(todate).format('YYYY-MM-DD')) {
                this.setState({ isFilterShow: true })
            }
        }
        if (clickedBarIndex) {
            this.setState({ clickedBarIndex: clickedBarIndex })
        }
        return params;
    }
    componentDidMount() {
        const { AccountState } = this.props;
        const account = AccountState.account;
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    exportToCSV = () => {
        const account = this.props.AccountState.account;
        const dtFormat = (account.dateformate !== '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat !== '' ? account.timeformat : 'hh:mm A');
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['ticketid', 'title', 'description', 'hotel', 'roomno', 'reporter', 'assigneename', 'status', 'time'],
            fileName: "Ticket Analytics" + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                if (params && params.column && params.column.colId === 'time') {
                    return moment(params.value).format(dtFormat);
                } else {
                    return params.value;
                }
            }
        })
    }

    exportPDF = () => {
        const account = this.props.AccountState.account;
        const dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")

        const selectedProperties = this.state.hotelName ? ['ticketid', 'title', 'description', 'roomno', 'reporter', 'assigneename', 'status', 'time'] : ['ticketid', 'hotel', 'title', 'description', 'roomno', 'reporter', 'assigneename', 'status', 'time'];

        const selectedTickets = this.state.tickets.map(ticket => {
            const selectedTicket = {};
            selectedProperties.forEach(property => {
                if (ticket.hasOwnProperty(property)) {
                    if (property == 'time') {
                        selectedTicket[property] = moment(ticket[property]).format(dtFormat)
                    } else if (property == "status") {
                        selectedTicket[property] = ticket[property] ? getTicket(ticket[property]) : "---"
                    } else if (property == "roomno") {
                        selectedTicket[property] = ticket[property] ? ticket[property] : "Other Place"
                    } else if (property == "description") {
                        selectedTicket[property] = ticket[property] ? ticket[property] : "---"
                    } else if (property == "assigneename") {
                        selectedTicket[property] = ticket[property] ? ticket[property] : "---"
                    } else {
                        selectedTicket[property] = ticket[property];
                    }
                }
            });
            return selectedTicket;
        });
        let data = {
            title: (this.state.hotelName) ? (this.state.hotelName != "All" ? this.state.hotelName + " - Ticket Analytics" : "Ticket Analytics") : "Ticket Analytics",
            head: this.state.hotelName ? ["#", "Ticket #", "Problem", "Description", "Room #", "Reported By", "Assignee", "Status", "Date"] : ["#", "Ticket #", "Hotel", "Problem", "Description", "Room #", "Reported By", "Assignee", "Status", "Date"],
            body: selectedTickets,
            hotelNameExport: this.state.hotelName ? account.hotelNameExport : '',
            hoteladdressExport: this.state.hotelName ? account.hoteladdressExport : '',
            limit: this.state.perPage,
            ticketmanagement: true,
            hotelWidth: true
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Ticket-Analytics" + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }


    getTickets = () => {
        const searchParams = new URLSearchParams(window.location.search.substring(1));
        let params = this.searchUrlParams()
        if (performance.navigation.type === 1) {
            // This is a page reload, set the URL
            if (window.history.replaceState) {
                window.history.replaceState({}, '', '/ticket-analytics');
            }
        }
        if (params.startDate || params.endDate) {
            let temp1 = new Date(params.startDate).getMonth()
            let temp2 = new Date(params.startDate).getFullYear()
            this.setState({ currentMonth: temp1, currentYear: temp2 })
        }

        if (searchParams && searchParams.size > 0) {
            const dataSource = this.getDataSource(params)
            this.gridApi.setDatasource(dataSource);
        } else {
            const dataSource = this.getDataSource()
            this.gridApi.setDatasource(dataSource);
        }
    }

    onRowClicked = event => {
        this.setState({ modalType: "View", id: event.data.ticketid });
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };

    onPerPageChange = e => {
        this.setState({ perPage: e }, () => {
            if (this.gridApi) {
                this.gridApi.paginationSetPageSize(parseInt(e, 10));
                const dataSource = this.getDataSource({ limitchange: e })
                this.gridApi.setDatasource(dataSource);
            }
        });
    };

    UpdateColumnDefs = () => {
        const role = this.props.AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role)) {
            let updatedColumnDefs = [...this.state.columnDefs];
            updatedColumnDefs = updatedColumnDefs.map((col) => {
                if (col.field === 'hotel' || col.field === 'logo') {
                    return { ...col, hide: false };
                }
                return col;
            });
            this.gridApi.setColumnDefs(updatedColumnDefs);
        }
    }

    // -------------------------------------filter-------------
    changeModal = e => {
        this.setState({ showCanvas: true })
    }
    passData = e => {
        const { groupoption, hoteloption, useroption, user, category, categoryoption, hotel, group, status, statusoption, priority, priorityoption, roomno, startDate, endDate } = this.state
        this.setState({ showCanvas: false, hotelName: hoteloption ? hoteloption.label : '' })
        if (this.state.singledate) {
            const dataSource = this.getDataSource({ hotel: hotel, group: group, category: category, user: user, status: status, priority: priority, roomno: roomno, endDate: endDate, startDate: startDate, })
            this.gridApi.setDatasource(dataSource);
        } else {
            const dataSource = this.getDataSource({ hotel: hotel, group: group, category: category, user: user, status: status, priority: priority, roomno: roomno })
            this.gridApi.setDatasource(dataSource);
        }
    }
    handlegrouphotel = e => {
        if (e.hoteloption) {
            this.setState({ hoteloption: e.hoteloption })
        }
        if (e.groupoption) {
            this.setState({ groupoption: e.groupoption })
        }
        if (e.group) {
            this.setState({ group: e.group })
        }
        if (e.hotel) {
            this.setState({ hotel: e.hotel })
        }
        if (e.category) {
            this.setState({ category: e.category })
        }
        if (e.categoryAll) {
            this.setState({ category: '' })
        }
        if (e.groupAll) {
            this.setState({ group: '' })
        }
        if (e.hotelAll) {
            this.setState({ hotel: '' })
        }
        if (e.userAll) {
            this.setState({ user: '' })
        }
        if (e.statusAll) {
            this.setState({ status: '' })
        }
        if (e.priorityAll) {
            this.setState({ priority: '' })
        }
        if (e.roomnoAll) {
            this.setState({ roomno: '' })
        }
        if (e.categoryoption) {
            this.setState({ categoryoption: e.categoryoption })
        }
        if (e.user) {
            this.setState({ user: e.user })
        }
        if (e.useroption) {
            this.setState({ useroption: e.useroption })
        }
        if (e.statusoption) {
            this.setState({ statusoption: e.statusoption })
        }
        if (e.status) {
            this.setState({ status: e.status })
        }
        if (e.priorityoption) {
            this.setState({ priorityoption: e.priorityoption })
        }
        if (e.priority) {
            this.setState({ priority: e.priority })
        }
        if (e.roomnooption) {
            this.setState({ roomnooption: e.roomnooption })
        }
        if (e.roomno) {
            this.setState({ roomno: e.roomno })
        }
    }
    reset = e => {
        if (e.group) {
            this.setState({ hotel: '', hoteloption: '', category: '', categoryoption: '', useroption: '', user: '', roomno: '', roomnooption: '' })
        }
        if (e.hotel) {
            this.setState({ category: '', categoryoption: '', useroption: '', user: '', roomno: '', roomnooption: '' })
        }
        if (e.category) {
            this.setState({ useroption: '', user: '' })
        }
    }
    selfreset = e => {
        if (e == 'hotel') this.setState({ hotel: '', hoteloption: '', category: '', categoryoption: '', useroption: '', user: '', roomno: '', roomnooption: '' })
        if (e == 'group') this.setState({ group: '', groupoption: '', hotel: '', hoteloption: '', category: '', categoryoption: '', useroption: '', user: '', roomno: '', roomnooption: '' })
        if (e == 'roomno') this.setState({ roomno: '', roomnooption: '' })
        if (e == 'priority') this.setState({ priority: '', priorityoption: '' })
        if (e == 'status') this.setState({ status: '', statusoption: '' })
        if (e == 'user') this.setState({ user: '', useroption: '' })
        if (e == 'category') this.setState({ category: '', categoryoption: '', user: '', useroption: '' })
        if (e == 'all') this.setState({
            hotel: '', hoteloption: '', category: '', categoryoption: '', useroption: '', user: '', roomno: '', roomnooption: '',
            priority: '', priorityoption: '', status: '', statusoption: '', group: '', groupoption: ''
        })
    }
    render() {
        const { currentMonth, currentYear } = this.state;
        const account = this.props.AccountState.account;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        var dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")

        if (this.state.tickets) {
            this.state.tickets.forEach(object => {
                object.dtFormat = dtFormat;
            });
        }

        if (this.state.modalType === 'View') {
            const { user, category, hotel, group, status, priority, roomno, startDate, endDate, clickedBarIndex } = this.state
            let myObject = {
                hotel: hotel, group: group, category: category, user: user, status: status, priority: priority, roomno: roomno, endDate: endDate, startDate: startDate, clickedBarIndex: clickedBarIndex
            }
            const objectString = objectToQueryString(myObject)
            return <Redirect to={'view-ticket-analytics/' + this.state.id + objectString} />
        }
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const title = (this.state.hotelName) ? (this.state.hotelName != "All" ? this.state.hotelName + " - Ticket Analytics" : "Ticket Analytics") : "Ticket Analytics";
        let hideFutureMonth = false;
        if (new Date().getMonth() == currentMonth && new Date().getFullYear() == currentYear) {
            hideFutureMonth = true
        } else {
            hideFutureMonth = false
        }
        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <Link to="/ticket-analytics"><h6 className="highlights_breadcrump"> Ticket Analytics</h6></Link></p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid ticket_analytics">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card calendar_card_boday">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-database mr-2 "></i></div>
                                            <h3 className="card-title"> Ticket Analytics</h3>
                                            <div className="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab">
                                                    </ul>
                                                    <div className="header-action">
                                                        <button type="button" className="btn button_auto_front_desk" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={this.changeModal}><i className="fa fa-filter mr-2"></i>Filter</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (this.state.showCanvas)
                                            &&
                                            <Filter showCanvas={this.state.showCanvas} temp={this.state} save={(e) => this.passData(e)} handlegrouphotel={(e) => this.handlegrouphotel(e)} reset={(e) => this.reset(e)} selfreset={(e) => this.selfreset(e)} onHide={(e) => this.setState({ showCanvas: false })} />
                                        }
                                        {this.state.count > 0 ?
                                            <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
                                            : <div className="card-body no_records text-center d-flex align-items-center justify-content-center pt-0">
                                                <div style={{ maxWidth: "340px" }}>
                                                    <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid" style={{ maxWidth: "272px" }} />
                                                    <h5 className="mb-2">No records to display </h5>
                                                </div>
                                            </div>
                                        }
                                        <div className={this.state.tickets.length > 0 ? "card-header pt-0 pb-0 ticket_analytic_header d-flex justify-content-space-between" : "card-header pt-0 pb-0"}>
                                            <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2 ">
                                                <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                <div className="form-group">
                                                    <Select
                                                        onChange={(e) => { this.onPerPageChange(Number(e.value)) }}
                                                        // onChange={(e) => {  }}

                                                        options={limitOptions}
                                                        defaultValue={limitOptions[0]}
                                                        className='limit_size'
                                                    />
                                                </div>
                                            </div>
                                            <div className="calendar_year_tickets" style={this.state.tickets && this.state.tickets.length == 0 ? { marginLeft: "197px" } : {}}>
                                                <div className='tickets_year_calendar_left_side_harrow' onClick={this.handlePrevMonth}>
                                                    <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                                                </div>
                                                <button className='btn topsocial_rest_btn btn-primarys tickets_month' type='button'>
                                                    <span>{new Date(currentYear, currentMonth).toLocaleString('default', { month: 'short', year: 'numeric' })}</span>
                                                </button>
                                                <div className='tickets_year_calendar_right_side_harrow' style={hideFutureMonth ? { cursor: 'default', backgroundColor: '#B7B0B0' } : {}} onClick={hideFutureMonth ? undefined : this.handleNextMonth}>
                                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="card-options ticket_month_skip">
                                                {this.state.isFilterShow &&
                                                    <button className='btn topsocial_rest_btn btn-primarys ticketfilter' type='button' onClick={this.chartReset} style={{ height: '45px !important', width: '115pxpx !important' }}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                }
                                                <div>
                                                    {this.state.tickets && this.state.tickets.length > 0 ?
                                                        <>
                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle ticket_export" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                            </div>
                                                        </> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="Over_all_table_style table-responsive">
                                                <div className="ag-theme-alpine ticket_aggridrow">
                                                    <AgGridReact
                                                        key={this.state.perPage}
                                                        rowHeight={82}
                                                        paginationPageSize={this.state.perPage}
                                                        cacheBlockSize={this.state.perPage}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        animateRows={true}
                                                        onGridReady={this.onGridReady}
                                                        overlayNoRowsTemplate={"No rows to display"}
                                                        rowModelType={'infinite'}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                                {
                                                    (this.state.tickets && this.state.tickets.length == 0)
                                                    &&
                                                    <span className='ag_grid_no_record_show_span' >No rows to display</span>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                    {
                        this.state.spinner ?
                            <div className='common_loader_ticket'>
                                <img className='loader_img_style_common' src='../../assets/images/load.png' />
                                <Spinner className='spinner_load_common' animation="border" variant="info" >
                                </Spinner>
                            </div> : ""
                    }
                    < div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }
                    }></div >
                    <Ticketpdfanalytics data={this.state.tickets} title={title} dtFormat={dtFormat} role={account.role} />
                </div >
            </>
        );
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    LandingState: state.landing,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(TimeSeriesChart);