import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { bindActionCreators } from "redux";
import Select from 'react-select';
import {
  AC_CHANGE_PREVILEGE,
  AC_EMPTY_USER,
  AC_HANDLE_USER_CHANGE,
  AC_LIST_USER,
  AC_LIST_USER_SEND_DATA,
  AC_SET_ALL_PREVILEGES,
  AC_VIEW_USER
} from "../../actions/userAction";
import CONSTANTS from "../../common/constants";
import ImportedURL from "../../common/api";
import { Error, Success } from "../../common/swal";
import axios from "axios";
import { Phonenumber, Emailvalidate, onErrorImage } from "../../common/validate";
import Swal from "sweetalert2";
import Spinner from 'react-bootstrap/Spinner';
import validator from 'validator';
import showSwalDialog from "../../utilities/Modal/swal";
import { AC_MULTIPLE_LIST_CONFIGRATIONS } from "../../actions/configurationAction";
import Pmsprivilegeuser from "./pmsprivilegeuser";
import { AC_LIST_PMS_PREVILEGES } from "../../actions/pmsprivilegesAction";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "Add",
      nameError: false,
      locationError: false,
      emailError: false,
      emailValidError: false,
      hotelidError: false,
      id: "",
      previleges: '',
      defaultCC: '',
      saving: false,
      phoneNoPatternError: false,
      spinner: false,
      oldprevileges: '',
      initialchange: true,
      checkChanges: true,
      fetch: true,
      tempHotel: {},
      existingData: [],
      previlegesLabel: [
        {
          name: 'Tickets',
          value: ['Incident_Ticket', 'All_Reports', 'Ticket_Report', 'Ticket', 'Ticket_Analytics', 'Ticket_Report', 'Create_Ticket_Report', 'Create_Close_Ticket_Report', 'Ageing_Report', 'Create_Incident_Ticket_Report']
        },
        {
          name: 'Hotels',
          value: ['Hotel']
        },
        {
          name: 'Users',
          value: ['User']
        },
        {
          name: 'To-Do List',
          value: ['Todo_List', 'Todo_Report', 'Todo_Tag', 'Todo_My_List', 'Todo_List_Item']
        },
        {
          name: 'Notification',
          value: ['Notification']
        },
        {
          name: 'Setup',
          value: ['Problem_Keyword', 'Designation', 'Room_Amenity']
        },
        {
          name: 'Master',
          value: ['Category', 'Currency', 'Country', 'Group', 'Time_Zone', 'Language', 'Configuration', 'Phone_Number_Block_List']
        },
        {
          name: 'Setting',
          value: ['Room_Type_And_Rooms']
        },
        {
          name: 'Reviews',
          value: ['Review', 'Feedback', 'Social_Review', 'Weekly_Report']
        }
      ]
    };
  }

  onChange = e => {
    this.setState({ spinner: false })
    const { name, value } = e.target;
    const Error = name + "Error";
    this.setState({ [Error]: false });
    if (name == 'name') {
      this.props.HandleInputChange('namechange', true);
    }
    this.props.HandleInputChange(name, value);
    if (name === 'email') {
      this.props.HandleInputChange('emailchange', true);
      this.setState({ email: value });
      var email = value;
      if (email) {
        this.setState({ [Error]: false });
        if (Emailvalidate(email)) {
          this.setState({ emailValidError: false })
        } else {
          this.setState({ emailValidError: true })
        }
      } else {
        this.setState({ [Error]: false, emailValidError: false })
      }
    }
  }
  onPhoneNoChange = e => {
    this.setState({ spinner: false })
    const { name, value } = e.target;
    const Error = name + "Error";
    if (name == 'phonenumber') {
      this.props.HandleInputChange('phonenumberchange', true);
    }
    if (name == 'phonenumber') {
      var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
      if (val) {
        this.setState({ [Error]: false });
        if (Phonenumber(val)) {
          this.setState({ phoneNoPatternError: false })
        } else {
          this.setState({ phoneNoPatternError: true })
        }
        this.props.HandleInputChange(name, val);
      } else {
        this.setState({ phoneNoPatternError: false })
        this.props.HandleInputChange(name, val);
      }
    }
  }

  changePrevilege = async (index, key) => {
    const { UserState } = this.props;
    if (this.state.initialchange) this.setState({ oldprevileges: UserState.user.previleges, initialchange: false });
    this.setState({ spinner: false, checkChanges: false })
    this.props.HandleInputChange('customprevilege', true);

    await this.props.ChangePrevilege(index, key);
    await this.checkArrayOfObjIsTrue()
  }


  checkArrayOfObjIsTrue() {
    let array = this.props.UserState.user.previleges;
    let status = true
    for (let obj of array) {
      if (obj.hasOwnProperty('add')) {
        if (obj.add != true) {
          status = false
        }
      }
      if (obj.hasOwnProperty('edit')) {
        if (obj.edit != true) {
          status = false
        }
      }
      if (obj.hasOwnProperty('view')) {
        if (obj.view != true) {
          status = false
        }
      }
      if (obj.hasOwnProperty('invite')) {
        if (obj.invite != true) {
          status = false
        }
      }
    }
    this.setState({ setCheckedUser: status, setChecked: status })
  }

  setAll = e => {
    this.setState({ spinner: false })
    const { checked } = e.target;
    const { UserState } = this.props;
    this.setState({ setChecked: checked })
    let previleges = UserState.user.previleges;
    if (this.state.initialchange)
      this.setState({ oldprevileges: previleges, initialchange: false });
    previleges = checked ? CONSTANTS.allprevileges : CONSTANTS.previleges;
    const updatedData = previleges.map(obj => {
      if (obj.name === 'Hotel') {
        delete obj.add;
        delete obj.edit;
      }
      return obj;
    });
    this.props.SetAllPrevileges(updatedData);
    this.props.HandleInputChange('customprevilege', true);
  }
  setAllUser = e => {
    const previleges = this.props.UserState.user.previleges;

    this.setState({ spinner: false })
    const { checked } = e.target;
    this.setState({ setCheckedUser: checked })
    if (this.state.initialchange) {
      this.setState({ oldprevileges: previleges, initialchange: false });
    }
    let truePrevileges = []
    let falsePrevileges = []
    if (previleges.length > 0) {
      previleges.map((item) => {
        let trueobj = { name: item.name }
        if (item.add != undefined) {
          trueobj["add"] = true
        }
        if (item.edit != undefined) {
          trueobj["edit"] = true
        }
        if (item.view != undefined) {
          trueobj["view"] = true
        }
        if (item.invite != undefined) {
          trueobj["invite"] = true
        }
        trueobj["sidebarname"] = item.sidebarname
        trueobj["number"] = item.number
        trueobj["mobile"] = item.mobile
        truePrevileges.push(trueobj)

        let falseobj = { name: item.name }
        if (item.add != undefined) {
          falseobj["add"] = false
        }
        if (item.edit != undefined) {
          falseobj["edit"] = false
        }
        if (item.view != undefined) {
          falseobj["view"] = false
        }
        if (item.invite != undefined) {
          falseobj["invite"] = false
        }
        falseobj["sidebarname"] = item.sidebarname
        falseobj["number"] = item.number
        falseobj["mobile"] = item.mobile
        falsePrevileges.push(falseobj)
      })
    }
    let sendValue = ''
    if (checked) {
      sendValue = truePrevileges
    } else {
      sendValue = falsePrevileges
    }
    this.props.SetAllPrevileges(sendValue);
    this.props.HandleInputChange('customprevilege', true);
  }

  handleChange = async (e) => {
    const { UserState, AccountState } = this.props
    const account = AccountState.account;
    const data = UserState.user;
    let listuser = UserState.listusers;

    this.setState({ spinner: false })
    const { name, value, label, logo, previleges, pmsprivileges } = e;
    let userprevileges = []
    if (previleges && previleges.length > 0) {
      previleges.map((temp) => {
        if (temp.view || temp.add || temp.edit || temp.invite) {
          userprevileges.push(temp)
        }
      })
    }
    const Error = name + "Error";
    if (name === 'category') {
      this.props.ListPMSPrivileges({ category: value, id: data._id })
      this.props.HandleInputChange('pmsprivileges', pmsprivileges ? pmsprivileges : []);
      await this.props.HandleInputChange('previleges', ImportedURL.SUPERADMIN.includes(account.role) ? previleges : userprevileges);
      await this.props.HandleInputChange('showprevileges', ImportedURL.SUPERADMIN.includes(account.role) ? true : userprevileges.some(({ add, edit, view, invite }) => [add, edit, view, invite].includes(true)));
      await this.checkArrayOfObjIsTrue()
    }
    this.setState({ [Error]: false, });
    this.props.HandleInputChange(name, value);
    if (name == 'hotel') {
      this.props.HandleInputChange('primaryhotel', "");
      let index = listuser.findIndex(obj => obj.hotelid == data.hotel);
      if (index !== -1) {
        listuser = [
          ...listuser.slice(0, index),
          { hotel: label, hotelid: value, hotellogo: logo },
          ...listuser.slice(index + 1)
        ]
        this.props.ListUserSendData(listuser)
      }
    }
  }
  handlePhoneCode = e => {
    this.setState({ spinner: false })
    this.setState({ defaultCC: '' });
    this.props.HandleInputChange('cc', e.value);
    this.props.HandleInputChange('ccchange', true);
  }
  SubmitForm = () => {
    const id = this.props.match.params.email;
    const { LandingState, UserState, history } = this.props;
    const data = UserState.user;
    let valid = 1;
    data['name'] = data.name.trim()
    if (!data.name) {
      this.setState({ nameError: true });
      valid = 0;
    }
    if (!data.email) {
      this.setState({ emailError: true });
      valid = 0;
    }
    if (!data.phonenumber) {
      valid = 0;
      this.setState({ phonenumberError: true });
    }
    if (!data.primaryhotel) {
      valid = 0;
      this.setState({ primaryhotelError: true });
    }
    if (this.state.phoneNoPatternError) {
      valid = 0;
    }
    if (this.state.emailValidError) {
      valid = 0;
    }
    if (!data.namechange) {
      data['namechange'] = false;
    }
    if (!data.ccchange) {
      data['ccchange'] = false;
    }
    if (!data.phonenumberchange) {
      data['phonenumberchange'] = false;
    }
    if (!data.emailchange) {
      data['emailchange'] = false;
    }

    let phoneCode = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[1] : [];
    let phoneValue = '';
    if (phoneCode.length > 0) {
      phoneCode.map(item => {
        if (data.cc) {
          if (item.code == data.cc) {
            phoneValue = item
          }
          if (item.phonecode == data.cc) {
            phoneValue = item
          }
        }
      })
    }
    if (data.changesPhoneStatus) {
      if (phoneValue) {
        data['phonecode'] = phoneValue.code;
        data['currencyname'] = phoneValue.currencyname;
        data['currencycode'] = phoneValue.currencycode;
        data['currencysymbol'] = phoneValue.symbol;
      }
    }
    if (valid) {
      if (data.userCount > 1 && (data.namechange || data.ccchange || data.phonenumberchange || data.emailchange)) {
        showSwalDialog('Do you want to change in all your account ?', '', 'Yes', 'Cancel', ImportedURL.LOCALIMAGEURL + 'assets/images/status.png', "No").then((result) => {
          const { AccountState } = this.props;
          const languageData = AccountState.account.SelectedlanguageData
          if (result.isConfirmed) {
            this.setState({ saving: true })
            data['multiplechange'] = true;
            axios.post(ImportedURL.API.updateUser + "/" + id, data)
              .then((res) => {
                this.setState({ saving: false })
                Success((languageData && languageData.USER ? languageData.USER : "User") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
                const pageno = new URLSearchParams(this.props.location.search).get('page');
                history.push("/list-user?page=" + pageno);
              }).catch(({ response }) => {
                this.setState({ saving: false })
                if (response.status == 409) {
                  Error((languageData && languageData.EMAIL_CANT_BE_UPDATED ? languageData.EMAIL_CANT_BE_UPDATED : " Email can't be updated, already tagged to tickets"))
                } else if (response.status == 408) {
                  Error((languageData && languageData.EMAIL_ALREADY_EXIT_FOR_THIS ? languageData.EMAIL_ALREADY_EXIT_FOR_THIS : " Email already exist for this hotel"))
                } else if (response.status == 400) {
                  Error((languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                } else if (response.status == 510) {
                  Error((languageData && languageData.EMAIL_ALREADY_EXIT_FOR_THIS ? languageData.EMAIL_ALREADY_EXIT_FOR_THIS : " Email already exist for this hotel"))
                } else if (response.status == 405) {
                  Error((languageData && languageData.PHONE_NUMBER ? languageData.PHONE_NUMBER : "Phone number") + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : " already exist"))
                }
                else if (response.status == 500) {
                  Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                } else if (response.status == 502) {
                  Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                } else {
                  Error(response.statusMessage)
                }
              });
          } else if (result.isDenied) {
            this.setState({ saving: true })
            data['multiplechange'] = false;
            axios.post(ImportedURL.API.updateUser + "/" + id, data)
              .then((res) => {
                this.setState({ saving: false })
                Success((languageData && languageData.USER ? languageData.USER : "User") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"))
                const pageno = new URLSearchParams(this.props.location.search).get('page');
                history.push("/list-user?page=" + pageno);
              }).catch(({ response }) => {
                this.setState({ saving: false })
                if (response.status == 409) {
                  Error((languageData && languageData.EMAIL_CANT_BE_UPDATED ? languageData.EMAIL_CANT_BE_UPDATED : " Email can't be updated, already tagged to tickets"))
                } else if (response.status == 408) {
                  Error((languageData && languageData.EMAIL_ALREADY_EXIT_FOR_THIS ? languageData.EMAIL_ALREADY_EXIT_FOR_THIS : " Email already exist for this hotel"))
                } else if (response.status == 400) {
                  Error((languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                } else if (response.status == 510) {
                  Error((languageData && languageData.EMAIL_ALREADY_EXIT_FOR_THIS ? languageData.EMAIL_ALREADY_EXIT_FOR_THIS : " Email already exist for this hotel"))
                } else if (response.status == 405) {
                  Error((languageData && languageData.PHONE_NUMBER ? languageData.PHONE_NUMBER : "Phone number") + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : " already exist"))
                }
                else if (response.status == 500) {
                  Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                } else if (response.status == 502) {
                  Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                } else {
                  Error(response.statusMessage)
                }
              });
          }
        })
      } else {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        this.setState({ saving: true })
        data['multiplechange'] = false;
        axios.post(ImportedURL.API.updateUser + "/" + id, data)
          .then((res) => {
            this.setState({ saving: false })
            Success((languageData && languageData.USER ? languageData.USER : "User") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
            const pageno = new URLSearchParams(this.props.location.search).get('page');
            history.push("/list-user?page=" + pageno);
          }).catch(({ response }) => {
            this.setState({ saving: false })
            if (response.status == 409) {
              Error((languageData && languageData.EMAIL_CANT_BE_UPDATED ? languageData.EMAIL_CANT_BE_UPDATED : "Email can't be updated, already tagged to tickets"))
            } else if (response.status == 408) {
              Error((languageData && languageData.EMAIL_ALREADY_EXIT_FOR_THIS ? languageData.EMAIL_ALREADY_EXIT_FOR_THIS : "Email already exist for this hotel"))
            } else if (response.status == 400) {
              Error((languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request'))
            } else if (response.status == 405) {
              Error((languageData && languageData.PHONE_NUMBER ? languageData.PHONE_NUMBER : "Phone number") + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : " already exist"))
            }
            else if (response.status == 510) {
              Error((languageData && languageData.EMAIL_ALREADY_EXIT_FOR_THIS ? languageData.EMAIL_ALREADY_EXIT_FOR_THIS : "Email already exist for this hotel"))
            }
            else if (response.status == 500) {
              Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
            } else if (response.status == 502) {
              Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
            } else {
              Error(response.statusMessage)
            }
          });
      }
    }
    else {
      const { AccountState } = this.props;
      const languageData = AccountState.account.SelectedlanguageData
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      Error((languageData && languageData.FILL_ALL_REQUIRED_FIELD ? languageData.FILL_ALL_REQUIRED_FIELD : " Fill all required field"));
    }
  }
  handlePhoneRestrict = e => {
    this.setState({ spinner: false })
    this.props.HandleInputChange("locationrestrict", e.target.checked);
  }

  fetchPermissions = async () => {
    this.setState({ spinner: false, fetch: false })
    const { UserState } = this.props;
    let userprevileges = []
    let tempUserPrevileges = [...UserState.user.previleges];
    let previleges = CONSTANTS.previleges;
    for (let i = 0; i < previleges.length; i++) {
      let Previlege = tempUserPrevileges.find(obj => { return obj.name == previleges[i].name });
      if (Previlege) {
        userprevileges.push({ ...Previlege, number: previleges[i].number })
      } else {
        userprevileges.push(previleges[i])
      }
    }
    await this.props.SetAllPrevileges(userprevileges);
    await this.checkArrayOfObjIsTrue(userprevileges)
  }

  setAllPMSStatus = (pmsprivileges) => {
    const pmsprivilegeslist = this.props.PMSPrivilegesState.pmsprevilegeslist;
    if (pmsprivileges && pmsprivileges.length > 0 && pmsprivilegeslist && pmsprivilegeslist.length > 0) {
      let status = true;
      for (let i = 0; i < pmsprivilegeslist.length; i++) {
        let find = pmsprivileges.find((e) => e.pmsprivilegesid == pmsprivilegeslist[i]._id)
        if (find) {
          for (let key in find.privileges) {
            if (key != 'name' || key != 'subprivileges') {
              if ((pmsprivilegeslist[i].typesOption).includes(key)) {
                if (find.privileges[key] === false) {
                  status = false;
                  break;
                }
              }
            }
          }
          if (find.privileges && find.privileges.subprivileges && find.privileges.subprivileges.length > 0 && status) {
            for (let j = 0; j < find.privileges.subprivileges.length; j++) {
              for (let key in find.privileges.subprivileges[j]) {
                if (key != 'name') {
                  if ((pmsprivilegeslist[i].typesOption).includes(key)) {
                    if (find.privileges.subprivileges[j][key] === false) {
                      status = false;
                      break;
                    }
                  }
                }
              }
            }
          }
        } else {
          status = false;
          break;
        }
      }
      this.props.HandleInputChange('setChecked', status);
    }
  }

  async componentDidMount() {
    const id = this.props.match.params.email;
    this.props.EmptyUser();
    this.props.ListConfiguration({ parentslug: ['pms-privileges-type', 'pms-privileges-sub-name'] });
    if (id) {
      this.setState({ spinner: true })
      await this.props.ViewUser(id).then((data) => {
        if (data) {
          this.setState({ existingData: data.pmsprivileges ? data.pmsprivileges : [] });
          this.props.ListUser({ email: data.email ? data.email : '' });
          this.props.ListPMSPrivileges({ category: data.category, id: data._id }).then(() => {
            this.setAllPMSStatus(data.pmsprivileges ? data.pmsprivileges : []);
            this.setState({ spinner: false })
          });
        }
      });
      await this.checkArrayOfObjIsTrue()
    } else {
      this.props.ListPMSPrivileges();
    }
    var phonecode = localStorage.getItem("phonecode");
    this.setState({ defaultCC: phonecode });

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  resetOldPermission = async () => {
    this.setState({ initialchange: true, oldprevileges: '' });
    await this.props.SetAllPrevileges(this.state.oldprevileges);
    await this.checkArrayOfObjIsTrue()
  }
  AddSelectHandleChange = e => {
    const { name, value, label } = e;
    const Error = name + "Error";
    this.setState({ [Error]: false });
    this.props.HandleInputChange(name, value);
  }

  setmadatoryUser = (e) => {
    const { checked } = e.target;
    this.props.HandleInputChange('mandatory', checked);
  }
  handleaccess = e => {
    this.props.HandleInputChange("webaccess", e.target.checked);
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.props.HandleInputChange('emailchange', true);
    this.setState({ email: value });
    let emailValidError = false;
    if (value) {
      this.setState({ emailError: false });
      if (!validator.isEmail(value)) {
        emailValidError = true;
      } else {
        const tld = value.split('.').pop();
        if (tld.length < 2 || tld.length > 3) {
          emailValidError = true;
        }
      }
    } else {
      this.setState({ emailError: false });
    }
    this.setState({ emailValidError: emailValidError });
    this.props.HandleInputChange(name, value);
  };
  render() {
    const { LandingState, UserState, AccountState } = this.props;
    const account = AccountState.account;
    const previleges = UserState.user.previleges;
    const listuser = UserState.listusers;
    const data = UserState.user;
    const dataSpinner = UserState.spinner;
    const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];
    const listCategories = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[7] : [];
    const hotelOptions = [{ label: 'All', value: '', name: 'searchhotel' }];
    listHotels.map(item => {
      hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, name: 'searchhotel' });
    })
    const categoryOptions = [{ label: 'All', value: '', name: 'searchcategory' }];
    listCategories.map(item => {
      categoryOptions.push({ label: item.name, value: item._id, name: 'searchcategory' });
    })

    var selectedHotel = '';
    const hotelOptionsAdd = [];

    if (account?.allhotels?.length > 0) {
      account.allhotels.map((item) => {
        if (account.hotel == item.hotelid) {
          selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
        }
        hotelOptionsAdd.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo });
      })
    } else {
      if (data.hotel?._id !== undefined) {
        listHotels.filter(filterItem => filterItem.status === true).map((item) => {
          if (data.hotel?._id == item._id) {
            selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
          }
          hotelOptionsAdd.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
        })
      } else {
        listHotels.filter(filterItem => filterItem.status === true).map((item) => {
          if (data.hotel == item._id) {
            selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
          }
          hotelOptionsAdd.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
        })
      }
    }
    let selectedCategory = ''
    if (data.category) {
      selectedCategory = listCategories.find(e => e._id === data.category)
    }

    let phoneCode = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[1] : [];
    phoneCode = phoneCode && phoneCode.length > 0 ? [...phoneCode.filter(item => item.code === "USA"), ...phoneCode.filter(item => item.code !== "USA")] : [];
    let ccData = '';
    if (phoneCode) {
      phoneCode.map(item => {
        if (data.cc) {
          if (item.code == data.cc) {
            ccData = { label: item.phonecode, value: item.code, flag: item.flag }
          }
          if (item.phonecode == data.cc) {
            ccData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
          }
        } else {
          if (item.code === account.phonecode) {
            ccData = { label: item.phonecode, value: item.code, flag: item.flag }
          }
          if (item.phonecode === account.phonecode) {
            ccData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
          }
        }
      })
    }
    const pageno = new URLSearchParams(this.props.location.search).get('page');

    const dataDesignation = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[6] : [];
    const DesignationArray = [];
    if (data.hotel) {
      dataDesignation.filter(filterItem => filterItem.status === true).map((item, i) => {
        if (data.hotel == item.hotel) {
          if (data.category == item.department || data.category == item.departmentId) {
            DesignationArray.push(
              item
            )
          }
        }
      })
    }
    var selectDesignationData = '';
    if (data.designation !== undefined) {
      selectDesignationData = DesignationArray.find(e => e._id === data.designation)
    }
    previleges.sort((a, b) => a.number - b.number);
    var repeated = []
    const languageData = this.props.AccountState.account.SelectedlanguageData;

    let primaryHotelSelect = ''
    let primaryHotelOption = []
    if (data.userhotellist && data.userhotellist.length > 0) {
      data.userhotellist.filter(e => e.status == true).map((item) => {
        if (item._id == data.primaryhotel) {
          primaryHotelSelect = { label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: "primaryhotel" }
        }
        primaryHotelOption.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: "primaryhotel" })
      })
    }

    return (
      <>
        <div>
          <div>
            <div className="breadcrump">
              <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-user"><h6> User List</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>Edit User</h6></p>
            </div>
            <div className="section-body pt-3 edit_user_sec">
              <div className="container-fluid">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="Departments-list"
                    role="tabpanel"
                  >

                    <div className="card">
                      <div className="card-body">
                        <div className="header-action">
                          <Link to={'/list-user?page=' + pageno}><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button></Link>
                        </div>
                        <div className='card-title d-flex'>
                          <div className='rounded_icon'><i className="icon-user circle_icon mr-2"></i></div>
                          <h3 className="card-title" style={{ marginTop: '10px' }}>Edit User</h3>
                        </div>
                        <div className="row">

                          {ImportedURL.SUPERADMIN.includes(account.role) ?
                            <div className="col-sm-6 col-md-6">
                              <div className="form-group" style={ImportedURL.SUPERADMIN.includes(account.role) ? {} : { display: "inline-flex", fontSize: "18px" }}>
                                <label style={ImportedURL.SUPERADMIN.includes(account.role) ? {} : { paddingTop: "15px" }} className="form-label">Hotel{this.state.modalType == "Edit" ? <span>:</span> : <span className="ml-1" style={{ color: "red" }}>{(ImportedURL.SUPERADMIN.includes(account.role)) ? '*' : ''}</span>}{ImportedURL.SUPERADMIN.includes(account.role) ? '' : <span className="ml-1" style={{ paddingLeft: "20px", margin: "0px 36px" }}>:</span>}</label>
                                {
                                  account.allhotels?.length > 0 ?
                                    <>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={ImportedURL.FILEURL + selectedHotel.logo} style={{ height: '50px', width: '50px', borderRadius: "50%", marginRight: "20px", objectFit: 'contain' }} className="contain_image" onError={onErrorImage} />
                                        <span className='all_search_text_size'>{selectedHotel.label}</span>
                                      </div>
                                    </>
                                    :
                                    <>
                                      <Select
                                        className="hotel_select_edit contain_image"
                                        value={selectedHotel}
                                        onChange={this.handleChange}
                                        options={hotelOptionsAdd}
                                        formatOptionLabel={(e) => (
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                            <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                          </div>
                                        )}
                                      />
                                    </>
                                }

                                <div className="invalid-feedback" style={{ display: this.state.hotelError ? "block" : 'none' }}>Hotel name is required</div>
                              </div>
                            </div>
                            : ''}
                          <div className="col-sm-6 col-md-6" style={{ zIndex: 999 }}>
                            <div className="form-group">
                              <label className="form-label">User Category<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                              <Select
                                className="hotel_select_edit"
                                value={selectedCategory ? { label: selectedCategory.name, value: selectedCategory._id, name: 'category' } : ''}
                                onChange={this.handleChange}
                                options={listCategories ? listCategories.filter(filterItem => filterItem.status === true).map(item => {
                                  return { label: item.name, value: item._id, name: 'category', previleges: item.previleges, pmsprivileges: item.pmsprivileges }
                                }) : ''}
                              />
                              <div className="invalid-feedback" style={{ display: this.state.categoryError ? "block" : 'none' }}>Users Category is required</div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Designation</label>
                              <Select
                                value={selectDesignationData ? { label: selectDesignationData.name } : ''}
                                onChange={this.AddSelectHandleChange}
                                options={DesignationArray.filter(filterItem => filterItem.status === true).map(item => {
                                  return {
                                    label: item?.name, value: item?._id, name: 'designation',
                                  }
                                })} />
                              <div className="invalid-feedback" style={{ display: this.state.departmentError ? "block" : 'none' }}>Designation is required</div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6" style={{ zIndex: 99 }}>
                            <div className="form-group">
                              <label className="form-label">Primary Hotel<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                              <Select
                                value={primaryHotelSelect}
                                onChange={this.AddSelectHandleChange}
                                options={primaryHotelOption}
                                formatOptionLabel={(e) => (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                    <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                  </div>
                                )}
                              />
                              <div className="invalid-feedback" style={{ display: this.state.primaryhotelError ? "block" : 'none' }}>Primary Hotel is required</div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Name"
                                name="name"
                                value={data.name}
                                onChange={this.onChange}
                              />
                              <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}> Name is required</div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Email<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Email"
                                name="email"
                                value={data.email}
                                onChange={this.handleInputChange}
                              />
                              <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
                              <div className="invalid-feedback" style={{ display: this.state.emailValidError ? "block" : 'none' }}>Enter valid email</div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
                            <label className="form-label">Phone #<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 pr-0 ">
                                <div className="form-group mb-0 country_code">
                                  <Select
                                    className="cc_phone_select"
                                    isDisabled={this.state.modalType == "View"}
                                    value={ccData ? ccData : ''}
                                    onChange={this.handlePhoneCode}
                                    options={phoneCode && phoneCode.filter(filterItem => filterItem.status === true).map(item => {
                                      return {
                                        label: item.phonecode,
                                        flag: item.flag,
                                        value: item.code,
                                        name: 'cc'
                                      }
                                    })}
                                    formatOptionLabel={(item) => {
                                      return (
                                        <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                      )
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 pl-0 pl-xl-0 pl-lg-0 pl-md-0 pl-sm-2">
                                <div className="form-group mb-0">
                                  <input type="text" className="form-control ml-1" maxLength={10} disabled={this.state.modalType == "View"} name='phonenumber' onChange={this.onPhoneNoChange} value={data.phonenumber} placeholder="Phone #" />
                                  <div className="invalid-feedback" style={{ display: this.state.phonenumberError ? "block" : 'none' }}>Phone # is required</div>
                                  <div className="invalid-feedback" style={{ display: this.state.phoneNoPatternError ? "block" : 'none' }}>Phone # should contain 10 digits</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <label className="form-label" style={{ marginBottom: '14.5px' }}>Ticket-Media Attachment</label>
                            <label className="custom-switch" data-toggle="modal" data-target="#exampleModal">
                              <input
                                type="checkbox"
                                checked={data.mandatory}
                                name="custom-switch-checkbox"
                                className="custom-switch-input"
                                onChange={this.setmadatoryUser}
                                style={{ cursor: 'pointer' }}
                              />
                              <span className="custom-switch-indicator"></span>
                              <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                                {data.mandatory ? "Mandatory" : "Non Mandatory"}
                              </span>
                            </label>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="text-left mb-4">
                              <label className="form-label" style={{ marginBottom: '14.5px' }}>Web Access</label>
                              <label className="custom-switch">
                                <input
                                  type="checkbox"
                                  name="locationrestrict"
                                  className="custom-switch-input"
                                  checked={data.webaccess}
                                  disabled={this.state.modalType == "View"}
                                  onChange={this.handleaccess}
                                  style={{ cursor: 'pointer' }}
                                />
                                <span className="custom-switch-indicator"></span>
                                <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                                  {data.webaccess ? "Allow" : "Deny"}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>


                      </div>
                      {data.showprevileges ?
                        <div className="col-lg-12">
                          <div className="card">
                            <div className="card-header set_permission_res" >
                              <h3 className="card-title">Set Permissons</h3>
                              <div className="card-options add_card_options">
                                <div className="d-flex justify-content-between align-items-center">
                                  <ul className="nav nav-tabs page-header-tab"></ul>
                                  <div className="header-action">
                                    <div className="input-group">
                                      {ImportedURL.SUPERADMIN.includes(account.role) && <> {this.state.oldprevileges && <span className="input-group-btn mr-2">
                                        <button className="btn btn-icon btn-sm show-fetch" type="submit" onClick={this.resetOldPermission} >
                                          <span className="fa fa-refresh mr-2"></span>Reset old permissions
                                        </button>
                                      </span>}
                                        {this.state.fetch ? <span className="input-group-btn mr-2">
                                          <button className="btn btn-icon btn-sm show-fetch" type="submit" onClick={this.fetchPermissions} >
                                            <span className="fa fa-refresh mr-2"></span>Fetch Permissons
                                          </button>
                                        </span> : ''}
                                      </>}
                                      {(this.state.oldprevileges && !ImportedURL.SUPERADMIN.includes(AccountState.role)) && <span className="input-group-btn mr-2">
                                        <button className="btn btn-icon btn-sm show-fetch" type="submit" onClick={this.resetOldPermission} >
                                          <span className="fa fa-refresh mr-2"></span>Reset old permissions
                                        </button>
                                      </span>}
                                      {ImportedURL.SUPERADMIN.includes(account.role) ?
                                        <label className="custom-switch">
                                          <input
                                            type="checkbox"
                                            checked={this.state.setChecked}
                                            name="custom-switch-checkbox"
                                            className="custom-switch-input"
                                            onChange={this.setAll}
                                            style={{ cursor: 'pointer' }}
                                          />
                                          <span className="custom-switch-indicator"></span>
                                          <span className="custom-switch-description" style={{ cursor: 'pointer' }} >
                                            Set All
                                          </span>
                                        </label>
                                        :
                                        <label className="custom-switch">
                                          <input
                                            type="checkbox"
                                            checked={this.state.setCheckedUser}
                                            name="custom-switch-checkbox"
                                            className="custom-switch-input"
                                            onChange={this.setAllUser}
                                            style={{ cursor: 'pointer' }}
                                          />
                                          <span className="custom-switch-indicator"></span>
                                          <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                                            Set All
                                          </span>
                                        </label>
                                      }

                                    </div>
                                    <div className="input-group">

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="card-body card-footer" style={{ borderTop: "none" }}>
                              <div className={previleges.length > 8 ? "Over_all_table_style table-responsive edit_user_table" : 'Over_all_table_style table-responsive edit_user_table_Custom'}>
                                <table className="table table-striped mb-0">
                                  <thead>
                                    <tr>
                                      <th style={{ textAlign: 'center' }}>Management</th>
                                      <th style={{ textAlign: 'center' }}>View</th>
                                      <th style={{ textAlign: 'center' }}>Add</th>
                                      <th style={{ textAlign: 'center' }}>Edit</th>
                                      <th style={{ textAlign: 'center' }}>Invite</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      previleges.map((data, index) => {
                                        let titleLabel = this.state.previlegesLabel.find(e => e.value.includes(data.name))
                                        let status = ''
                                        if (titleLabel) {
                                          status = repeated.find(e => e == titleLabel.name)
                                          if (!status) {
                                            repeated.push(titleLabel.name)
                                          }
                                        }
                                        if (data.sidebarname) {
                                          return (
                                            <tr key={index}>
                                              <td style={{ position: 'relative' }}>

                                                <p className="user_permission">
                                                  {(!status) ? (titleLabel ? titleLabel.name : "") : ''}
                                                </p>
                                                <p style={{ marginTop: "revert", textAlign: 'center' }}>
                                                  {data.sidebarname} {data.mobile ? <i class="fa fa-mobile ml-2 mobile_permission_icon" aria-hidden="true"></i> : ''}
                                                </p>
                                              </td>
                                              <td className="user_category_previledge">
                                                <label className="custom_control_user_previleges custom-checkbox">
                                                  <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.view} onChange={(event) => this.changePrevilege(index, 'view')} />
                                                  <span className="custom-control-label"></span>
                                                </label>
                                              </td>
                                              <td className="user_category_previledge">
                                                {data.name !== "History" ?
                                                  <>
                                                    {
                                                      data.add != undefined ?
                                                        <>
                                                          <label className="custom_control_user_previleges custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.add} onChange={(event) => this.changePrevilege(index, 'add')} />
                                                            <span className="custom-control-label"></span>
                                                          </label>
                                                        </> : '---'
                                                    }
                                                  </>
                                                  : '---'
                                                }
                                              </td>
                                              <td className="user_category_previledge">
                                                {data.name !== "History" ?
                                                  <>
                                                    {data.edit != undefined ?
                                                      <>
                                                        <label className="custom_control_user_previleges custom-checkbox">
                                                          <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.edit} onChange={(event) => this.changePrevilege(index, 'edit')} />
                                                          <span className="custom-control-label"></span>
                                                        </label>
                                                      </> : '---'
                                                    }
                                                  </>
                                                  : '---'
                                                }
                                              </td>
                                              <td className="user_category_previledge">
                                                {data.name == "User" ?
                                                  <label className="custom_control_user_previleges custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.invite} onChange={(event) => this.changePrevilege(index, 'invite')} />
                                                    <span className="custom-control-label"></span>
                                                  </label>
                                                  : '---'}
                                              </td>
                                            </tr>
                                          )
                                        }
                                      })
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                          <div style={{ maxWidth: "340px" }}>
                            <h5 className="mb-2">No permission given</h5>
                          </div>
                        </div>
                      }
                      {/* <Pmsprivilegeuser {...this.props} type={"user"} existingData={this.state.existingData} setAllPMSStatus={(e) => this.setAllPMSStatus(e)} changePmsPrivileges={(e) => this.setState({ changePmsPrivileges: e })} /> */}
                      <div className="card-footer text-right mandatory">
                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                        <div className="text-right">
                          {
                            this.state.saving ?
                              <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                              :
                              <button type="submit" className="btn commor_save" onClick={this.SubmitForm}>
                                <i className="fe fe-save mr-2"></i>Update
                              </button>
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
        <div id="overlay" style={{ display: this.state.spinner ? (dataSpinner ? 'block' : 'none') : 'none' }}></div>
        {
          this.state.spinner && (dataSpinner &&
            <div className="common_loader">
              <img className='loader_img_style_common' src='../../assets/images/load.png' />
              <Spinner className='spinner_load_common' animation="border" variant="info" >
              </Spinner>
            </div>
          )
        }


        <div className="modal fade" id="exampleModal" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>Attachment</h5>
                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" style={{ fontSize: "23px" }} id="closeModal" onClick={this.clear}>
                    <img src='../../assets/images/cancel.png' />
                  </span>
                </button>
              </div>
              <div className="modal-body">
                {data.mandatory ?
                  <p className="mandatory_popup_text">Media file upload mandatory during ticket creation</p>
                  :
                  <p className="mandatory_popup_text">Media file upload not mandatory</p>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  UserState: state.user,
  AccountState: state.account,
  LandingState: state.landing,
  PMSPrivilegesState: state.pmsprivileges,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ChangePrevilege: AC_CHANGE_PREVILEGE,
      HandleInputChange: AC_HANDLE_USER_CHANGE,
      SetAllPrevileges: AC_SET_ALL_PREVILEGES,
      ViewUser: AC_VIEW_USER,
      EmptyUser: AC_EMPTY_USER,
      ListUser: AC_LIST_USER,
      ListUserSendData: AC_LIST_USER_SEND_DATA,
      ListConfiguration: AC_MULTIPLE_LIST_CONFIGRATIONS,
      ListPMSPrivileges: AC_LIST_PMS_PREVILEGES,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
